import Wrapper from '@/views/user/Wrapper'

export default [
  // ADMIN
  {
    path: '/admin',
    name: 'admin',
    component: Wrapper,
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin']
    },
    children: [{
        path: 'profile',
        component: () => import('@/views/user/shared/Profile'),
        redirect: 'profile/menu',
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin']
        },
        children: [{
          path: 'menu',
          component: () => import('@/views/user/admin/profile/Menu'),
          meta: {
            requiresAuth: true,
            rolesAllowed: ['admin']
          },
        }, {
          path: 'personal-data',
          component: () => import('@/views/user/admin/profile/PersonalData'),
          meta: {
            requiresAuth: true,
            rolesAllowed: ['admin']
          },
        }, {
          path: 'roles',
          component: () => import('@/views/user/admin/profile/Roles'),
          meta: {
            requiresAuth: true,
            rolesAllowed: ['admin']
          },
        }, {
          path: 'security',
          component: () => import('@/views/user/shared/profile/Security'),
          meta: {
            requiresAuth: true,
            rolesAllowed: ['admin']
          },
        }, ]
      }, {
        path: 'orders/',
        name: 'adminOrders',
        component: () => import('@/views/user/shared/OrdersNew.vue'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin']
        },
      }, {
        path: 'orders/:oId',
        name: 'adminOrder',
        component: () => import('@/views/user/shared/Order'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin']
        },
      }, {
        path: 'products/:type',
        name: 'adminProducts',
        component: () => import('@/views/user/seller/Products'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin']
        },
      }, {
        path: 'products/:type/:pId',
        name: 'adminProduct',
        component: () => import('@/views/user/seller/Product'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin']
        },
      }, {
        path: 'sellers',
        component: () => import('@/views/user/admin/Sellers'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin'],
        },
      }, {
        path: 'sellers/:id',
        component: () => import('@/views/user/admin/Seller'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin'],
        },
      },{
        path: 'not-verified-sellers',
        component: () => import('@/views/user/admin/NotVerifiedSellers'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin'],
        },
      }, {
        path: 'not-verified-sellers/:id',
        component: () => import('@/views/user/admin/NotVerifiedSeller'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin'],
        },
      },{
        path: 'clients',
        component: () => import('@/views/user/admin/Clients'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin'],
        },
      }, {
        path: 'clients/:id',
        component: () => import('@/views/user/admin/Client'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin'],
        },
      }, {
        path: 'admins',
        component: () => import('@/views/user/admin/Admins'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin'],
          nonEmployees: true
        },
      }, {
        path: 'admins/:id',
        component: () => import('@/views/user/admin/Admin'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin'],
          nonEmployees: true
        },
      },
      {
        path: 'homepage',
        component: () => import('@/views/user/admin/Homepage'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin'],
        },
      },
      {
        path: 'statistics',
        name: 'adminStatistics',
        component: () => import('@/views/user/admin/Statistics'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin'],
        },
      },
      {
        path: 'logs',
        name: 'adminLogs',
        component: () => import('@/views/user/admin/Logs'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin'],
        },
      },
      {
        path: 'reversed-auction/participation/:pId',
        name: 'raParticipation',
        component: () => import('@/views/user/admin/Participation'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin']
        },
      },
    ]
  },
  // CLIENT
  {
    path: '/:role?/client/:id?',
    name: 'client',
    component: Wrapper,
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin', 'client']
    },
    children: [{
      path: 'profile',
      component: () => import('@/views/user/shared/Profile'),
      redirect: 'profile/menu',
      meta: {
        requiresAuth: true,
        rolesAllowed: ['admin', 'client']
      },
      children: [{
        path: 'menu',
        component: () => import('@/views/user/client/profile/Menu'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin', 'client']
        },
      }, {
        path: 'personal-data',
        name: 'clientPersonalData',
        component: () => import('@/views/user/client/profile/PersonalData'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin', 'client']
        },
      }, {
        path: 'delivery-addresses',
        name: 'clientDeliveryAddresses',
        component: () => import('@/views/user/shared/profile/DeliveryAddresses'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin', 'client']
        },
      }, {
        path: 'invoice-data',
        name: 'clientInvoiceData',
        component: () => import('@/views/user/client/profile/Companies'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin', 'client']
        },
      }, {
        path: 'security',
        component: () => import('@/views/user/shared/profile/Security'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['client']
        },
      }, {
        path: 'settings',
        name: 'clientUserSettings',
        component: () => import('@/views/user/admin/UserSettings'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin'],
        },
      }]
    }, {
      path: 'orders',
      name: 'clientOrders',
      component: () => import('@/views/user/shared/OrdersNew'),
      meta: {
        requiresAuth: true,
        rolesAllowed: ['client'],
      },
    }, {
      path: 'orders/:oId',
      name: 'clientOrder',
      component: () => import('@/views/user/shared/Order'),
      meta: {
        requiresAuth: true,
        rolesAllowed: ['client']
      },
    }, {
      path: 'preo-vouchers',
      name: 'clientMicrowallets',
      component: () => import('@/views/user/shared/preoVouchers/PreoVouchersList.vue'),
      meta: {
        requiresAuth: true,
        rolesAllowed: ['client'],
      },
    },
      {
        name: 'paymentRequest',
        path: 'payment-request',
        component: () => import('@/views/user/shared/ConfirmPreoPayment.vue'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['client'],
        },
      },
      {
        path: 'preo-voucher/:preoId',
      name: 'clientMicrowallets',
      component: () => import('@/views/user/shared/preoVouchers/PreoVoucher.vue'),
      meta: {
        requiresAuth: true,
        rolesAllowed: ['client'],
      },
    }, {
      path: 'microwallets',
      name: 'clientMicrowallets',
      component: () => import('@/views/user/shared/Orders'),
      meta: {
        requiresAuth: true,
        rolesAllowed: ['client'],
      },
    }, {
      path: 'auctions',
      name: 'clientAuctions',
      component: () => import('@/views/user/shared/Orders'),
      meta: {
        requiresAuth: true,
        rolesAllowed: ['client'],
      },
    }, {
      path: 'favourites',
      name: 'favouritesClient',
      component: () => import('@/views/user/shared/Favourites'),
      meta: {
        requiresAuth: true,
        rolesAllowed: ['client', 'seller'],
      },
    },
    ]
  },
  // SELLER
  {
    path: '/:role?/seller/:id?',
    name: 'seller',
    component: Wrapper,
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin', 'seller'],
    },
    children: [{
        path: 'sellers',
        component: () => import('@/views/user/seller/Sellers'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['seller'],
          nonEmployees: true
        },
      }, {
        path: 'sellers/:_id',
        component: () => import('@/views/user/seller/Seller'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['seller'],
          nonEmployees: true
        },
      },
      {
        path: 'objects',
        component: () => import('@/views/user/seller/Objects'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['seller'],
          nonEmployees: true
        },
      }, {
        path: 'objects/:id',
        component: () => import('@/views/user/seller/Object'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['seller'],
          nonEmployees: true
        },
      },
      {
        path: 'tips',
        component: () => import('@/views/user/seller/Tips'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['seller'],
        },
      },
      {
        name: 'sellerEmployees',
        path: 'employees',
        component: () => import('@/views/user/seller/Employees'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin', 'seller'],
          nonEmployees: true
        },
      },
      {
        name: 'sellerEmployees',
        path: 'employees/:_id',
        component: () => import('@/views/user/seller/Employee'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin', 'seller'],
          nonEmployees: true
        },
      }, {
        path: 'profile',
        name: 'sellerProfileWrapper',
        component: () => import('@/views/user/shared/Profile'),
        redirect: 'profile/menu',
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin', 'seller']
        },
        children: [{
            path: 'menu',
            component: () => import('@/views/user/seller/profile/Menu'),
            meta: {
              requiresAuth: true,
              rolesAllowed: ['admin', 'seller'],
              noSellerEmployees: true
            },
          },
          {
            path: 'employee/:_id',
            name: 'sellerProfileEmployee',
            component: () => import('@/views/user/seller/Employee'),
            meta: {
              requiresAuth: true,
              rolesAllowed: ['seller'],
              onlyEmployees: true
            },
          },
          {
            path: 'company',
            name: 'sellerCompany',
            component: () => import('@/views/user/shared/profile/Company'),
            meta: {
              requiresAuth: true,
              rolesAllowed: ['admin', 'seller'],
              noSellerEmployees: true
            },
          },
          {
            path: 'not-verified-sellerCompany',
            name: 'not-verified-sellerCompany',
            component: () => import('@/views/user/shared/profile/Company'),
            meta: {
              requiresAuth: true,
              rolesAllowed: ['admin', 'seller'],
              noSellerEmployees: true
            },
          },
          {
            path: 'not-verified-contract',
            name: 'not-verified-contract',
            component: () => import('@/views/user/seller/profile/Contract'),
            meta: {
              requiresAuth: true,
              rolesAllowed: ['admin', 'seller'],
              noSellerEmployees: true
            },
          },
          {
            path: 'not-verified-preonow',
            name: 'not-verified-preonow',
            component: () => import('@/views/user/seller/profile/Preonow'),
            meta: {
              requiresAuth: true,
              rolesAllowed: ['admin', 'seller'],
              noSellerEmployees: true
            },
          },
          {
            path: 'delivery-addresses',
            name: 'sellerDeliveryAddresses',
            component: () => import('@/views/user/shared/profile/DeliveryAddresses'),
            meta: {
              requiresAuth: true,
              rolesAllowed: ['admin', 'seller'],
              noSellerEmployees: true
            },
          }, {
            path: 'contract',
            name: 'sellerContract',
            component: () => import('@/views/user/seller/profile/Contract'),
            meta: {
              requiresAuth: true,
              rolesAllowed: ['admin', 'seller'],
              noSellerEmployees: true
            },
          },
          {
            path: 'preonow',
            name: 'sellerPreonow',
            component: () => import('@/views/user/seller/profile/Preonow'),
            meta: {
              requiresAuth: true,
              rolesAllowed: ['admin', 'seller'],
              noSellerEmployees: true
            },
          },
          {
            path: 'invoice-data',
            component: () => import('@/views/user/shared/profile/Companies'),
            meta: {
              requiresAuth: true,
              rolesAllowed: ['admin', 'seller']
            },
          },
          {
            path: 'security',
            component: () => import('@/views/user/shared/profile/Security'),
            meta: {
              requiresAuth: true,
              rolesAllowed: ['seller'],
              noSellerEmployees: true
            },
          },
          // {
          //   path: 'plan',
          //   name: 'sellerPlan',
          //   component: () => import('@/views/user/seller/profile/Plan'),
          //   meta: {
          //     requiresAuth: true,
          //     rolesAllowed: ['admin', 'seller'],
          //     noSellerEmployees: true
          //   },
          // },
          {
            path: 'settings',
            name: 'sellerUserSettings',
            component: () => import('@/views/user/admin/UserSettings'),
            meta: {
              requiresAuth: true,
              rolesAllowed: ['admin'],
              noSellerEmployees: true
            },
          },
          {
            path: 'not-verified-sellerUserSettings',
            name: 'not-verified-sellerUserSettings',
            component: () => import('@/views/user/admin/UserSettings'),
            meta: {
              requiresAuth: true,
              rolesAllowed: ['admin'],
              noSellerEmployees: true
            },
          }
        ]
      },
      {
        path: 'products/:type',
        name: 'sellerProducts',
        component: () => import('@/views/user/seller/Products'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin', 'seller'],
          noSellerEmployees: true
        },
      },
      {
        path: 'products/:type/:pId',
        name: 'sellerProduct',
        component: () => import('@/views/user/seller/Product'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin', 'seller'],
          noSellerEmployees: true
        },
      },
      {
        path: 'orders',
        name: 'sellerOrders',
        component: () => import('@/views/user/shared/Orders/SellerOrdersWrapper'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin', 'seller']
        },
      },
      {
        path: 'orders-seller/:oId',
        name: 'sellerOrder',
        component: () => import('@/views/user/shared/Order'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin', 'seller']
        },
      },
      // {
      //   path: 'orders/:oId',
      //   name: 'sellerOrder',
      //   component: () => import('@/views/user/shared/Orders/SellerOrdersWrapper.vue'),
      //   meta: {
      //     requiresAuth: true,
      //     rolesAllowed: ['admin', 'seller']
      //   },
      // },
      // {
      //   path: 'microwallets',
      //   name: 'sellerMicrowallets',
      //   component: () => import('@/views/user/shared/Orders/SellerOrdersWrapper.vue'),
      //   meta: {
      //     requiresAuth: true,
      //     rolesAllowed: ['admin', 'seller'],
      //   },
      // },
      {
        path: 'preo-vouchers',
        name: 'sellerMicrowallets',
        component: () => import('@/views/user/shared/Orders/SellerOrdersWrapper.vue'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin', 'seller'],
        },
      },{
        path: 'preo-voucher/:preoId',
        name: 'sellerMicrowallets',
        component: () => import('@/views/user/shared/preoVouchers/PreoVoucher.vue'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin', 'seller'],
        },
      },
      {
        path: 'auctions',
        name: 'sellerAuctions',
        component: () => import('@/views/user/shared/Orders'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['seller'],
        },
      },
      {
        path: 'reversed-auctions',
        name: 'sellerReversedAuctions',
        component: () => import('@/views/user/shared/Orders'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['seller'],
        },
      },
      {
        path: 'b2b',
        name: 'sellerB2b',
        component: () => import('@/views/user/shared/Orders'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['seller'],
        },
      },
      {
        path: 'received-offers',
        name: 'receivedOffers',
        component: () => import('@/views/user/shared/Orders'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['seller'],
        },
      },
      {
        path: 'received-offers/:oId',
        name: 'receivedOffer',
        component: () => import('@/views/user/shared/Offer'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['seller']
        },
      },
      // Seller as client
      {
        path: 'orders-sellerAsClient/:oId',
        name: 'sellerAsClientOrder',
        component: () => import('@/views/user/shared/Order'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['seller']
        },
      },
      {
        path: 'orders-sellerAsClient',
        name: 'sellerAsClientOrders',
        component: () => import('@/views/user/shared/Orders/SellerOrdersWrapper'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['client', 'seller'],
          noSellerEmployees: true
        },
      }, {
        path: 'preoVouchers-sellerAsClient',
        name: 'sellerAsClientMicrowallets',
        component: () => import('@/views/user/shared/Orders/SellerOrdersWrapper'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['client', 'seller'],
          noSellerEmployees: true
        },
      }, {
        path: 'my-preo-voucher/:preoId',
        name: 'sellerAsClient',
        component: () => import('@/views/user/shared/preoVouchers/PreoVoucher.vue'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin', 'seller'],
        },
      },
      {
        path: 'client-preo-voucher/:preoId',
        name: 'sellerFromClient',
        component: () => import('@/views/user/shared/preoVouchers/PreoVoucher.vue'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['admin', 'seller'],
        },
      },
      {
        path: 'auctions-sellerAsClient',
        name: 'sellerAsClientAuctions',
        component: () => import('@/views/user/shared/Orders'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['client', 'seller'],
          noSellerEmployees: true
        },
      }, {
        path: 'reversed-auctions-sellerAsClient',
        name: 'sellerAsClientReversedAuctions',
        component: () => import('@/views/user/shared/Orders'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['client', 'seller'],
          noSellerEmployees: true
        },
      }, {
        path: 'b2b-sellerAsClient',
        name: 'sellerAsClientB2b',
        component: () => import('@/views/user/shared/Orders'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['client', 'seller'],
          noSellerEmployees: true
        },
      }, {
        path: 'favourites',
        name: 'favouritesSeller',
        component: () => import('@/views/user/shared/Favourites'),
        meta: {
          requiresAuth: true,
          rolesAllowed: ['client', 'seller'],
          noSellerEmployees: true
        },
      },
    ]
  },
  {
    path: '/employee/payment-request',
    name: 'employeePaymentRequest',
    component: () => import('@/views/user/employee/PaymentRequest'),
    meta: {
      requiresAuth: true,
      rolesAllowed: ['seller'],
      onlyEmployees: true
    },
  }
]
