// Holds frontend utility functions
// const gtm = process.env.GTM;

export default {
     sendToGA(data, event) {
        try {
            // if (gtm) {
                window?.dataLayer.push({ecommerce: null});
                if (event) {
                    window.dataLayer?.push({
                        'event': event,
                        'ecommerce':
                        data
                    });
                } else {
                    window.dataLayer?.push({
                        'ecommerce':
                        data
                    });
                }
            // }
        } catch (e) {
            console.error(e);
            console.error("Failed sending info to GTM");
        }
    },

    percentage(percent, totalValue) {
        return (percent*totalValue) / 100;
    },
    legalUri() {
        return "https://docs.preonow.com/legal";
    },
}