<template>
  <div
    class="preocard"
    :class="
      `preocard--${color || 'default'}
      ${clickable ? 'preocard--clickable' : ''}
      ${active ? 'preocard--active' : ''}`
    "
    @click="$emit('click')"
  >
    <div class="shapes">
      <img
        :src="`${uploads}/assets/img/preocard/preocard.svg`"
        alt="Preocard background image"
      />
    </div>
    <header>
      <p>ТЕКУЩ БАЛАНС, ПРИО</p>
      <h1>{{ preo.toFixed(2) }}</h1>
    </header>
    <footer>
      <p>{{ company }}</p>
      <p>Preo ваучер {{ preoVoucherId }}</p>
    </footer>
  </div>
</template>

<script>

export default {
  props: ['preo', 'company', 'color', 'preoVoucherId', 'clickable', 'active'],
  computed: {
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.preocard {
  align-items: flex-start;
  background: rgb(156, 44, 243);
  background: linear-gradient(
    180deg,
    rgba(156, 44, 243, 1) 0%,
    rgba(58, 73, 249, 1) 100%
  );
  border-radius: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 139px;
  justify-content: space-between;
  min-height: 139px;
  min-width: 238px;
  padding: 7px;
  position: relative;
  text-align: left;
  width: 238px;

  &.preocard--clickable {
    position: relative;
    @include noselect();
    @include hover();
    &::before {
      border: 4px solid #665eff;
      border-radius: 15px;
      content: '';
      height: calc(100% + 12px);
      left: -6px;
      position: absolute;
      top: -6px;
      opacity: 0;
      width: calc(100% + 12px);
      @include transform(scale(0.98));
      @include tr();
      z-index: 0;
    }
    &.preocard--active {
      &::before {
        opacity: 1;
        @include transform(scale(1));
      }
    }
    &:not(.preocard--active) {
      mix-blend-mode: luminosity;
    }
  }

  &.preocard--light-blue {
    background: rgb(44, 216, 243);
    background: linear-gradient(
      180deg,
      rgba(44, 216, 243, 1) 0%,
      rgba(58, 154, 249, 1) 100%
    );
  }
  &.preocard--red {
    background: rgb(228, 44, 102);
    background: linear-gradient(
      180deg,
      rgba(228, 44, 102, 1) 0%,
      rgba(245, 91, 70, 1) 100%
    );
  }

  .shapes {
    border-radius: inherit;
    height: 100%;
    left: 0;
    position: absolute;
    overflow: hidden;
    top: 0;
    width: 100%;
    z-index: 1;
    @include noselect();
    &::before,
    &::after {
      background-color: #000;
      border-radius: 50%;
      content: '';
      height: 400px;
      opacity: 0.1;
      position: absolute;
      width: 400px;
    }
    &::before {
      bottom: -340px;
      left: -200px;
    }
    &::after {
      right: -200px;
      top: -335px;
    }
    > img {
      height: 90%;
      position: absolute;
      right: 0;
      top: 5%;
      @include transform(translateX(32%));
    }
  }
  header {
    font-weight: 700;
    p {
      color: inherit;
      font-size: $px12;
    }
    h1 {
      color: inherit;
      font-size: $px36;
      line-height: $px30;
    }
  }
  footer {
    font-weight: 700;
    p {
      color: inherit;
      &:first-child {
        font-size: $px14;
      }
      &:last-child {
        font-size: $px11;
      }
    }
  }
}
</style>
