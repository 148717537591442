<template >
  <iframe class="video-and-banner"
          src="https://www.youtube.com/embed/5duAJtW_rhE?si=P9gEQwxjy-haxzH3"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen>
  </iframe>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: [],
  components: {  },
  computed: {
    ...mapGetters([]),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
  },
  data() {
    return{}
  },
  watch: {},
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/banner-and-video.scss';
</style>
