<template>
  <div class="modal-wrapper" v-if="gift._id && !$route.query.hideGift">
    <transition name="modal" appear>
      <div class="modal">
        <header>
          <p class="center confirm-title">
            <template>
              <p>
                Имаш {{ gift.preo }} Preo-точки подарък от
                {{ gift.giftFrom.role === 'seller'  ? gift.giftFrom.company.name : gift.giftFrom.name }}
              </p>
            </template>
          </p>
        </header>
        <main v-if="hasAddress">
<!--        <main v-if="hasAddress">-->
          <p class="microwallet-name">
            За {{ gift.product.name }} Preo ваучер
          </p>
          <p class="gift-comment" v-if="gift.giftComment">
            {{ gift.giftComment }}
          </p>
          <p class="modal-text">
            Ако Preo-точките не бъдат приети до 24 часа, те автоматично се
            връщат на изпратилия ги. Също така може и да откажете точките. Ако
            приемете точките, във вашия профил ще се създаде нов Preo ваучер,
            от който да пазарувате.
          </p>

<!--          <section class="deliveryOptions">-->
<!--            <p>-->
<!--              Преди да приемете подаръка, моля изберете метод и адрес на-->
<!--              доставка-->
<!--            </p>-->
<!--            <div>-->
<!--              <Dropdown-->
<!--                class="input-wrapper input-wrapper&#45;&#45;margin-bottom"-->
<!--                placeholder="Изберете метод на доставка"-->
<!--                v-model="deliveryType"-->
<!--                prop="name"-->
<!--                :list="deliveryTypes"-->
<!--                :up="true"-->
<!--              />-->

<!--              <p-->
<!--                v-if="!this.currentUser.addresses.length"-->
<!--                class="add-adress"-->
<!--                @click="hasAddress = false"-->
<!--              >-->
<!--                Добави адрес за доставка-->
<!--              </p>-->
<!--              <Dropdown-->
<!--                v-else-->
<!--                class="input-wrapper input-wrapper-delivery"-->
<!--                :placeholder="-->
<!--                  deliveryType.value === 'sellerAdress'-->
<!--                    ? 'Изберете адрес на доставчик'-->
<!--                    : 'Изберете адрес за доставка'-->
<!--                "-->
<!--                v-model="address"-->
<!--                prop="city"-->
<!--                :additionalProps="['address', 'additional']"-->
<!--                :list="addresses"-->
<!--                :disabled="!deliveryType.value"-->
<!--                :up="true"-->
<!--              />-->
<!--            </div>-->
<!--            <span-->
<!--              >Цена за доставка ще бъде начислена при доставка и се заплаща-->
<!--              отделно</span-->
<!--            >-->
<!--          </section>-->
        </main>
<!--        <main v-else>-->
<!--          &lt;!&ndash; if gift reciever has no address &ndash;&gt;-->
<!--          <section class="deliveryOptions">-->
<!--            <p>Адрес за доставка</p>-->
<!--            <div class="newAddress">-->
<!--              <Input-->
<!--                label="Град"-->
<!--                placeholder="Град"-->
<!--                v-model="newAddress.city"-->
<!--                :invalid="invalid.city === true"-->
<!--                @input="invalid.city = null"-->
<!--              />-->
<!--              <Input-->
<!--                label="Адрес"-->
<!--                placeholder="Адрес"-->
<!--                v-model="newAddress.address"-->
<!--                :invalid="invalid.address === true"-->
<!--                @input="invalid.address = null"-->
<!--              />-->
<!--            </div>-->

<!--            <div class="newAddress">-->
<!--              <Input-->
<!--                label="Етаж, вход, ап."-->
<!--                placeholder="Етаж, вход, ап."-->
<!--                v-model="newAddress.additional"-->
<!--                :invalid="invalid.additional === true"-->
<!--                @input="invalid.additional = null"-->
<!--              />-->
<!--              <Input-->
<!--                label="Телефон"-->
<!--                placeholder="Телефон"-->
<!--                v-model="newAddress.phone"-->
<!--                :invalid="invalid.phone === true"-->
<!--                @input="invalid.phone = null"-->
<!--              />-->
<!--            </div>-->
<!--            <div>-->
<!--              <Input-->
<!--                label="Допълнителна информация, коментар"-->
<!--                placeholder="Допълнителна информация, коментар"-->
<!--                v-model="newAddress.comment"-->
<!--              />-->
<!--            </div>-->
<!--            <button class="newAddress-button" @click="addAddress()">-->
<!--              Запиши адрес-->
<!--            </button>-->
<!--          </section>-->
<!--        </main>-->
        <span class="error">
          {{ error }}
        </span>
        <div class="gift-button-div">
          <button class="cancel-button" @click="save(false)">
            ОТКАЖИ ПОДАРЪКА
          </button>
          <button class="accept-button" @click="save(true)">
            ПРИЕМИ ПОДАРЪКА
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      gift: {},
      deliveryTypes: this.getDeliveryMethods(),
      deliveryType: {},
      address: {},
      hasAddress: true,

      newAddress: {
        city: '',
        address: '',
        additional: '',
        phone: '',
        comment: '',
        main: '',
      },
      invalid: {},
      error: '',
    };
  },
  watch: {
    $route: {
      handler: 'checkForAGift',
      immediate: true,
    },
    gift() {
      this.getDeliveryMethods(this.gift.product);
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'isAuthenticated']),
    addresses() {
      if (this.deliveryType.value === 'clientAdress') {
        return this.currentUser.addresses || [];
      } else if (this.deliveryType.value === 'sellerAdress') {
        return this.gift.product.cityRows || [];
      } else {
        return [];
      }
    },
  },
  methods: {
    close(update) {
      this.$emit('close', update);
    },
    checkForAGift() {
      this.gift = {};
      if (this.isAuthenticated) {
        this.$apiService.get('/orders/gifts/presents').then((res) => {
          if (res.data) {
            this.gift = res.data;
          }
        });
      }
    },
    isValid() {
      // if (!this.newAddress.city) {
      //   this.invalid.city = true;
      //   this.error = 'Градът е задължителен';
      //   return false;
      // }
      // if (!this.newAddress.address) {
      //   this.invalid.address = true;
      //   this.error = 'Адресът е задължителен';
      //   return false;
      // }
      // if (!this.newAddress.additional) {
      //   this.invalid.additional = true;
      //   this.error = 'Полето за етаж, вход и апартамент е задължително';
      //   return false;
      // }
      // if (!this.newAddress.phone) {
      //   this.invalid.phone = true;
      //   this.error = 'Телефонният номер е задължителен';
      //   return false;
      // }
      //
       return true;
    },
    addAddress() {
      if (this.isValid()) {
        this.currentUser.addresses.push({
          city: this.newAddress.city,
          address: this.newAddress.address,
          additional: this.newAddress.additional,
          phone: this.newAddress.additional,
          comment: this.newAddress.comment,
          main: !this.currentUser.addresses.length ? true : false,
        });

        this.$apiService
          .put(`/user/addresses`, this.currentUser)
          .then(() => {
            this.hasAddress = true;
          })
          .catch((err) => {
            this.error = err.response.data.message;
            this.loading = false;
          });
      }
    },
    save(c) {
      // if ((!this.deliveryType.name || !this.address.city) && c === true) {
      //   this.error = 'Не сте избрали метод и адрес за доставка';
      // } else {
        if (
          confirm(
            `Сигурни ли сте че искате да ${
              c ? 'приемете' : 'откажете'
            } подаръка?`
          )
        ) {
          this.$apiService
            .put(`/orders/gift/${this.gift._id}`, {
              confirm: c,
              deliveryType: this.deliveryType,
              address: this.address,
            })
            .then(() => {
              this.$snotify.success(`Подаръкът е ${c ? 'приет' : 'отказан'}`);
              this.deliveryType=''
              this.address=''
            })
            .catch((err) => {
              this.$snotify.error(err.response.data.message);
            })
            .then(() => {
              this.gift = {};
              // check again
              this.checkForAGift();
            });
        }
      // }
    },
    getDeliveryMethods(product) {
      let deliveryMethods = [];

      if (product) {
        if (product.cities.length) {
          const sellerName = product.seller.company.name
            ? product.seller.company.name
            : product.seller.company.company;
          deliveryMethods.push({
            name: `Доставка от ${sellerName} до мой адрес`,
            value: 'clientAdress',
          });
        }
        if (product.cityRows.length) {
          const sellerName = product.seller.company.name
            ? product.seller.company.name
            : product.seller.company.company;
          deliveryMethods.push({
            name: `Взимане от локация на ${sellerName}`,
            value: 'sellerAdress',
          });
        }
        if (product.type === 'reversed-auction') {
          const sellerName = product.seller.company.name
            ? product.seller.company.name
            : product.seller.company.company;
          deliveryMethods.push({
            name: `Доставка от ${sellerName} до мой адрес`,
            value: 'clientAdress',
          });
        }
      }
      this.deliveryTypes = deliveryMethods;
      return deliveryMethods;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;

  .confirm-title {
    font-size: 1.42857rem;
    font-weight: 500;
  }

  .confirm-message {
    font-size: 1.3rem;
    font-weight: 400;
  }
  .error {
    text-align: center;
    margin-bottom: 5px;
  }
  main {
    margin-bottom: 1rem;
    .microwallet-name {
      font-size: 1.42857rem;
      font-weight: 500;
      margin: 0.5rem 0 1rem;
      text-align: center;
    }
    .gift-comment {
      margin: 2rem 0;
      opacity: 0.8;
      text-align: center;
    }

    .first-rows-div {
      display: flex;

      .preo-points-input {
        flex-basis: 30%;
      }

      .email-input {
        flex-basis: 70%;
      }
    }

    .modal-text {
      font-size: 1.42857rem;
      font-weight: 400;
      text-align: center;
      width: 80%;
      margin: auto;
    }

    .deliveryOptions {
      background: #cbd3df63 0% 0% no-repeat padding-box;
      border: 1px solid #cdd3df;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 15px;
      margin: 10px 0;
      .add-adress {
        color: $mainAccent;
        cursor: pointer;
        width: fit-content;
        margin: 0 auto;
      }
      p {
        font-size: 18px;
        padding-bottom: 20px;
        text-align: center;
      }
      span {
        font-size: 12px;
        padding-top: 20px;
        text-align: center;
      }
      .newAddress {
        display: flex;
        justify-content: space-between;
        div {
          width: 48%;
          margin: 3px 0;
        }
      }
      .error {
        font-size: 14px;
      }
      .newAddress-button {
        background-color: #1473e6;
        color: #fff;
        margin-top: 5px;
      }
    }
  }
  .gift-button-div {
    margin: auto;
    margin-bottom: 1rem;

    .cancel-button {
      color: #fff;
      background-color: #ff4e8e;
    }
    .accept-button {
      background-color: #1473e6;
      color: #fff;
      margin-left: 0.5rem;
    }
  }

  &.confirm {
    .cancel-button-div {
      display: flex;
      justify-content: center;
      margin-top: 6%;
      margin-bottom: 5%;

      .cancel-button {
        background-color: #ec4256;
      }
    }
  }
}

@media screen and (max-width: $standard) {
  .modal-wrapper {
    display: flex;
    .modal {
      main {
        .first-rows-div {
          display: flex;
          flex-direction: column;

          .email-input {
            margin-left: 0;
            margin-top: 11px;
          }
        }

        .modal-text {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .modal-wrapper {
    display: flex;
    .modal {
      main {
        .first-rows-div {
          display: flex;
          flex-direction: column;

          .email-input {
            margin-left: 0;
            margin-top: 11px;
          }
        }

        .modal-text {
          width: 110%;
        }
      }
    }
    .gift-button-div {
      display: flex;

      .cancel-button {
        font-size: 13px;
      }

      .accept-button {
        font-size: 13px;
      }
    }
  }
}
@media screen and (max-width: 360px) {
  .modal-wrapper {
    display: flex;
    .modal {
      .microwallet-name {
        font-size: 19px;
      }

      .modal-text {
        font-size: 19px;
      }
      .confirm-title {
        font-size: 16px;
      }
      main {
        .first-rows-div {
          display: flex;
          flex-direction: column;

          .email-input {
            margin-left: 0;
            margin-top: 11px;
          }
        }

        .modal-text {
          width: 110%;
        }
      }
    }
    .gift-button-div {
      display: flex;
      .cancel-button {
        font-size: 10.7px;
      }
      .accept-button {
        font-size: 10.7px;
      }
    }
  }
}
</style>
