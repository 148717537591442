<template>
  <div class="user__wrapper">
    <main>
      <Nav />
      <MobileNav />
      <router-view />
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Nav from './Nav';
import MobileNav from '@/components/MobileNav';

export default {
  name: 'Home',
  components: {
    Nav,
    MobileNav,
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
.user__wrapper {
  padding: 0 $sides 1rem;
  > main {
    display: flex;
    margin: 0 auto;
    width: 100%;
  }
}
.user__profile__menu {
  padding: 12px 126px;
}
.user__profile__menu__toggle {
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #cbd3df;
  cursor: pointer;
  display: flex;
  height: 55px;
  justify-content: space-between;
  padding: 5px 9px 5px 21px;
  height: fit-content;
  @include tr();

  &:not(:last-child) {
    margin-bottom: 9px;
  }

  &:hover {
    background-color: #f8fafc;
  }

  .texts {
    color: #171717;
    overflow: hidden;
    margin-right: 10px;
    p {
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
    }
    span {
      font-size: $px12;
      font-weight: 300;
      line-height: $px12;
      opacity: 0.7;
    }
  }
}
.sellerActivity {
  position: relative;
  &:hover {
    .notSellerDiv--hover {
      height: 100%;
      opacity: 1;

      img {
        display: inline-block;
        opacity: 1;
      }
    }
  }
}
.notSellerDiv {
  background: transparent linear-gradient(180deg, #5c3882 0%, #665eff 100%) 0%
    0% no-repeat padding-box;
  color: #ffffff;
  padding: 10px 9px 10px 21px;

  &.notSellerDiv--hover {
    border: 1px solid #00000000;
    border-radius: 10px;
    height: 0%;
    left: 50%;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    @include transition(0.3s);
    img {
      display: none;
      opacity: 0;
      margin: 20px 0;
    }
  }
  .texts {
    color: #ffffff;
    span {
      display: block;
      margin: 2px 0;
    }
  }
  .btn-notSeller {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 16px;
    color: #6049ba;
    font-size: 12px;
    height: 32px;
    width: fit-content;
    white-space: nowrap;
    &:hover {
      color: white;
    }
    &.sentSellerReq {
      pointer-events: none;
      opacity: 0.7;
    }
  }
}

.user__inner-wrapper {
  background-color: $mainBg;
  border-radius: 10px;
  margin-top: calc(1rem + 5px);
  padding: 10px 14px;
  width: 100%;
}
.user__title {
  font-size: 15px;
  font-weight: 500;
}
.user__subtitle {
  color: #6e6e6e;
  font-size: $px12;
}
.user__inner-header {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
  div {
    > p {
      font-size: $px20;
      font-weight: 500;
    }
    .texts {
      span {
        font-size: $px12;
        line-height: $px12;
        opacity: 0.7;
      }
    }
  }
}
.user__back {
  align-items: center;
  background-color: #fff;
  border-radius: 13px;
  border: 1px solid #e9e9e9;
  color: #350887;
  display: inline-flex;
  height: 55px;
  justify-content: center;
  margin-right: 15px;
  min-width: 55px;
  width: 55px;
  @include tr();

  &:hover {
    background-color: #f8fafc;
  }
}

.user__main {
  color: #6e6e6e;
  margin: 0 auto;
  width: 631px;
  > p {
    font-size: $px12;
    margin-bottom: 23px;
    &.divider {
      border-bottom: 1px solid #000;
      color: #000;
      font-weight: 700;
      margin-bottom: 11px;
      padding-bottom: 0.25rem;
    }
    .sentence {
      margin-top: 0.5rem;
      display: block;
    }
  }
}
.user__row {
  display: flex;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 11px;
  }
  .download-banner-wrapper{
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  .download--banner{
    height: 25px;
    @include hover()
  }
  }
  .removePlan{
    // align-items: center;
    // display: flex;
    font-size: 25px;
    // margin-right: -15px;
    margin-left: 10px;
  }
  .input-wrapper {
    &:not(:first-child) {
      margin-left: 11px;
      &.planPrice{
        text-align: right ;

        span{
          font-weight: bold;
        }
      }
    }
      &.banner-wrapper{
        white-space: nowrap;
        }
  }
  .checkbox-container {
    margin-right: 8px;
    & + p {
      color: #000;
    }
  }
  &.user__row--upload {
    flex-direction: column;
    > p {
      color: #6e6e6e;
      font-size: $px12;
      margin-bottom: 5px;
    }
  }
  &.secondSlider{
    margin-top: 15px;
  }
  &.user__row--slider {
    align-items: center;
    .checkbox-slider {
      margin-right: 11px;
    }
    .texts {
      p {
        font-size: $px11;
        strong {
          color: #000;
          font-size: $px12;
        }
      }
    }
  }
  &.user__row--slider-input {
    padding-left: 51px;
  }
  &.user__row--table {
    border-radius: 10px;
    border: 1px solid #cbd3df;
    margin-bottom: 11px;
    table {
      border-collapse: collapse;
      width: 100%;
      tr {
        td {
          background-color: #fff;
          border-bottom: 1px solid #cbd3df;
          padding: 1rem;
          &:last-child {
            text-align: right;
          }
        }
        &:first-child {
          td {
            border-bottom: 2px solid #cbd3df;
            &:first-child {
              border-top-left-radius: 10px;
            }
            &:last-child {
              border-top-right-radius: 10px;
            }
          }
        }
        &:last-child {
          td {
            border: 0;
            &:first-child {
              border-bottom-left-radius: 10px;
            }
            &:last-child {
              border-bottom-right-radius: 10px;
            }
          }
        }
      }
    }
    & + p {
      margin-top: 23px;
    }
  }
}

.user__footer {
  align-items: center;
  border-top: 1px solid #000;
  display: flex;
  justify-content: space-between;
  margin: 26.5px auto 0;
  padding-bottom: 1rem;
  padding-top: 16.5px;
  width: 631px;
}

.user__footer > div,
.user__row__buttons {
  align-items: center;
  display: flex;
}

.user__footer,
.user__row__buttons,
.user__row--slider {
  button {
    align-items: center;
    background-color: #c6c6c6;
    border: none;
    border-radius: 16px;
    display: flex;
    font-size: $px12;
    font-weight: 600;
    height: 32px;
    justify-content: center;
    margin-left: 5px;
    outline: none;
    min-width: 85px;
    @include tr();
    &:disabled {
      opacity: 0.5;
    }
    &:not(:disabled) {
      cursor: pointer;
      &:hover {
        background-color: #b2b2b2;
      }
    }
    &.save {
      background-color: #1473e6;
      color: #fff;
      &:hover {
        background-color: #0059c5;
      }
    }
    &.remove {
      background-color: $mainRemove;
      color: #fff;
      &:hover {
        background-color: $hoverRemove;
      }
      &.deleteEmployee {
        white-space: nowrap !important;
        min-width: max-content !important;
      }
    }
    &.double {
      background-color: #256819;
      color: #fff;
      &:hover {
        background-color: #398f2a;
      }
    }
  }
}

.table {
  border: 1px solid #cbd3df;
  border-radius: 10px;
  margin: 4px 0;
  width: 100%;
  table {
    border-collapse: collapse;
    border-radius: 10px;
    border: none;
    width: 100%;
    .order-details-box {
      min-height: 40px;
    }
    th,
    td {
      border-bottom: 1px solid #cbd3df;
      min-height: 40px;
      padding: 1rem;
    }
    .order-details {
      margin-top: 1px;
      border-bottom: 0px solid #cbd3df;
    }
    .product-details {
      margin-bottom: 0px;
      border-bottom: 0px solid #cbd3df;
    }
    thead {
      tr {
        th {
          border-bottom: 2px solid #cbd3df;
          &:first-child {
            border-top-left-radius: 10px;
          }
          &:last-child {
            border-top-right-radius: 10px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &.td--main-photo {
            padding-bottom: 4px;
            padding-top: 4px;
            width: 1%;
            img {
              border: 2px solid #3e3e3e;
              border-radius: 4px;
              display: inline-block;
              height: 39px;
              -o-object-fit: cover;
              object-fit: cover;
              vertical-align: middle;
              width: 39px;
            }
          }

          &.td--type {
            .icons {
              font-size: $px20;
              margin-right: 11px;
              vertical-align: middle;
            }
          }
          .sub {
            font-size: $px12;
            opacity: 0.5;
          }
        }
        &:last-child {
          td {
            border-bottom: 0;
            &:first-child {
              border-bottom-left-radius: 10px;
            }
            &:last-child {
              border-bottom-right-radius: 10px;
            }
          }
        }
        &.clickable {
          td {
            cursor: pointer;
          }
          &:hover {
            td {
              background-color: rgba($color: #cbd3df, $alpha: 0.1);
            }
          }
        }
      }
    }
  }
}

.add-more {
  align-items: center;
  display: flex;
  .icons-button {
    margin-right: 19px;
  }

  & ~ .add-more {
    margin-top: 10px;
  }
}
.horizont-nav-button {
  position: fixed;
  bottom: 120px;
  right: 1vh;
  z-index: 5;
}
.horizont-nav {
  align-items: center;
  background-color: #240460;
  bottom: 0%;
  display: flex;
  height: 80px;
  justify-content: center;
  left: 0%;
  position: fixed;
  padding: 8px 0;
  right: 0%;
  width: 100%;
  z-index: 200;
  .router-div {
    margin: 0 5px;
  }
  .router-wrapper {
    align-items: center;
    color: $mainBg;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 55px;
    height: 50px;
    text-transform: uppercase;
    @include hover();
    @include noselect();

    span {
      font-size: 20px;
      margin-bottom: 4px;
    }
    a,
    p {
      text-align: center;
      font-size: 9px;
      line-height: 9px;
    }
    &:not(:first-child) {
      margin-left: 4px;
    }
    img{
      filter: brightness(0) invert(1);
      max-width: 25px;
      max-height: 25px;
      &.margin--bottom{
        margin-bottom: 4px;
      }
    }
    .img-active {
      display: none;
    }
    .img-inactive {
      display: block;
    }

    &.active {
      color: #00d4ff;
      .img-active {
        display: block;
      }
      .img-inactive {
        display: none;
      }
    }
    &.inactive {
      color: $mainBg;

      .img-active {
        display: none;
      }
      .img-inactive {
        display: block;
      }
    }
  }
}

.vertical-nav {
  background-color: #240460;
  bottom: 64px;
  display: flex;
  flex-direction: row;
  position: fixed;
  left: 0%;
  right: 0%;
  z-index: 10;
  .myProfile {
    background: #d0d6ec;
    box-shadow: 0px 0px 90px #00000029;
    bottom: 78px;
    border: 1px solid #cbd3df;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    position: fixed;
    padding: 20px 5px;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    width: 297px;
    left: 0;

&:before {
   content: "";
   position: absolute;
   top: 100%;
   border-top: 20px solid black;
   border-top-color: inherit;
   border-left: 15px solid transparent;
   border-right: 15px solid transparent;
}
   &.leftBubbleAdmin{
     &:before {
       right: 77.5%;
   }
   }
   &.slightLeftAdmin{
     &:before {
       right: 56.5%;
   }
   }
   &.slightRightAdmin{
     &:before {
       right: 34%;
   }
   }
   &.rightBubbleAdmin{
     &:before {
       right: 12% !important;
   }
   }

   &.leftBubbleClient{
     &:before {
       right: 67%;
   }
   }
   &.rightBubbleClient{
     &:before {
       right: 23% !important;
   }
   }
   &.rightBubbleEmployee{
     &:before {
       right: 25.5% !important;
   }
   }

   &.leftBubble{
     &:before {
       right: 88.5%;
   }
   }
   &.centerBubble{
     &:before {
       right: 45%;
   }
   }
   &.slightLeftBubble{
     &:before {
       right: 68.7%;
   }
   }
   &.rightBubble{
     &:before {
       right: 1%;
   }
   }
   &.rightBubbleNotVerifiedSeller{
     &:before {
       right: 21% !important;
   }
   }
   &.centerBubbleNotVerifiedSeller{
     &:before {
       right: 65% !important;
   }
   }

    .profile {
      color: #401e63;
      text-align: center;
      margin-bottom: 20px;
    }
    .router {
      align-items: center;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #cbd3df;
      border-radius: 10px;
      display: flex;
      height: 55px;
      padding: 0 15px;

      img,
      .icons {
        max-width: 14px;
        max-height: 16px;
        object-fit: scale-down;
        margin-right: 15px;
      }
      p{
        font-weight: bold;
      }
      span {
        opacity: 0.8;
      }
      &:hover,&.router-link-active {
        background: #401e63 0% 0% no-repeat padding-box;
        color: white;
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
  div {
    background-color: $mainBg;
    border-radius: 30px;
    display: flex;
    margin: 4px;
    width: 100%;
    justify-content: center;

    p {
      width: fit-content;
      padding: 6px;
      white-space: pre-wrap;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: $sm) {
  .user__wrapper {
    padding: 0 $sides--sm 1rem;
  }
}

@media screen and (max-width: $standard) {
  .user__wrapper {
    padding: 0;
    > main {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  .user__box {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-right: 5vh;
    width: 100%;
    .user__subtitle {
      color: #6e6e6e;
      font-size: $px12;
      margin: 0.5rem 0;
    }
  }
  .user__back {
    margin-right: 10px;
  }
  .user__inner-wrapper {
    margin-top: 0%;
    margin-bottom: 35%;
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% - #{$sides--sm});

    .searchbar-wrapper {
      display: flex;
      justify-content: center;

      .searchbar {
        margin-right: 0;
      }

      button {
        margin-right: 5%;
        margin-left: 0;
        flex-basis: 30%;
        white-space: initial !important;
      }
    }

    .table {
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: auto;
      @include scrollbar(8px, $mainColor);
    }

    .user__profile__menu {
      padding: 0%;
      margin: 0%;
    }
  }
}
@media screen and (max-width: $standard) {
  .user__profile__company {
    .user__main {
      width: 100%;

      p {
        margin-left: 10%;
        margin-right: 10%;
      }

      .user__row {
        width: 125%;
        flex-direction: column;
        justify-content: center;

        .input-wrapper {
          width: 80%;
          margin-bottom: 2%;
        }

        .input-wrapper:not(:first-child) {
          margin-left: 0px;
        }
      }

      .user__row:not(:last-child) {
        margin-bottom: 0px;
      }

      .user__row--slider {
        display: flex;
        flex-direction: row;
        padding-left: 10%;
        padding-right: 10%;
        margin-top: 3%;
        .texts {
          p {
            margin-left: 0%;
            margin-right: 15%;
          }
        }
      }
    }

    .user__footer {
      width: 100%;
      flex-direction: column;
      padding-bottom: 2rem;

      p {
        margin-bottom: 3%;
        width: 90%;
      }
    }
  }
}
</style>
