<template>
  <div v-if="!filtered" style="position: relative;">
    <div class="menu-r" id="menuId" :class="{'remove-shadow': menuActive }">
      <p v-if="!isMobile" class="category">Категории</p>
      <ul
        v-on:mouseover="getData(category)"
        v-for="(category, i) in allCategories"
        :key="`category-${i}`"
      >
        <li v-if="category.rows > 0"
          v-on:click="activeCategory = activeCategory === i ? '' : i"
          style="display: flex;">
          <img
            :src="require(`@/assets/img/categories/${category.icon}`)"
            :alt=category.label
          />

          <p>{{ category.label }}</p>
          <img v-if="isMobile"
               class="category-arrow"
               :class="activeCategory === i && 'menu-open'"
               :src="`${uploads}/assets/img/arrow-new.svg`">
        </li>

        <li v-if="isMobile && activeCategory === i" class="subcategories-mobile" >
          <CategoryTree
            :categoryData="category.children"
            :isSubcategory="false"
          >
          </CategoryTree>
        </li>
      </ul>
    </div>
  </div>
  <div v-else class="home__pr-cat" ref="home">
    <main class="pr-cats" ref="cats">
      <section
        ref="cat"
        class="pr-cat"
        v-for="(category, i) in filteredCategories"
        @click="openCategory(category)"
        :key="`category-${i}`"
      >
        <img
          :style="{
            backgroundColor: `${category.color}`,
            border: isMobile ? ` 10px solid ${category.color}` : 'none'
          }"
          :src="require(`@/assets/img/categories/${category.icon}`)"
          :alt=category.label
        />
        <p>{{ category.label }}</p>
      </section>
    </main>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import CategoryTree from "@/views/home/CategoryTree.vue";

export default {
  props: {
    getCategoryData: Function,
    filtered: Boolean,
    rows: Number,
  },
  components: {CategoryTree},
  data() {
    return {
      menuActive:false,
      activeCategory: null,
    };
  },
  computed: {
    ...mapGetters(['currentUser', "isMobile", "allCategories", "filteredCategories"]),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
    lineHeight(){
      let innerHeight = document.getElementById('menuId').offsetHeight;
      let categoriesCount = this.allCategories.filter(category => category.rows > 0).length

      console.log(innerHeight, categoriesCount, innerHeight/categoriesCount)
      return (innerHeight - 35)/categoriesCount
      // return document.getElementById('menuId').offsetHeight / this.allCategories.filter(category => category.rows > 0).length;
    }
  },
  methods: {
    getData(category) {
      this.menuActive = category.length > 0;
      this.getCategoryData(category)
    },
    openCategory(c) {
      this.$router.push(`/products?search=&categories=${c.label}`);
    },
  },
  mounted() {
    this.$store.dispatch('getCategories')
    if (this.product?.type === 'microwallet') {
      this.product.continuity = 'Временна';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';

.remove-shadow{
  box-shadow: none;
}
.menu-r {
  width: 300px;
  background-color: #ffffff;
  color: #707070;
  z-index: 1;
  margin-top: -35px;
  border-radius: 15px;
  height: calc(100% + 35px);
  box-shadow: -50px 50px 50px 5px rgba(231, 231, 231, 0.85);


  p {
    padding-left: 20px;
    line-height: 63px;
    font-weight: bold;
    margin-left: 10px;
    //width: 80%;
  }

  ul {
    padding-left: 20px;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    cursor: url('../../assets/img/cursor.png'), auto;
    list-style: none;

    &:hover {
      background-color: #b100b2;
      color: white;
    }

    img {
      width: 24px;
      filter: invert(42%) sepia(1%) saturate(0%) hue-rotate(216deg) brightness(93%) contrast(94%);
    }
    .category-arrow{
      margin-top: 23px;
      transform: translate(0, -50%) rotate(180deg) scale(0.6);
      position: absolute;
      right: 10px;
    }
    .menu-open{
      transform: rotate(90deg) scale(0.6);
    }
  }

  ul:hover img{
      filter: none;
  }
  a {
    padding: 0;
  }
}
.box-shadow{
  box-shadow: none;
}
.home__pr-cat {
  padding-top: 20px;
}
.pr-cats {
  display: flex;
  justify-content: space-evenly;
  max-width: 1000px;
  margin: 0 auto;
  @include scrollbar(5px, #cacaca);

  .pr-cat {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 98px;
    cursor: url('../../assets/img/cursor.png'), auto;
    &:hover {
      p {
        text-decoration: underline;
      }
    }

    img {
      border-radius: 10px;
      height: 98px;
      object-fit: scale-down;
      margin-bottom: 10px;
      width: 100%;
    }
  }
}
@media all and (max-width: $l){
  .menu-r {
    width: 280px;
  }
}
@media all and (max-width: $sm) {
  .menu-r {
    width: 240px;
    p {
      line-height: 47px;
    }
  }
}

@media all and (max-width: $sm){
  .pr-cats .pr-cat img {
    height: 88px;
    width: 88px;
  }
}
@media all and (max-width:$standard) {
  .home__pr-cat {
    margin-left: 10px;
    margin-bottom: 0;
  }
  .pr-cats {
    max-width: max-content !important;
    overflow: auto;
    justify-content: flex-start;
    scroll-snap-type: x mandatory;
    transition: transform 1s ease;

    &::-webkit-scrollbar {
      // hides Scrollbar
      display: none;
    }

    .pr-cat {
      min-width: 14vw;
      margin: 6px;
      scroll-snap-align: start;
      p{
        width: 88px;
      }
      img {
        //min-height: 14vw;
        //height: 14vw;
      }
    }
  }
  .menu-r{
    position: absolute;
    margin-top: 0;
    border-radius: 0;
    margin-left: -9px;
    width: 350px;
    font-size: 14px;
    height: fit-content;
    max-height: 400px;
    //overflow: auto;
    z-index: 9999;
    overflow: scroll;

    ul{
      display: block;
      img{
        width: 20px;
      }
      &:hover{
        background-color: transparent;
        color: #707070;
        img{
          filter: invert(42%) sepia(1%) saturate(0%) hue-rotate(216deg) brightness(93%) contrast(94%);
        }
      }
    }
  }
}
@media all and (max-width: 650px) {
  .pr-cats {
    .pr-cat {
      display: block;
      min-width: 70px;
      margin: 6px;
      p{
        width: auto;
        font-size: 12px;
      }
      img {
        height: 68px;
        width: 68px;
      }
    }
  }
}
@media all and (max-width:$xs) {
  .pr-cats {
    .pr-cat {
      min-width: 60px;
      margin: 0;
      p{
        font-size: 11px;
      }
      img {
        height: 58px;
        width: 58px;
      }
    }
  }
}
@media all and (max-width:$xxs) {
  .pr-cats {
    .pr-cat {
      min-width: 50px;
      margin: 4px;
      img {
        height: 48px;
        width: 48px;
      }
    }
  }
}
</style>
