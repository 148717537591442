
import router from "@/router";

export function getRemainingDays(isComminUp, date) {
  const today = new Date()
  date = new Date(date)

  if (isComminUp) {
    date.setHours(0, 0, 0, 0);
  } else{
    date.setHours(23, 559, 59, 999);
  }

  const total = date.getTime() - today.getTime();
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    days: days,
    hours: hours,
    minutes: minutes,
  };
}

export function checkExpirationPeriodForPreoVouchers(startDate, dueDate) {
  const today = new Date

  if (today.getTime() >= startDate.getTime() && today.getTime() <= dueDate.getTime()) {
    const remainingDays = getRemainingDays(true, dueDate)

    return {
      label: ` Оставащо време за ползване:
      ${remainingDays.days} ${remainingDays.days === 1 ? 'ден' : 'дни'},
      ${remainingDays.hours} ${remainingDays.hours === 1 ? 'час' : 'часа'},
      ${remainingDays.minutes} ${remainingDays.minutes === 1 ? 'минута' : 'минути'}`,
      color: '#4fd746',
      showGiftOption: true,
    }
  } else if (today.getTime() < startDate.getTime()) {
    const remainingDays = getRemainingDays(true, startDate)
    return {
      label: `Остават ${remainingDays.days} ${remainingDays.days === 1 ? 'ден' : 'дни'}, преди да може да използвате Preo ваучера`,
      color: '#b846d7',
      showGiftOption: true,
    }
  } else if (today.getTime() > dueDate.getTime()) {
    return {
      label: "Периодът за ползване е изтекъл",
      color: '#ce2323',
      showGiftOption: false,
    }
  }
}

export function redirectToBy(shouldRedirect, trueLink, falseLink){
  if(shouldRedirect){
    return router.push(trueLink)
  }else{
    return router.push(falseLink)
  }
}
