import {ApiService} from "@/common/api.service";
import {getRandomNumber} from "vue-share-buttons/src/helpers";

const state = {
  favouriteProducts: [],
  message:''
}

const getters = {
  favouriteProducts: state => state.favouriteProducts,
  message: state => state.message,
}

const actions = {
  async getFavouriteProducts(context) {
    if(context.getters.isAuthenticated && context.getters.currentUser.role !== 'admin') {
      await ApiService.get(`/favourite-products`).then((res) => {
        context.commit('setFavouriteProducts', res.data);
      });
      await context.dispatch('saveFavouriteAfterLogin');
    }else if (context.getters.isAuthenticated && context.getters.currentUser.role === 'admin'){
      localStorage.removeItem('favourites')
      context.commit('resetFavouriteProducts')
    }
  },
  saveFavouriteAfterLogin(context){
    const localStorageFavourites = JSON.parse(localStorage.getItem('favourites'))

    if(localStorageFavourites){
      localStorageFavourites.map(async item => {
        const exist = state.favouriteProducts.find(fav => fav.product._id === item.product._id)

        if (!exist) {
          await context.dispatch('addToFavourites', item.product)
        }
      })
      localStorage.removeItem('favourites')
    }

  },
  async handleFavouriteProduct(context, product) {
      const productExist = state.favouriteProducts.find(favourite => favourite.product._id === product._id)

      if (!productExist) {
          await context.dispatch('addToFavourites', product)
      } else {
          await context.dispatch('deleteFromFavourites', productExist._id)
      }
  },
  async addToFavourites(context, product) {
    if(context.getters.isAuthenticated){
      await ApiService
        .post("/favourite-products", {
          product: product._id,
        })
        .then((res) => {
          const data = {
            _id: res.data.product,
            product: product
          }

          context.commit('setFavouriteProduct', {action: 'add', data: data});
          context.commit('setMessage', {status: 'success', message: "Успешно добавихте обявата към Любими"});
        })
        .catch((error) => {
          context.commit('setMessage', {status: 'error', message: `Възникна грешка: ${error}`});
        });
    }else{
      context.commit('setFavouriteProduct', {
        action: 'add', data: {
          _id: getRandomNumber(),
          product: product
        }
      });
      localStorage.setItem('favourites', JSON.stringify(state.favouriteProducts))
    }
  },
  async deleteFromFavourites(context, id) {
    if(context.getters.isAuthenticated){
      await ApiService
        .delete(`/favourite-products/${id}`)
        .then(() => {
          context.commit('setFavouriteProduct', {action: 'delete', id: id})
          context.commit('setMessage', {status: 'success', message: "Успешно премахнахте обявата от Любими"});
        })
        .catch((error) => {
          context.commit('setMessage', {status: 'error', message: `Възникна грешка: ${error}`});
        });
    }else{
      context.commit('setFavouriteProduct', {action: 'delete', id: id})
      localStorage.setItem('favourites', JSON.stringify(state.favouriteProducts))
    }
  },
}

const mutations = {
  setFavouriteProducts(state, data) {
    state.favouriteProducts = data
  },
  setMessage(state, data) {
    state.message = data
  },
  resetFavouriteProducts(state){
      state.favouriteProducts = []
  },
  setFavouriteProduct(state, data){
   if (data.action === 'add'){
      state.favouriteProducts.push(data.data)
    }else{
      state.favouriteProducts = state.favouriteProducts.filter(favourite => favourite._id !== data.id)
    }
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};
