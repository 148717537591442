<template>
  <div  v-if="$route.name === 'Home'">
    <!-- MOBILE NAV -->
    <template v-if="mobile">
      <div class="horizont-nav">
        <div
          @click="openMenu('myOrders')"
          class="router-div"
        >
          <section
            class="router-wrapper"
            :class="{
              active: 'myOrders',
              inactive: selected !== 'myOrders',
            }"
          >
            <span class="icons">
              <img
                class="img-inactive"
                src="../assets/img/nav/mobile/cart.svg"
                alt="Preonow"
              />
              <img
                class="img-active"
                src="../assets/img/nav/mobile/cart-active.svg"
                alt="Preonow"
              />
            </span>
            <a>МОИТЕ ПОРЪЧКИ</a>
          </section>
        </div>

        <div
          @click="openMenu('sellerFavouritesNav')"
          class="router-div"
        >
          <router-link
            class="router-wrapper"
            :class="{
              active: 'sellerFavouritesNav',
              inactive: selected !== 'sellerFavouritesNav',
            }"
            :to="`/${currentUser.role}/favourites`"
          >
            <span class="icons">8</span>
            <a>Любими</a>
          </router-link>
        </div>

        <div
          @click="openMenu('profilesAndSettings')"
          class="router-div"
        >
          <section
            class="router-wrapper"
            :class="{
              active: isActivated && selected === 'profilesAndSettings',
              inactive: selected !== 'profilesAndSettings',
            }"
            @click.prevent="myProfil = true"
            :to="`/${currentUser.role}/profile/menu`"
          >
            <span class="icons">
              <img
                class="img-inactive"
                :src="`${uploads}/assets/img/nav/mobile/profile.svg`"
                alt="Preonow"
              />
              <img
                class="img-active"
                src="../assets/img/nav/mobile/profile-active.svg"
                alt="Preonow"
              />
            </span>
            <a v-if="!currentUser.verified">ПРОФИЛИ И НАСТРОЙКИ</a>
            <a v-else>МОЯТ ПРОФИЛ</a>
          </section>
        </div>

      </div>

      <div class="vertical-nav">
        <section
          class="myProfile centerBubble"
          :class="{centerBubbleNotVerifiedSeller: !currentUser.verified}"
          v-if="
            selected === 'myOrders' &&
            isActivated
          "
        >
         <p class="profile">МОИТЕ ПОРЪЧКИ</p>
          <main @click="isActivated = false">
            <router-link
              :to="getUrl('orders')"
              class="router"
            >
              <p class="icons">i</p>
              <p>Поръчки</p>
            </router-link>
            <router-link
              class="router"
              :to="getUrl('preoVouchers')"
            >
              <p class="icons">V</p>
              <p>Preo ваучери</p>
            </router-link>
            <router-link
              v-if="currentUser.verified"
              class="router"
              :to="getUrl('b2b')"
            >
              <p class="icons">!</p>
              <p>B2B</p>
            </router-link>
          </main>
        </section>
        <!-- МОИТЕ ПОРЪЧКИ SELLER-->

        <!-- ПОРЪЧКИ ADMIN-->
        <section
          class="myProfile leftBubbleAdmin"
          v-if="
            currentUser.role === 'admin' &&
            selected === 'adminPanel' &&
            isActivated
          "
        >
          <p class="profile">ПОРЪЧКИ</p>
          <main @click="isActivated = false">
            <router-link :to="`/${currentUser.role}/orders-sellerAsClient`" class="router">
              <p class="icons">i</p>
              <p>Поръчки</p>
            </router-link>
          </main>
        </section>
        <!-- ПОРЪЧКИ ADMIN-->

        <!-- БД ПРОДУКТИ ADMIN-->
        <section
          class="myProfile slightLeftAdmin"
          v-if="
            currentUser.role === 'admin' &&
            selected === 'database-products' &&
            isActivated
          "
        >
          <p class="profile">БД ПРОДУКТИ</p>
          <main @click="isActivated = false">
            <router-link to="/admin/products/product-service" class="router">
              <p class="icons">Q</p>
              <p>СТОКИ И УСЛУГИ</p>
            </router-link>
            <router-link to="/admin/products/microwallet" class="router">
              <p class="icons">V</p>
              <p>PREO ВАУЧЕРИ</p>
            </router-link>
            <router-link to="/admin/products/auction" class="router">
              <p class="icons">S</p>
              <p>ТЪРГОВЕ</p>
            </router-link>
            <router-link to="/admin/products/reversed-auction" class="router">
              <p class="icons">R</p>
              <p>ОБРАТНИ ТЪРГОВЕ</p>
            </router-link>
            <router-link to="/admin/products/b2b" class="router">
              <p class="icons">!</p>
              <p>B2B</p>
            </router-link>
          </main>
        </section>
        <!-- БД ПРОДУКТИ ADMIN-->

        <!-- БД ПОТРЕБИТЕЛИ ADMIN-->
        <section
          class="myProfile slightRightAdmin"
          v-if="
            currentUser.role === 'admin' &&
            selected === 'database-users' &&
            isActivated
          "
        >
          <p class="profile">БД ПОТРЕБИТЕЛИ</p>
          <main @click="isActivated = false">
            <router-link to="/admin/sellers" class="router">
              <p class="icons">F</p>
              <p>ТЪРГОВЦИ</p>
            </router-link>
            <router-link to="/admin/not-verified-sellers" class="router">
              <p class="icons">]</p>
              <p>ЮРИДИЧЕСКИ ЛИЦА</p>
            </router-link>
            <router-link to="/admin/clients" class="router">
              <p class="icons">D</p>
              <p>ФИЗИЧЕСКИ ЛИЦА</p>
            </router-link>
          </main>
        </section>
        <!-- БД ПОТРЕБИТЕЛИ ADMIN-->

        <!-- МОЯТ ПРОФИЛ / ПРОФИЛ И НАСТРОЙКИ-->
        <section
          class="myProfile rightBubble"
          :class="{rightBubbleClient:currentUser.role === 'client', rightBubbleEmployee:currentUser.employee,
          rightBubbleAdmin:currentUser.role === 'admin',
          rightBubbleNotVerifiedSeller: !currentUser.verified && currentUser.role === 'seller'&& !currentUser.employee
          }"
          v-if="selected === 'profilesAndSettings' && myProfil"
        >
          <p class="profile">ПРОФИЛ И НАСТРОЙКИ</p>
          <main @click="myProfil = false">
            <router-link
              v-if="!currentUser.employee"
              :to="`/${currentUser.role}/profile/menu`"
              class="router"
            >
              <img :src="`${uploads}/assets/img/nav/profile.svg`" alt="Preonow" />
              <section>
                <p>
                  {{
                    currentUser.role === 'seller'
                      ? currentUser.company.name
                      : currentUser.name
                  }}
                </p>
                <span>Моят профил</span>
              </section>
            </router-link>
            <router-link
              v-if="currentUser.employee"
              :to="`/seller/profile/employee/${currentUser._id}`"
              class="router"
            >
              <img :src="`${uploads}/assets/img/nav/profile.svg`" alt="Preonow" />
              <section>
                <p>{{ currentUser.name }}</p>
                <span>Моят профил</span>
              </section>
            </router-link>
            <router-link
              v-if="currentUser.verified"
              to="/seller/objects"
              class="router"
            >
              <section class="icons">^</section>
              <section>
                <p>ОБЕКТИ</p>
                <span>Моите обекти</span>
              </section>
            </router-link>
            <router-link
              v-if="currentUser.verified"
              to="/seller/employees"
              class="router"
            >
              <img :src="`${uploads}/assets/img/nav/employees.svg`" alt="Preonow" />
              <section>
                <p>СЛУЖИТЕЛИ</p>
                <span>Акаунти на моите служители</span>
              </section>
            </router-link>
            <router-link
              v-if="currentUser.verified"
              :to="`/${currentUser.role}/tips`"
              class="router"
            >
              <img :src="`${uploads}/assets/img/nav/tips.svg`" alt="Preonow" />
              <section>
                <p>БАКШИШИ</p>
                <span>Списък и отчетност</span>
              </section>
            </router-link>
            <!-- admin -->
            <router-link
              to="/admin/homepage"
              class="router"
              v-if="currentUser.role === 'admin'"
            >
              <p class="icons">5</p>
              <p>НАЧАЛНА СТРАНИЦА</p>
            </router-link>
            <router-link
              to="/admin/admins"
              class="router"
              v-if="currentUser.role === 'admin' && !currentUser.employee"
            >
              <img :src="`${uploads}/assets/img/nav/employees.svg`" alt="" />
              <p>ПОТРЕБИТЕЛИ</p>
            </router-link>
            <!-- admin -->

            <a class="router" @click="logout">
              <section class="icons">W</section>
              <section>
                <p>ИЗХОД</p>
                <span>Preonow</span>
              </section>
            </a>
          </main>
        </section>
        <!-- МОЯТ ПРОФИЛ / ПРОФИЛ И НАСТРОЙКИ-->
      </div>

      <!-- VERTCAL NAV -->
    </template>
    <!-- MOBILE NAV -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['mobileOnDesktop'],
  data() {
    return {
      count: {},
      selected: '',
      mobile: false,
      isActivated: false,
      open: false,
      myProfil: false,
      openUserMenu: false,
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    uploads() {
      return `${process.env.VUE_APP_UPLOADS_URI}`;
    },
  },
  watch: {
    $route: {
      handler: 'routeChange',
      immediate: true,
    },
  },
  methods: {
    openMenu(menu){
      this.isActivated = this.selected !== menu ? true : !this.isActivated
      this.selected = menu;

      console.log('on click menu', this.selected)
    },
    getUrl(name){
      switch (name){
        case 'orders':
          switch (this.currentUser.role){
            case 'seller':
              return `/${this.currentUser.role}/orders-sellerAsClient`;
            case 'client':
              return '/client/orders';
            case 'admin':
              return  `/${this.currentUser.role}/orders`
          }
        break;
        case 'preoVouchers':
          switch (this.currentUser.role){
            case 'seller':
              return `/${this.currentUser.role}/preoVouchers-sellerAsClient`;
            case 'client':
              return '/client/preo-vouchers';
            case 'admin':
              return  `/${this.currentUser.role}/products/microwallet`
          }
          break;
        case 'b2b':
          switch (this.currentUser.role){
            case 'seller':
              return `/${this.currentUser.role}/b2b-sellerAsClient`;
            case 'client':
              return '';
            case 'admin':
              return `/${this.currentUser.role}/products/b2b`
          }
        break;
      }
    },
    routeChange() {
      if (this.currentUser.role === 'admin') {
        this.countProducts();
        this.countUsers();
      } else if (this.currentUser.role === 'seller') {
        this.countProducts(true);
      }
      this.open = false;
    },
    countProducts(seller) {
      const types = [
        'product-service',
        'microwallet',
        'auction',
        'reversed-auction',
        'b2b',
      ];

      for (let t of types) {
        this.$apiService
          .get(
            `/products/seller/${t}${
              seller ? `/${this.currentUser._id}` : ''
            }?count=1`
          )
          .then((res) => {
            this.$set(this.count, t, res.data || 0);
          })
          .catch(() => this.$set(this.count, t, 0));
      }
    },
    countUsers() {
      const roles = [
        { role: 'client' },
        { role: 'seller', verified: 'true' },
        { role: 'not-verified-seller', verified: 'false' },
      ];
      let url = '';

      for (let r of roles) {
        if (r.role !== 'client') {
          url = `/users/?role=seller&employee=false&count=1&verified=${r.verified}`;
        } else {
          url = `/users/?role=client&employee=false&count=1`;
        }
        this.$apiService
          .get(url)
          .then((res) => {
            this.$set(this.count, r.role, res.data || 0);
          })
          .catch(() => this.$set(this.count, r.role, 0));
      }
    },
    goTo(r) {
      if (r !== this.$route.path) {
        this.$router.push(r);
      }
    },
    logout() {
      this.$store.dispatch('logout').then(() => this.$router.push('/'));
    },
    isMobileMode() {
      if (
        window.innerWidth < 900 ||
        this.$route.name === 'employeePaymentRequest' ||
        this.mobileOnDesktop
      ) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },
    wantsToBeSeller() {
      this.$set(this.currentUser, 'wantsToBeSeller', true);
      this.$apiService
        .put(`/user/wantsToBeSeller`, this.currentUser)
        .then(() => {
          this.$router.push('/seller/profile/menu');
        })
        .catch((err) => {
          this.error = err.response.data.message;
        });
    },
  },
  mounted() {
    this.isMobileMode();
    window.addEventListener('resize', this.isMobileMode);
    if (this.$route.query.s) {
      this.selected = this.$route.query.s;
      this.active = true;
      this.isActivated = true;
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.isMobileMode);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.profile__nav {
  margin-right: 13px;
  min-width: 280px;
  width: 280px;
  > p {
    color: rgba($color: #171717, $alpha: 0.5);
    font-size: $px12;
    margin-bottom: 5px;
    padding: 0 $px12;
  }
  &.router-link-active:not(.router-link-inactive) {
    img {
      filter: invert(100%);
    }
  }
}

.create-new {
  background: rgb(252, 251, 255);
  border-radius: 15px;
  box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.25);
  margin-left: 275px;
  height: fit-content;
  position: absolute;
  padding: 0 3vh;
  width: 350px;
  z-index: 1;

  p {
    font-weight: 900;
    text-align: center;
    padding: 1vh 0;
  }

  div {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(107, 55, 107);
    border-radius: 10px;
    cursor: pointer;
    padding: 1vh;
    margin-bottom: 0.3vh;
    @include tr();
    @include noselect();
    p {
      color: rgb(107, 55, 107);
      padding-bottom: 0vh;
      text-align: left;
    }
    span {
      color: gray;
    }
  }
  .create-new-button:hover {
    background-color: $hoverPurple;
  }
  .create-new-last-button {
    margin-bottom: 2vh;

    &:hover {
      background-color: $hoverPurple;
    }
  }
}
.orders-from-clients {
  margin-top: 5%;
}

.profile__nav__button {
  align-items: center;
  background-color: $mainBg;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 55px;
  padding: 15px 5px;
  margin-bottom: 1.5px;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 3px;
  }
  @include tr();
  @include noselect();
  &.profile__nav__button--add {
    padding-right: 38px;
  }
  .icons {
    color: #212121;
    font-size: 20px;
    margin-right: 15px;
  }
  .icons-button {
    position: absolute;
    right: 4px;
    top: 50%;
    @include transform(translateY(-50%));
  }
  .icons-img-wrapper {
    width: 23px;
    height: 23px;
    margin-right: 15px;
  }
  .texts {
    color: #171717;
    overflow: hidden;
    p {
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    span {
      font-size: $px12;
      font-weight: 300;
      line-height: 10px;
      opacity: 0.7;
    }
  }
  &:hover:not(.inactive) {
    background-color: $hoverPurple;
  }
  & + p {
    margin-top: 17px;
  }

  &.router-link-active:not(.router-link-inactive) {
    background-color: $mainPurple;
    .texts,
    .icons,
    .icons-button:not(:hover) {
      color: #fff;
    }

    img {
      filter: invert(100%);
    }
  }

  &.inactive {
    opacity: 0.6;
  }
  &.notSeller {
    opacity: 0.6;
    pointer-events: none;
  }
}
@media all and (max-width:900px) {
  .profile__nav__button {
    height: 15px;
    margin-bottom: 0;
    overflow-y: hidden;

    .texts {
      color: #654d91;
      max-width: fit-content;

      p {
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        text-align: center;
        white-space: pre-line;
      }
      span {
        font-size: $px12;
        font-weight: 300;
        line-height: 10px;
        opacity: 0.7;
      }
    }
    &:hover:not(.inactive) {
      background-color: $mainBg;
    }
    & + p {
      margin-top: 17px;
    }

    &.router-link-active:not(.router-link-inactive) {
      background-color: $mainBg;
      p {
        background-color: rgba(36, 4, 96, 0.5);
      }
      .texts,
      .icons,
      .icons-button:not(:hover) {
        color: #fff;
      }
      .icons-img-wrapper {
        img {
          filter: invert(100%);
        }
      }
    }

    &.inactive {
      opacity: 0.6;
    }

    &.profile__nav__button--add {
      padding-right: 0px;
    }
  }
  .create-new {
    background: rgb(252, 251, 255);
    border-radius: 15px;
    box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.8);
    bottom: 160px;
    height: fit-content;
    position: fixed;
    padding: 0 3vh;
    width: fit-content;
    right: 10px;

    p {
      font-weight: 900;
      text-align: center;
      padding: 1vh 0;
    }

    div {
      background: rgb(255, 255, 255);
      border: 1px solid rgb(107, 55, 107);
      border-radius: 10px;
      cursor: pointer;
      padding: 1vh;
      margin-bottom: 0.3vh;
      @include tr();
      @include noselect();
      p {
        color: rgb(107, 55, 107);
        padding-bottom: 0vh;
        text-align: left;
      }
      span {
        color: gray;
      }
    }
  }
}
</style>
