<template>
  <section class="container cart">
    <div class="items-wrapper">
      <div class="title">Количка за пазаруване</div>
      <div v-if="!showButton">
        Вашата количка е празна. Вижте какво сте запазили в
        <button class="favourite-link" @click="redirectFromCartTo">Любими</button>
      </div>
      <div v-else>
        <div class="comment" style="padding-top: 30px">
          Във Вашата количка има продукти, предлагани от <span>{{ getBrandNames() }}.</span> Поръчката ще бъде изпратена
          към тях и ще бъде доставена в отделни пратки.
        </div>
        <div class="seller-info" v-for="(brand, i) in (isAuthenticated ? cart.cartItems : cart.cartItemsFromSession)"
             :key="`cartItem--${i}`">
          <div class="border">Продукти с доставка от: {{ brand.products[0].product.seller.company.company }}</div>
          <div class="cart-item-wrapper" v-for="(product, i) in brand.products" :key="`product--${i}`">
            <div class="item">
              <div class="product-info-wrapper">
                <img
                  :src="`${uploads}/users/${product.product.seller._id}/products/200x200-conv-pn-${product.product.mainPhoto}`"
                  alt="Product cover"
                />
                <div class="text-wrapper">
                  <div class="text-row product-name">{{ product.product.name }}</div>
                </div>
              </div>

              <div class="price-and-quantity">
                <Price :value="product.finalPrice" discounted="1"/>
                <div class="quantity">
                  <!--              <button @click="changeQuantity(i, false)"-->
                  <button @click="changeQuantity(product._id, product.product._id, product.quantity, false)"
                          :disabled="product.quantity === 1 || product.quantity === product.product.minQuantityPerOrder || isLoading">
                    -
                  </button>
                  <span class="quantity-value">{{ product.quantity }}</span>
                  <!--              <button @click="changeQuantity(i,true)"-->
                  <button @click="changeQuantity(product._id, product.product._id,product.quantity,true)"
                          :disabled="product.quantity === product.product.maxQuantityPerOrder || isLoading">+
                  </button>
                  <button @click="deleteItem(product._id)" :disabled="isLoading"><img
                    :src="`${uploads}/assets/img/trash.png`" alt="trash"/></button>
                </div>
              </div>
            </div>
           <div class="comments" v-if="product.product.type !== 'microwallet'">
              <div
                v-show="
                product.product.freeDeliveryOver > 0
                && product.product.freeDeliveryOver > product.quantity && showDeliveryFreeForBrand(brand).showFreeDeliveryByProduct"
                class="comment flex">
                <img :src="`${uploads}/assets/img/common/freeDelivery.svg`" alt="freeDelivery"/>
                <span> Безплатна доставка над {{ product.product.freeDeliveryOver }} {{ product.product.unit }} за текущия продукт.</span>
              </div>

              <div
                v-show="( product.deliveryPrice === 0
                  || (product.deliveryPrice === 0 && product.product.freeDeliveryOver <= product.quantity)
                  || !showDeliveryFreeForBrand(brand).showFreeDeliveryByProduct) && !product.product.clientTakesTheProductHimself && !product.product.priceIsCalculatedByCourier"
                class="comment flex">
                <img :src="`${uploads}/assets/img/common/freeDelivery.svg`" alt="freeDelivery"/>
                <span style="color: #1db954"> Безплатна доставка.</span>
              </div>
              <div
                v-show="product.product.priceIsCalculatedByCourier  && !(( product.deliveryPrice === 0 && !product.product.priceIsCalculatedByCourier)
                  || (product.deliveryPrice === 0 && product.product.freeDeliveryOver <= product.quantity)
                  || !showDeliveryFreeForBrand(brand).show)"
                class="comment flex">
                <img :src="`${uploads}/assets/img/common/important.svg`" alt="important"/>
                <span> Цената за доставката се начислява според тарифата на куриерската фирма и не е включена в цената на продукта. </span>
              </div>
              <div
                v-show="product.product.clientTakesTheProductHimself"
                class="comment flex">
                <img :src="`${uploads}/assets/img/common/office.svg`" alt="office"/>
                <span> Продуктът може да се вземе от Обекти: {{
                    getObjectsByBrand(product.product.deliverToAllLocalStores, brand, product.product.cityRows || []).join(', ')
                  }} </span>
              </div>
              <div
                v-show="product.product.cities.length > 1"
                class="comment flex">
                <img :src="`${uploads}/assets/img/common/delivery.svg`" alt="delivery"/>
                <span> Доставка се извършва до следните градове:
                  <span>{{ product.product.cities.join(', ') }}. </span>
              </span>
              </div>
            </div>
          </div>
          <div class="brand-costs" v-if="showDeliveryTotal">
            <div class="brand-info">
              <div class="comment flex double-border"
                   v-show="showDeliveryFreeForBrand(brand).show">
                <img :src="`${uploads}/assets/img/common/important.svg`" alt="important"/>
                <span>{{ showDeliveryFreeForBrand(brand).message }}</span>
              </div>
            </div>
            <div class="brand-total ">
              <div class="comment">Продукти общо:
                <span>{{ brand.sumOfProducts.toFixed(2) }}лв.</span>
              </div>
              <div class="comment" v-if="showDeliveryTotal">Доставка общо:
                <span v-html="getDeliveryTotal(brand)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CartSummary :redirectTo="'/cart/checkout'" :shouldValidate="false" :showButton="showButton" :status="status"/>
  </section>
</template>

<script>
import updateRemaining from '@/mixins/updateRemaining.js';
import loaderMixin from '@/mixins/loader';

import 'lazysizes';

import {mapGetters} from 'vuex';
import Price from "@/components/Price.vue";
import CartSummary from "@/components/CartSummary.vue";
import {PROCESSED} from "@/assets/enumerates/statusesEnumerate";
import {redirectToBy} from "@/common/commonService";

export default {
  components: {CartSummary, Price},
  mixins: [updateRemaining, loaderMixin],
  computed: {
    ...mapGetters(['cart', 'isLoading', 'isAuthenticated', 'currentUser', 'favouriteProducts']),
    status(){
      return  PROCESSED
    },
    uploads() {
      return `${process.env.VUE_APP_UPLOADS_URI}`;
    },
    showButton(){
      if(this.cart.cartTotal == 0 || this.cart.cartQuantity === 0){
        return false
      }
      return true
    },
    showDeliveryTotal(){
      let show = false

      Object.values(this.cart.cartItems).map(brand => {
        brand.products.map(product=> {
          if(product.product.type !== 'microwallet'){
            show = true
          }
        })
      })

      return show
    }
  },
  data() {
    return {
      haveFreeDeliveryMessage: false,
      isFreeDeliverBySellerProperties: false
    };
  },
  methods: {
    showDeliveryFreeForBrand(brand){
      const currentNumberOfItems = brand.numberOfProducts
      const currentSumOfProducts = brand.sumOfProducts
      const freeDeliveryForSum = brand.products[0].product.seller.company.freeDeliveryForSum || 0
      const freeDeliveryForNumberOfItems = brand.products[0].product.seller.company.freeDeliveryForNumberOfItems || 0
      let data = brand.products[0].product.seller.company

      if (freeDeliveryForSum > 0 && freeDeliveryForSum > currentSumOfProducts && freeDeliveryForNumberOfItems > 0 && freeDeliveryForNumberOfItems > currentNumberOfItems) {

        return ({
          show: true,
          showFreeDeliveryByProduct: true,
          message: `Поръчайте
          ${data.freeDeliveryForNumberOfItems} броя продукти от ${data.company}  или
          продукти над стойност ${data.freeDeliveryForSum.toFixed(2)}лв.
          и ще получите безплатна доставка.`
        })
      } else {
        if (freeDeliveryForSum > currentSumOfProducts) {
          return ({
            show: !(freeDeliveryForNumberOfItems > 0 && freeDeliveryForNumberOfItems <= currentNumberOfItems),
            showFreeDeliveryByProduct: !(freeDeliveryForNumberOfItems > 0 && freeDeliveryForNumberOfItems <= currentNumberOfItems),
            message: `Поръчайте продукти от ${data.company} над стойност ${data.freeDeliveryForSum.toFixed(2)}лв. и ще получите безплатна доставка.`
          })
        } else if (freeDeliveryForNumberOfItems > currentNumberOfItems) {
          return ({
            show: !(freeDeliveryForSum > 0 && freeDeliveryForSum <= currentSumOfProducts),
            showFreeDeliveryByProduct: !(freeDeliveryForSum > 0 && freeDeliveryForSum <= currentSumOfProducts),
            message: `Поръчайте ${data.freeDeliveryForNumberOfItems} броя продукти от ${data.company} и ще получите безплатна доставка.`
          })
        }
      }
    if (freeDeliveryForSum < 0 || freeDeliveryForNumberOfItems < 0) {
      return {show: false, showFreeDeliveryByProduct: true}
    }

      return {show: false, showFreeDeliveryByProduct: false}
    },
    redirectFromCartTo(){
      return redirectToBy(this.favouriteProducts.length === 0, '/', `${this.currentUser.role + '/favourites'}`)
    },
    changeQuantity(cartItemId, productId, quantity, isIncrease) {
      this.$store.dispatch('changeQuantity', {cartItemId, productId, quantity, isIncrease})
    },
    deleteItem(id) {
      this.$store.dispatch('deleteItem', id).then(res => {
        this.$snotify.success(res.data.message)
      })
    },
    getBrandNames() {
      const brands = []

      Object.values(this.isAuthenticated ? this.cart.cartItems : this.cart.cartItemsFromSession).map(brand =>{
        brands.push(brand.products[0].product.seller.company.company)
      })

      return brands.join(', ')
    },
    getDeliveryTotal(brand){
      let productsWithPriceIsCalculatedByCourier = []
      let productsWithPickUpOptions = []
      let mess = '';
      brand.products.forEach(data=>{
        if(data.product.priceIsCalculatedByCourier){
          productsWithPriceIsCalculatedByCourier.push(data.product.name)
        }
        if(data.product.clientTakesTheProductHimself && data.product.type !== 'microwallet'){
          productsWithPickUpOptions.push(data.product.name)
        }
      })

      if(brand.products.length === productsWithPriceIsCalculatedByCourier.length){
        const currentNumberOfItems = brand.numberOfProducts
        const currentSumOfProducts = brand.sumOfProducts
        const freeDeliveryForSum = brand.products[0].product.seller.company.freeDeliveryForSum || 0
        const freeDeliveryForNumberOfItems = brand.products[0].product.seller.company.freeDeliveryForNumberOfItems || 0

        if((freeDeliveryForSum > 0 && freeDeliveryForSum <= currentSumOfProducts) ||  (freeDeliveryForNumberOfItems > 0 && freeDeliveryForNumberOfItems <= currentNumberOfItems)){
          mess = "<span style='color: #1db954'>Безплатно</span>"
        }
        else{
          mess = 'Цената зависи от куриер'
        }
      }else if(productsWithPriceIsCalculatedByCourier.length > 0 && brand.products.length > productsWithPriceIsCalculatedByCourier.length){
        mess = `${brand.deliveryTotal > 0 ? brand.deliveryTotal.toFixed(2) + 'лв.' : "<span style='color: #1db954'>Безплатно. </span>"} Доставката за продукти: <span style='color: #333131; text-decoration: underline'>${productsWithPriceIsCalculatedByCourier.join(', ')}</span> зависи от куриер.`
      }else if(productsWithPickUpOptions.length > 0){
        mess = `${brand.deliveryTotal > 0 ? brand.deliveryTotal.toFixed(2) + 'лв.' : "<span style='color: #1db954'>Няма. </span>"} Цената за доставка зависи от Вашия избор за продукти: <span style='color: #333131; text-decoration: underline'>${productsWithPickUpOptions.join(', ')}</span> `
      }else if(productsWithPriceIsCalculatedByCourier.length === 0 && brand.deliveryTotal > 0){
        mess = `${brand.deliveryTotal.toFixed(2)}лв.`
      }else{
        mess = "<span style='color: #1db954'>Безплатно</span>"
      }
      return mess
    },

    getObjectsByBrand(deliverToAllLocalStores, data, additionalObjects){
      const obj = []
      if(deliverToAllLocalStores){
        data.objects.map(a => {
          obj.push(`"${a.address}"`)
        })
      }

      additionalObjects.map(a=>{
        obj.push(`"${a.city}, ${a.address}"`)
      })
      return obj
    },
  },
  mounted() {

  },
  destroyed() {
    window.removeEventListener('resize', this.isMobileMode);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base';
.favourite-link{
  border: none;
  text-decoration: underline;
  font-size: 16px;
  color: #51199d;
  background-color: #c329c61a;
  border-radius: 15px;
}
.cart {
  padding: 40px 0;
  width: 1280px;
  display: flex;
}

.cart-wrapper {
  display: flex;
}

.title {
  font-size: 22px;
  color: #666666;
}

.items-wrapper {
  padding: 0;
  display: block;
  //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 70%;
  height: fit-content;
  .seller-info {
    color: #666666;
    font-size: 18px;
    width: 100%;
    padding-left: 20px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .border{
    border-bottom: 1px solid rgba(187, 187, 187, 0.49);
    padding-bottom: 15px;
    width: 90%;
  }
   .cart-item-wrapper{
     margin-bottom: 20px;
     width: 90%;
   }
  .item {
    display: flex;
    width: 100%;
    padding-left: 20px;

    img {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      margin: auto 0;
    }

    .product-info-wrapper {
      font-size: 16px;
      display: flex;
      padding: 15px 0;
      width: 100%;

      .text-wrapper {
        margin: auto 30px;
      }

      .text-row {
        padding: 0;
        display: -webkit-box;
        max-width: 100%;
        //margin: 0 auto;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 10px 0;
      }

      .product-name {
        color: #666666;
      }
    }

    .price-and-quantity {
      margin: auto;
      text-align: center;
      .quantity {
        color: #b50db6;

        .quantity-value{
          line-height: 25px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
        button {
          width: 25px;
          height: 25px;
          border: none;
          color: #b50db6;
          //color: rgba(187, 187, 187, 0.49);
          background-color: transparent;
          cursor: url('../../assets/img/cursor.png'), auto;
          font-size: 20px;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          img {
            width: 15px;
            height: 15px;
          }

          &:disabled{
            cursor: not-allowed;
          }
        }
      }
    }
  }
  .comments{
    color: #bbbbbb;
    font-size: 12px;
    padding-top: 20px;
  }
  .comment{
    color: #666666;
    //font-size: 12px;
    width: 90%;
    text-align: justify;
    padding-top: 5px;
    img{
      width: 20px;
      height: 20px;
      margin: auto 0;
    }
    span{
      margin: auto 0;
    }
  }
  .flex{
    display: flex;
    gap: 10px;
  }
  .brand-costs{
    color: #bbbbbb;
    font-size: 14px;
    .brand-total{
      padding-top: 20px;
    }
  }
  .brand-info{
    color: #666666;
    font-size: 12px;
  }
  .double-border{
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid rgba(187, 187, 187, 0.49);
    border-top: 1px solid rgba(187, 187, 187, 0.49);
  }
}


@media all and (max-width: $m) {
  .cart {
    width: 980px;
  }

  .items-wrapper {
    width: 70%;

    .item {
      .product-info-wrapper {
        font-size: 14px;
      }

      img {
        width: 80px;
        height: 80px;
      }
    }
  }
  .title {
    font-size: 18px;
  }
}

@media all and (max-width: 1050px) {
  .cart {
    width: 880px;
  }
  .title {
    font-size: 20px;
  }
}

@media all and (max-width: $standard) {
  .cart {
    width: 600px;
    display: block;
  }
  .items-wrapper {
    width: 100%;
    .cart-item-wrapper{
      width: 95%;
    }
    .title{
      text-align: center;
    }
    div{
      margin: auto;
    }

    .seller-info{
      padding-left: 0;
    }

    .item {
      width: 100%;
    }
    padding-bottom: 30px;
    border-bottom: 1px solid #b7b3b375;
  }
}

@media all and (max-width: 650px) {

  .cart {
    width: 400px;
    display: block;
  }
  .items-wrapper {
    .item {
      display: block;

      .product-info-wrapper {
        width: 100%;
        padding: 0;
      }

      .price-and-quantity {
        justify-content: end;
        display: grid;

        .quantity {
          justify-content: end;
        }
      }
    }
  }
}

@media all and (max-width: $xs) {

  .cart {
    width: 95%;
  }
  .items-wrapper {
    .item {
      .product-info-wrapper {
        .text-wrapper {
          margin: auto 10px;
        }
      }

      img {
        width: 70px;
        height: 70px;
      }

      .price-and-quantity {
        margin-top: -15px;

        .quantity {
          padding-right: 3px;

          button {
            width: 15px;
            height: 15px;
          }
          .quantity-value{
            line-height: 15px;
          }
        }
      }
    }
  }
  .title {
    padding-bottom: 15px;
  }
}
</style>
