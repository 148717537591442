<template>
  <div class="dropdown-wrapper" v-if="isMobileOpen">
    <div class="incognito-nav">
      <div class="home-dropdown-wrapper">
        <a @click="$emit('close')">
          <span class="icons home-dropdown">6</span>
        </a>
      </div>
      <a href="/" class="nav__logo">
        <img :src="`${uploads}/assets/img/preonow-logo.svg`" alt="Preonow" />
      </a>
      <div class="close-dropdown-wrapper">
        <a @click="$emit('close')" class="icons close-dropdown">W</a>
      </div>
    </div>
    <div v-if="!currentUser.role" class="user-dropdown">
      <div class="mobile-dropdown-links">
        <ul>
          <li @click="$emit('close')">
            <a href="/">
              <span class="icons">5</span>
              <span class="links-text home-text">НАЧАЛО</span>
            </a>
          </li>
          <li @click="$emit('close')">
            <a href="/?login=true">
              <span class="icons">.</span>
              <span class="links-text enter-text">ВХОД</span>
            </a>
          </li>
          <li @click="$emit('close')">
            <a href="/?register=true">
              <span class="icons">.</span>
              <span class="links-text registration-text">РЕГИСТРАЦИЯ</span>
            </a>
          </li>
          <li @click="$emit('close')">
            <a href="/contacts">
              <span class="icons">J</span>
              <span class="links-text what-is-text">КОНТАКТИ И ЧЗВ</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div v-else-if="currentUser.role === 'seller' && !currentUser.employee" class="user-dropdown">
      <div class="mobile-dropdown-links">
        <ul>
          <li @click="$emit('close')">
            <a href="/">
              <span class="icons">5</span>
              <span class="links-text home-text">НАЧАЛО</span>
            </a>
          </li>
          <li class="li-name" @click="$emit('close')">
            <div class="seller-cover">
              <a :href="`/${currentUser.role}/profile`">
                <span class="icons">.</span>
                <span class="links-text home-text">МОЯТ ПРОФИЛ</span>
              </a>
            </div>
          </li>
          <li @click="$emit('close')">
            <a href="/contacts">
              <span class="icons">J</span>
              <span class="links-text what-is-text">КОНТАКТИ И ЧЗВ</span>
            </a>
          </li>


          <li class="li-space" @click="$emit('close')">
            <div class="seller-cover">
              <a :href="`/${currentUser.role}/orders-sellerAsClient?s=sellerAsClientNav`">
                <span class="icons">+</span>
                <span class="text">МОИТЕ ПОРЪЧКИ</span>
              </a>
            </div>
          </li>
          <li @click="$emit('close')">
            <div class="seller-cover">
              <a :href="`/${currentUser.role}/orders?s=sellerNav`">
                <span class="icons">,</span>
                <span class="text">НАПРАВЕНИ ПОРЪЧКИ</span>
              </a>
            </div>
          </li>
          <li @click="$emit('close')" v-if="!currentUser.deactivated">
            <div class="seller-cover">
              <a :href="`/${currentUser.role}/products/product-service?s=sellerProductsNav`">
                <span class="icons">*</span>
                <span class="text">ПРОДУКТИ И УСЛУГИ</span>
              </a>
            </div>
          </li>
          <li @click="$emit('close')">
            <div class="seller-cover">
              <a :href="`/${currentUser.role}/profile/menu?s=profilesAndSettings`">
                <span class="icons">-</span>
                <span class="text">ПРОФИЛИ И НАСТРОЙКИ</span>
              </a>
            </div>
          </li>


          <li class="li-space exit" @click="logout">
            <div class="seller-cover">
              <a href="">
                <span class="text">ИЗХОД</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else-if="currentUser.employee" class="user-dropdown">
      <div class="mobile-dropdown-links">
        <ul>
          <li @click="$emit('close')">
            <router-link to="/employee/payment-request">
              <span class="icons">5</span>
              <span class="links-text home-text">ИЗПРАЩАНЕ НА ЗАЯВКА</span>
            </router-link>
          </li>
          <li class="li-name" @click="$emit('close')">
            <div class="seller-cover">
              <a :href="`/${currentUser.role}/profile/employee/${currentUser._id}`">
                <span class="icons">.</span>
                <span class="links-text home-text">МОЯТ ПРОФИЛ</span>
              </a>
            </div>
          </li>
          <li class="li-name" @click="$emit('close')">
            <div class="seller-cover">
              <router-link to="seller/tips">
                <span class="icons">'</span>
                <span class="links-text home-text">МОИТЕ БАКШИШИ</span>
              </router-link>
            </div>
          </li>
          <li class="li-space exit" @click="logout">
            <div class="seller-cover">
              <a href="">
                <span class="text">ИЗХОД</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else-if="currentUser.role === 'client'" class="user-dropdown">
      <div class="mobile-dropdown-links">
        <ul>
          <li @click="$emit('close')">
            <a href="/">
              <span class="icons">5</span>
              <span class="links-text home-text">НАЧАЛО</span>
            </a>
          </li>


          <li @click="$emit('close')">
            <router-link :to="`/${currentUser.role}/profile`">
              <span class="icons mail-box-dropdown">.</span>
              <span class="links-text name">МОЯТ ПРОФИЛ</span>
            </router-link>
          </li>
          <li @click="$emit('close')">
            <a href="/contacts">
              <span class="icons">J</span>
              <span class="links-text what-is-text">КОНТАКТИ И ЧЗВ</span>
            </a>
          </li>
          <li class="li-space" @click="$emit('close')">
           <a :href="`/${currentUser.role}/orders?s=clientNav`">
              <span class="icons mail-box-dropdown">+</span>
              <span class="links-text orders">МОИТЕ ПОРЪЧКИ</span>
           </a>
          </li>
          <li @click="$emit('close')">
            <a :href="`/${currentUser.role}/profile/menu?s=profilesAndSettings`">
              <span class="icons mail-box-dropdown">-</span>
              <span class="links-text orders">ПРОФИЛ И НАСТРОЙКИ</span>
            </a>
          </li>


          <li class="li-space exit" @click="logout">
            <div class="seller-cover">
              <a href="">
                <span class="text">ИЗХОД</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else-if="currentUser.role === 'admin'" class="user-dropdown">
      <div class="mobile-dropdown-links">
        <ul>
          <li @click="$emit('close')">
            <a href="/">
              <span class="icons">5</span>
              <span class="links-text home-text">НАЧАЛО</span>
            </a>
          </li>
          <li @click="$emit('close')">
            <a :href="`/${currentUser.role}/profile`">
              <span class="icons mail-box-dropdown">.</span>
              <span class="name">МОЯТ ПРОФИЛ</span>
            </a>
          </li>

          <li @click="$emit('close')">
            <a href="/contacts">
              <span class="icons">J</span>
              <span class="links-text what-is-text">КОНТАКТИ И ЧЗВ</span>
            </a>
          </li>
          <li class="li-space" @click="$emit('close')">
            <a :href="`/${currentUser.role}/orders?s=adminPanel`">
              <span class="icons mail-box-dropdown">+</span>
              <span class="links-text orders">ПОРЪЧКИ И ОПЕРАЦИИ</span>
            </a>
          </li>
          <li @click="$emit('close')">
            <a :href="`/${currentUser.role}/products/product-service?s=database-products`">
              <span class="icons mail-box-dropdown">*</span>
              <span class="links-text orders">БАЗА ДАННИ ПРОДУКТИ</span>
            </a>
          </li>
          <li @click="$emit('close')">
            <a :href="`/${currentUser.role}/sellers?s=database-users`">
              <span class="icons mail-box-dropdown">*</span>
              <span class="links-text orders">БАЗА ДАННИ ПОТРЕБИТЕЛИ</span>
            </a>
          </li>
          <li @click="$emit('close')">
            <a :href="`/${currentUser.role}/profile/menu?s=profilesAndSettings`">
              <span class="icons mail-box-dropdown">-</span>
              <span class="links-text orders">ПРОФИЛИ И НАСТРОЙКИ</span>
            </a>
          </li>


          <li class="li-space exit" @click="logout">
            <div class="seller-cover">
              <a href="">
                <span class="text">ИЗХОД</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Footer
      :isMobileOpen="isMobileOpen"
      :employee="employee"
    />
  </div>
</template>

<script>
import Footer from "./Footer";
export default {
  components: {
    Footer,
  },
  props: ["currentUser", "isMobileOpen", "uploads"],
  data() {
    return {
      orders: [],
      employee: this.currentUser.employee ? true : false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => this.$router.push("/"));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.dropdown-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  background-color: #240460;
  z-index: 100;
  color: $mainBg;
  margin-bottom: 5%;
  overflow-y: scroll;
  @include noselect();

  .incognito-nav {
    flex-basis: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    width: 100%;
  }

  .user-dropdown {
    flex-basis: 70%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    .mobile-dropdown-links {
      width: 100%;
    }
  }

  .div-footer {
    flex-basis: 23%;
    .footer--mobile {
      margin-top: 1% !important;
    }
  }

  .home-dropdown-wrapper,
  .close-dropdown-wrapper {
    flex-basis: 0;
    flex-grow: 1;
  }

  .close-dropdown-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .home-dropdown {
    font-size: 22px;
    @include hover();
  }

  .nav__logo {
    img {
      display: block;
      height: 39px;
    }
  }

  .close-dropdown {
    font-size: 28px;
    @include hover();
  }
}

ul {
  padding: 0;
}
li {
  display: flex;
  align-items: center;
  a {
    padding: 12px;
    .icons {
      font-size: 22px;
      margin-right: 1rem;
    }
  }
}

.li-space {
  margin-top: 5%;
}

.li-space {
   &.exit {
     display: flex;
     justify-content: center;

     span:last-child {
       padding: 5px;
       flex-basis: 100%;
       display: flex;
       justify-content: center;
     }
   }
}

li a,
.mobile-dropdown-links ul li .name,
.orders {
  font-size: 14px;
  font-weight: 500;
  color: $mainBg;
}

header {
  display: none;
}

.seller-cover {
  font-size: 10px;
  width: 100%;
}

.seller-cover a {
  width: 100%;
}

.seller-cover a img {
  border-radius: 50%;
  border: solid 2px #f6f7fb;
  height: 24px;
  margin-right: 1rem;
  width: 24px;
}

.mobile-dropdown-links {
  margin-top: 3%;
}

.mobile-dropdown-links ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mobile-dropdown-links ul li a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - #{$sides--sm});
  margin: auto;
  background-color: rgba(246, 247, 251, 10%);
  margin-bottom: 10px;
  border-radius: 12px;

  span:first-child {
    flex-basis: 14%;
    color: #F6F7FB;
  }

  span:last-child {
    flex-basis: 70%;
    display: flex;
    justify-content: center;
    color: #F6F7FB;
  }
}

@media screen and (max-width: 500px) {
  .mobile-dropdown-links ul li a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    margin: auto;
    background-color: rgba(246, 247, 251, 10%);
    margin-bottom: 10px;
    border-radius: 12px;

    span:first-child {
      font-size: 18px;
    }

    span:last-child {
      font-size: 12px;
    }
  }
}
</style>
