import {ApiService} from "@/common/api.service";

const state = {
  isMenuOpen: false,
  isMobile: false,
  favouriteItems: 0,
}

const getters = {
  isMenuOpen: state => state.isMenuOpen,
  isMobile: state => state.isMobile,
  favouriteItems: state => state.favouriteItems,
}

const actions = {
  handleMenu(context) {
    context.commit('handleMenu');
    document.querySelector('body').style.overflow = state.isMenuOpen ? 'hidden' : 'visible';
  },
  setIsMobile(context) {
    context.commit('setIsMobile');
    document.querySelector('body').style.overflow = !state.isMobile && 'visible';
  },
  async setFavouriteItems(context, data) {
    if (data.initial) {
      await ApiService.get(`/favourite-products`).then((res) => {
        data.count = res.data.length
      });
    } else if (data.reset) {
      data.count = 0
    }

    context.commit('setFavouriteItems', data);
  },
}

const mutations = {
  handleMenu(state) {
    state.isMenuOpen = !state.isMenuOpen;
  },
  setIsMobile(state) {
    state.isMobile = window.innerWidth <= 900;
  },
  setFavouriteItems(state, data) {
    if(data.initial || data.reset){
      state.favouriteItems = data.count
    }else{
      state.favouriteItems = data.action === 'remove' ? state.favouriteItems - 1 : state.favouriteItems + 1
    }
  },
}

export default {
  state,
  actions,
  mutations,
  getters
};
