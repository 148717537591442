<template>
  <div
    class="confirm-preo-payment"
    :class="{ 'confirm-preo-payment--success': success }"
    v-if="
      isAuthenticated &&
      !(currentUser.role === 'seller' && currentUser.employee) &&
      preoPayment._id
    "
  >
    <Header mobileOnDesktop="true" light="true" />
    <div
      class="preo-payment-request__content"
      v-if="success"
      key="content-finished-paid"
    >
      <div class="preo">
        <img
          src="@/assets/img/preo-payment/success.svg"
          class="svg-success"
          alt="Successful"
        />
      </div>
      <div class="request center">
        <h1>Успешно плащане!</h1>
        <p>Благодарим ви че пазарувахте с Прио :)</p>
        <br />
        <p>
          Моля, оценете търговеца. Така ще му помогнете да подобри обслужването
          си
        </p>
        <Rate v-model="preoPayment.rate" />
        <button @click="rate">Потвърди</button>
      </div>
    </div>
    <div class="preo-payment-request__content" key="c2" v-else>
      <transition name="fade" mode="out-in">
        <div class="request center" key="r1">
          <h1>Нова заявка за ползване на Прио Точки</h1>
          <p>
            Времето за потвърждение е 24 часа.<br />Моля, потвърдете използваната
            сума при
          </p>
          <div class="seller">
            <div class="seller-cover">
              <img
                :src="`${uploads}/users/${preoPayment.seller._id}/640x640-conv-pn-${preoPayment.seller.cover}`"
                alt="Seller logo"
                v-if="preoPayment.seller.cover"
              />
              <img v-else src="@/assets/img/home/no-cover.webp" alt="No cover" />
            </div>
            <div class="seller-texts">
              <h3>{{ preoPayment.seller.name }}</h3>
              <p>Служител: {{ preoPayment.createdBy.name }}</p>
            </div>
          </div>
          <span class="timer timer--no-margins">{{
            timer || 'зареждане...'
          }}</span>
          <div class="preo-payment-bill">
            <span>Сметка</span>
            <span>{{ (Number(preo) + Number(delivery)).toFixed(2) }} лв.</span>
          </div>
          <div class="preo-payment-row preo-payment-row--total">
            <span>Прио точки</span>
            <span>{{ preo.toFixed(2) }} preo</span>
          </div>
          <div class="preo-payment-row preo-payment-row--total">
            <span>Доставка</span>
            <span>{{
              delivery ? `${delivery.toFixed(2)} preo` : 'безплатна'
            }}</span>
          </div>
          <div class="preo-payment-row" v-if="mw">
            <span>Текущ баланс</span>
            <span>{{ mw.preoLeft.toFixed(2) }} preo</span>
          </div>
          <div class="preo-payment-row" v-if="mwAdditional">
            <span>Текущ баланс на допълнителен портфейл</span>
            <span>{{ mwAdditional.preoLeft.toFixed(2) }} preo</span>
          </div>
          <template v-if="!mw">
            <p>Избери Preo ваучер:</p>
            <div class="preocards-select-wrapper">
              <PreoCard
                v-for="mw in microwallets"
                :key="mw._id"
                clickable="true"
                @click="selectMicrowallet(mw)"
                :active="preoPayment.microwallet === mw._id"
                :preo="mw.preoLeft"
                :company="mw.seller.company.company"
                :preoVoucherId="mw.voucherId"
              />
              <p class="center" v-if="!microwallets.length">
                Няма налични ваучери
              </p>
            </div>
          </template>
          <button
            v-else-if="microwallets.length > 1"
            class="mini-button"
            @click="preoPayment.microwallet = null"
          >
            <p>Избери друг Preo ваучер</p>
          </button>
          <div class="preo-payment-row" v-if="mw" @click="tipModal = true">
            <button
              class="mini-button"
              :class="{ 'mini-button--shake': shake }"
            >
              <p v-if="!tip">+Добави Бакшиш</p>
              <p v-else>Промени бакшиш</p>
            </button>
            <span class="span--tip">
              <span class="icons" v-if="tip" @click.stop="tip = 0">W</span>
              {{ Number(this.tip || 0).toFixed(2) }} preo</span
            >
          </div>

          <template v-if="mw && total > mw.preoLeft">
            <div v-if="!mwAdditional">
              <p>Избери допълнителен Preo ваучер:</p>
              <div class="preocards-select-wrapper">
                <PreoCard
                  v-for="mw in additionalMicrowallets"
                  :key="mw._id"
                  clickable="true"
                  @click="selectAdditionalMW(mw)"
                  :active="preoPayment.microwallet === mw._id"
                  :preo="mw.preoLeft"
                  :company="mw.seller.company.company"
                  :preoVoucherId="mw.voucherId"
                />
                <p class="center"
                   style="color: red; font-size: 16px; font-weight: 500;background-color: #666666; line-height: 30px; border-radius: 15px; padding: 10px 0; opacity: 0.8"
                   v-if="!additionalMicrowallets.length">
                  Няма налични допълнителени ваучери
                </p>
              </div>
            </div>
          </template>
          <div class="preo-payment-row preo-payment-row--total">
            <span>Общо</span>
            <span>{{ total.toFixed(2) }} preo</span>
          </div>
          <div class="preo-payment-row" v-if="mw && !mwAdditional">
            <span>Баланс след плащане</span>
            <span
            >{{ balanceAfterPay }}preo</span
            >
          </div>
          <div class="preo-payment-row" v-if="mwAdditional && mw">
            <span>Баланс след плащане допълнителен портфейл</span>
            <span>{{ balanceAfterPay }} preo</span>
          </div>
          <div class="preo-payment-row" v-if="mw">
            <span>Сума за доплащане</span>
            <span>{{ surchargeАmount }} лв.</span>
          </div>
          <button :class="!mw || buttonDisabled || computedButtonDisabled ? 'disabled' : ''" @click="save()" :disabled="!mw || buttonDisabled || computedButtonDisabled">Потвърди</button>
          <button @click="decline()">Откажи</button>
        </div>
      </transition>
    </div>
    <Tip
      v-if="tipModal"
      :preoPayment="preoPayment"
      :preo="preo + this.delivery"
      :ballance="mw.preoLeft"
      :additionalMwBallance="mwAdditional"
      @close="closeTipModal"
    />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Header from "@/components/Header";
import PreoCard from "@/components/PreoCard";
import Rate from "./Rate";
import Tip from "./Tip.vue";
import button from "../../../components/Button.vue";

export default {
  components: {
    Header,
    PreoCard,
    Rate,
    Tip,
  },
  props: ["id"],
  data() {
    return {
      tip: 0,
      shake: false,
      unsuccessful: false,
      success: false,
      preoPayment: {},
      microwallets: [],
      additionalMicrowallets: [],
      interval: null,
      now: null,
      timer: "",
      ws: null,
      wsc: null,
      tipModal: false,
      buttonDisabled: false,
      buttonPressed: false
    };
  },
  computed: {
    button() {
      return button
    },
    ...mapGetters(["isAuthenticated", "currentUser"]),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
    balanceAfterPay() {
      let balance = 0
      if (this.mwAdditional) {
        balance = this.mwAdditional.preoLeft + this.mw.preoLeft - this.total
      } else {
        balance = this.mw.preoLeft - this.total
      }
      return 0 > balance ? 0.00 : balance.toFixed(2)
    },
    surchargeАmount() {
      let amount = 0
      if (this.mwAdditional) {
        amount = this.mwAdditional.preoLeft + this.mw.preoLeft - this.total
      } else {
        amount = this.mw.preoLeft - this.total
      }
      return 0 < amount ? 0.00 : Math.abs(amount).toFixed(2)
    },
    preo() {
      return this.preoPayment.preo;
    },
    delivery() {
      return this.preoPayment.delivery;
    },
    total() {
      const p = this.preoPayment.preo || 0;
      const t = this.tip || 0;
      const d = this.preoPayment.delivery || 0;
      return Number(p + t + d);
    },
    mw() {
      return this.microwallets.find((m) => {
        return m._id === this.preoPayment.microwallet;
      });
    },
    mwAdditional() {
      return this.additionalMicrowallets.find((m) => {
        return m._id === this.preoPayment.additionalMicrowallets;
      });
    },
    remaining() {
      let r = 0;
      if (this.mw) {
        r = this.mw.preoLeft - this.total;
      }
      return Math.round((r < 0 ? r * -1 : 0) * 100) / 100;
    },
    address() {
      return this.currentUser.addresses || [];
    },
    computedButtonDisabled(){
      if(this.remaining > 0 && this.additionalMicrowallets.length === 0){
        return true
      }

      return false
    }
  },
  watch: {
    isAuthenticated: {
      handler: "getPreoPaymentIfAuthenticated",
      immediate: true,
    },
  },
  methods: {
    closeTipModal(update) {
      this.tipModal = false;
      if (update) {
        this.tip = update;
      }
    },
    reset() {
      if (this.interval) clearInterval(this.interval);
      this.preoPayment = {};
      this.microwallets = [];
      this.success = false;
    },
    updateTimer() {
      if (this.preoPayment.deadline) {
        const d = new Date(this.preoPayment.deadline) ;
        const r = (d - this.now) /1000;

        if (r <= 0) {
          this.timer = "";
          this.reset();
          return;
        }
        this.getPreoPeymnetList()
        let now = new Date().getTime();
        let timeleft = d - now;

        // let days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
        let hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

        return (this.timer = hours + ' часа, ' + minutes + ' минути, ' + seconds + ' секунди');
        // return (this.timer = `0${m}:${("0" + s.toFixed()).slice(-2)}`);
      }
      this.timer = "";
    },
    getMicrowallets() {
      this.loading = true;
      this.preoVouchers = [];

      this.$apiService
        .get(`/preo-vouchers/get-list?active=1&seller=${this.preoPayment.seller._id}&isPreoPayment=true`)
        .then((res) => {
          this.microwallets = res.data;

          if (this.microwallets.length === 1) {
            this.selectMicrowallet(this.microwallets[0]);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false
        })
    },
    getMicrowallets2() {
      const url = `/orders/user/client/${this.preoPayment.client._id}/microwallet/${this.preoPayment.seller._id}?active=1`;

      this.$apiService.get(url).then((res) => {
        this.microwallets = res.data;

        if (this.microwallets.length === 1) {
          this.selectMicrowallet(this.microwallets[0]);
        }
      });
    },
    getAdditionalMW(selectedMwId) {
      const url = `/preo-vouchers/get-list?active=1&seller=${this.preoPayment.seller._id}&selectedMwId=${selectedMwId._id}&preoBiggerThan=${this.total - selectedMwId.preoLeft}&isPreoPayment=true`;

      this.$apiService.get(url).then((res) => {
        this.additionalMicrowallets = res.data;

        if (this.additionalMicrowallets.length === 1) {
          this.selectAdditionalMW(this.additionalMicrowallets[0]);
        }else if(this.additionalMicrowallets.length === 0){
          this.$snotify.error('Сумата надвишава прио точките в главния ваучер, но нямате друг, който може да използвате!');
        }
      });
    },
    getAdditionalMW2(selectedMwId) {
      const url = `/orders/user/client/${this.preoPayment.client._id}/microwallet/${this.preoPayment.seller._id}?active=1&selectedMwId=${selectedMwId._id}&preoBiggerThan=${this.total - selectedMwId.preoLeft}`;

      this.$apiService.get(url).then((res) => {
        this.additionalMicrowallets = res.data;
        if (this.additionalMicrowallets.length === 1) {
          this.selectAdditionalMW(this.additionalMicrowallets[0]);
        }
      });
    },
    selectMicrowallet(mw) {
      this.$set(this.preoPayment, "additionalMicrowallets", '');
      if (this.total > mw.preoLeft) {
        this.getAdditionalMW(mw)
      }
      this.$set(this.preoPayment, "microwallet", mw._id);
      if (mw.preoLeft < this.total) {
        this.preoPayment.paid = mw.preoLeft;
      } else {
        this.preoPayment.paid = this.total ;
      }
    },
    selectAdditionalMW(mw) {
      this.$set(this.preoPayment, "additionalMicrowallets", mw._id);
    },
    getPreoPaymentIfAuthenticated() {
      if (
        this.isAuthenticated &&
        !(this.currentUser.role === "seller" && this.currentUser.employee)
      ) {
        this.getPreoPayment(true);
      }
    },
    connectAndListenForConfirmation() {
      if (this.wsc) this.wsc.close();

      const protocol = location.protocol === "https:" ? "wss" : "ws";

      this.wsc = new WebSocket(`${protocol}://${window.location.host}/ws/`
      );

      this.wsc.onmessage = (message) => {
        const msg = JSON.parse(message.data);
        if (
          msg === `pp-confirmation-${this.preoPayment._id}` &&
          !this.success
        ) {
          this.getPreoPayment();
        }
      };
    },
    getPreoPeymnetList(){
      if(!this.buttonPressed){
        this.$apiService
          .get(`/preo-payments/active`).then(res => {
          if(res.data){
            this.preoPayment.deadline = res.data.deadline
          }else{
            this.preoPayment.deadline = this.now
          }
        })
      }
    },
    getPreoPayment(isInitial = false) {
      if (this.wsc) this.wsc.close();
      this.reset();
      this.tip = 0;
      this.$apiService
        .get(`/preo-payments/active`)
        .then((res) => {
          this.preoPayment = res.data || {};

          if (this.preoPayment._id) {
            this.getMicrowallets();

            this.now = new Date();
            if (this.now < new Date(this.preoPayment.deadline)) {
              this.interval = setInterval(() => {
                this.now = new Date();
                this.updateTimer();
              }, 1000);

              this.connectAndListenForConfirmation();
            } else {
              this.$set(this.preoPayment, "finished", true);
            }
          }else if(isInitial && !this.preoPayment._id){
            this.$router.push(`/client/preo-vouchers`);
          }
        })
        .catch((err) => console.log(err));
    },
    isValid() {
      if (!this.preoPayment.additionalMicrowallets) {

        if (this.preoPayment.paid <= 0) {
          this.$snotify.error("Цената трябва да е по-голяма от 0");
          return false;
        }

        if (this.preoPayment.paid > this.mw.preoLeft) {
          this.$snotify.error("Недостатъчна наличност в избрания Preo ваучер");
          return false;
        }

        if (this.tip > 0 && this.total > this.mw.preoLeft) {
          this.shake = true;
          this.$snotify.error(
            "Не може да добавяте бакшиш поради недостатъчна наличност в текущия портфейл!"
          );
          return false;
        }

        if (this.tip < 0) {
          this.shake = true;
          this.$snotify.error("Бакшишът не може да бъде отрицателна стойност!");
          return false;
        }

        // if ((this.mw.preoLeft - this.total) < 0) {
        //     this.shake = true;
        //     this.$snotify.error("Нямате достатъчно ПриоТочки!");
        //     return false;
        // }
      }

      return true;
    },
    decline() {
      this.buttonPressed = true;
      this.$apiService
        .post("/preo-payments/declined?fromClient=true", this.preoPayment)
        .then(() => {
          this.$snotify.success("Успешно отказано");
          this.getPreoPayment();
          this.$router.push('/client/preo-vouchers')
        })
        .catch((err) => {
          console.log(err);
        });
    },
    save() {
      this.buttonDisabled = true
      this.buttonPressed = true;
      if (this.isValid()) {
        this.postSeen(this.preoPayment);

        this.preoPayment.tip = this.tip;
        this.preoPayment.sum = this.preo + this.delivery + this.tip;
        this.preoPayment.remaining = this.remaining || 0;

        if (this.wsc) this.wsc.close();
        this.$apiService
          .post("/preo-payments/confirm", this.preoPayment)
          .then(() => {
            this.$snotify.success("Успешно запазено");
            this.success = true;
          })
          .catch((err) => {
            console.log(err);
          }).finally(() => {
          this.buttonDisabled = false
        });
      }
    },
    rate() {
      this.$apiService
        .post("/preo-payments/rate", this.preoPayment)
        .then(() => {
          this.$snotify.success("Успешно оценено");
          this.getPreoPayment();
          this.$router.push('/client/preo-vouchers')
        })
        .catch((err) => {
          console.log(err);
        });
    },
    postSeen(payment) {
      let url = `/orders/seen`;
      this.$apiService
        .post(url, payment)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    const ws = location.protocol === "https:" ? "wss" : "ws";
    this.ws = new WebSocket(`${ws}://${window.location.host}/ws/`);
    this.ws.onmessage = (message) => {
      if (this.isAuthenticated) {
        const m = JSON.parse(message.data);
        if (m === `pp-request-${this.currentUser._id}` || m.includes('pp-declined')) {
          this.getPreoPaymentIfAuthenticated();
        }
      }
    };
  },
  destroyed() {
    if (this.interval) clearInterval(this.interval);

    if (this.ws) this.ws.close();
    if (this.wsc) this.wsc.close();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
@import '@/scss/preo-payment.scss';

.confirm-preo-payment {
  background-color: #665eff;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  @include tr();

  &.confirm-preo-payment--success {
    background-color: #1db954;
  }

  .seller {
    align-items: center;
    background-color: rgba($color: #fff, $alpha: 0.2);
    border-radius: 12px;
    color: #fff;
    display: flex;
    margin: 30px 0;

    .seller-cover {
      background-color: #e7edef;
      border: 5px solid #e7edef;
      border-radius: 50%;
      display: flex;
      margin: -10px 10px;
      overflow: hidden;

      img {
        border-radius: 12px;
        height: 88px;
        mix-blend-mode: multiply;
        object-fit: contain;
        width: 88px;
      }
    }

    .seller-texts {
      margin-left: 6px;
      text-align: left;
    }

    h3 {
      font-size: 22px;
      font-weight: 900;
    }

    p {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .preo-payment-bill {
    align-items: center;
    background-color: rgba($color: #fff, $alpha: 0.2);
    border-radius: 10px;
    display: flex;
    font-size: $px17;
    height: 50px;
    justify-content: space-between;
    margin: 30px 0;
    padding: 5px 12px;
    width: 100%;
  }

  .preo-payment-row {
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 1rem;

    span {
      color: rgba($color: #fff, $alpha: 0.5);

      &:last-child {
        font-weight: 700;
        text-align: right;
      }
    }

    .input {
      border: none;
    }

    &.preo-payment-row--total {
      font-size: 21px;

      span {
        color: #fff;

        &:last-child {
          font-weight: 900;
        }
      }
    }

    .span--tip {
      align-items: center;
      display: inline-flex;

      .icons {
        color: #fff;
        font-size: 1.25rem;
        margin-right: 0.5rem;
        @include hover();
      }
    }
  }

  .preocards-select-wrapper {
    display: flex;
    margin-bottom: 11px;
    overflow: auto;
    padding: 6px;
    width: calc(100% + 12px);
    margin-left: -6px;
    @include scrollbar(5px, #665eff);

    .preocard {
      &:not(:last-child) {
        margin-right: $px16;
      }
    }

    > p {
      width: 100%;
      opacity: 0.5;
    }
  }

  .rate {
    margin: 1rem 0;
  }
}
</style>
