<template >
  <main :class="{ 'modal-wrapper user--menu': this.inNav }" @click.self="close()">
    <template v-if="isAuthenticated">
      <!-- Админ,клиент,ФЛ -->
      <section v-if="!currentUser.verified" @click="close()">
        <router-link :to="`/${currentUser.role}/profile/menu`" class="name-role"
        >
          <p class="name">
            {{
              currentUser.role === 'seller' && !currentUser.employee
                ? currentUser.company.name
                : currentUser.name
            }}
          </p>
          <span v-if="currentUser.role === 'admin' && !currentUser.employee" class="role"
            >Администратор</span
          >
          <span v-if="currentUser.employee">Служител</span>
          <span v-else-if="currentUser.role === 'seller'">Юридическо лице</span>
          <span v-else-if="currentUser.role === 'client'">Физическо лице</span>
        </router-link>
        <div>
          <img src="../assets/img/header/user.svg" alt="" />

          <div
            @click="isNotificationModalOpen = true"
            class="circle-count"
            v-if="getTotalCount > 0 && currentUser.role !== 'admin'"
          >
            {{ getTotalCount }}
          </div>
          <div
            @click="isNotificationModalOpen = true"
            class="circle-count admin"
            v-else-if="getTotalCountAdmin > 0 && currentUser.role === 'admin'"
          >
            {{ getTotalCountAdmin }}
          </div>
        </div>

        <div class="dropdown--menu">
          <router-link class="router" :to="`/${currentUser.role}/profile/menu`"  :is="currentUser.deactivated ? 'span' : 'router-link'"
            >ПРОФИЛ</router-link
          >
          <router-link
            class="router"
            v-if="currentUser.role === 'admin' || currentUser.role === 'client'"
            :to="`/${currentUser.role}/orders`"
            >ПОРЪЧКИ</router-link
          >
          <router-link
            class="router"
            v-else
            :to="`/${currentUser.role}/orders-sellerAsClient`"
            >ПОРЪЧКИ</router-link
          >
          <router-link
            class="router"
            v-if="currentUser.role !== 'admin'"  :is="currentUser.deactivated ? 'span' : 'router-link'"
            :to="`/${currentUser.role}/favourites`"
            >ЛЮБИМИ</router-link
          >
                    <router-link class="router" v-if="currentUser.role === 'admin'" to="/contacts"
            >КОНТАКТИ И ЧЗВ</router-link
          >
          <p class="router" @click="logout">ИЗХОД</p>
        </div>
      </section>

      <!-- ЮЛ -->
      <section
      @mouseover="openSellerDropdown=true"
      @click="close()"
        class="seller--section"
        v-else-if="currentUser.verified"
      >
      <div class="seller--img--name">

        <router-link
          :to="`/${currentUser.role}/profile/menu`"
          class="seller-cover"
        >
          <img
            v-if="currentUser.cover"
            :src="`${uploads}/users/${currentUser._id}//100x100-conv-pn-${currentUser.cover}`"
            alt="Seller logo"
          />
          <img v-else src="@/assets/img/home/no-cover.webp" alt="No cover" />
          <div
            @click.prevent="isNotificationModalOpen = true"
            class="circle-count"
            v-if="getTotalCount > 0"
          >
            {{ getTotalCount }}
          </div>
        </router-link>

        <router-link :to="`/${currentUser.role}/profile/menu`" class="name-and-role"  :is="currentUser.deactivated ? 'span' : 'router-link'">
          <p class="name">
            {{ currentUser.company.name }}
          </p>
          <span>Търговец</span>
        </router-link>
      </div>


        <p class="icons-button" @click="openSellerDropdown=false">U</p>

        <div class="dropdown--menu dropdown--seller" :class="{ 'dropdown--seller--closed': !this.openSellerDropdown }" v-if="!currentUser.deactivated">
          <router-link class="router" to="/seller/products/product-service/new"  :is="currentUser.deactivated ? 'span' : 'router-link'">
            <img src="../assets/img/header/new-offer.svg" alt="" />
            <div>
              <p>НОВА ОБЯВА</p>
              <span>Създаване на нова обява</span>
            </div>
          </router-link>
          <router-link class="router" to="/seller/products/microwallet/new"  :is="currentUser.deactivated ? 'span' : 'router-link'">
            <img src="../assets/img/header/new-mp.svg" alt="Нов Preo ваучер" />
            <div>
              <p>НОВ PREO ВАУЧЕР</p>
              <span>Създаване на нов Preo ваучер</span>
            </div>
          </router-link>
          <router-link class="router" to="/seller/products/b2b/new"  :is="currentUser.deactivated ? 'span' : 'router-link'">
            <img src="../assets/img/header/new-b2b.svg" alt="" />
            <div>
              <p>НОВА B2B ОБЯВА</p>
              <span>Създаване на нова бизнес обява</span>
            </div>
          </router-link>
          <router-link class="router" to="/seller/objects/new"  :is="currentUser.deactivated ? 'span' : 'router-link'">
            <img src="../assets/img/header/new-object.svg" alt="" />
            <div>
              <p>НОВ ОБЕКТ</p>
              <span>Добавяйте обекти от вашата верига</span>
            </div>
          </router-link>
          <router-link class="router" to="/seller/employees/new"  :is="currentUser.deactivated ? 'span' : 'router-link'">
            <img src="../assets/img/header/new-user.svg" alt="" />
            <div>
              <p>НОВ СЛУЖИТЕЛ</p>
              <span>Служители, обслужващи клиентите</span>
            </div>
          </router-link>
        </div>
      </section>

<!--      <section v-else-if="currentUser.deactivated" @click="close()">deactaaivated</section>-->
      <!-- deactivated user -->
    </template>
    <template v-else>
      <!-- not logged user -->
      <section @click="close()">
        <img  :src="`${uploads}/assets/img/header/login-icon.svg`" alt="" />
        <p class="nav-link">Вход/Регистрация</p>

        <div class="dropdown--menu">
          <div class="router" @click="goTo('/?login=true')">ВХОД</div>
          <div class="router" @click="goTo('/?register=true')">РЕГИСТРАЦИЯ</div>
          <router-link class="router" to="/becomeSeller">СТАНЕТЕ ТЪРГОВЕЦ</router-link>
          <router-link class="router" to="/contacts"
            >КОНТАКТИ И ЧЗВ</router-link
          >
        </div>
      </section>
    </template>
    <MobileNotificationsModal
      v-if="isNotificationModalOpen"
      @close="isNotificationModalOpen = false"
      :notify="notify"
      :currentUser="currentUser"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import MobileNotificationsModal from './MobileNotificationsModal';
export default {
  props: ['getTotalCount', 'getTotalCountAdmin', 'notify', 'inNav'],
  components: { MobileNotificationsModal },
  computed: {
    ...mapGetters(['isAuthenticated', 'currentUser', 'isMobile']),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
  },
  data() {
    return { isNotificationModalOpen: false,openSellerDropdown:true };
  },
  watch: {},
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => this.$router.push('/'));
      this.$emit('close', true);
    },
        close(update) {
      this.$emit("close", update);
      document.querySelector('body').style.overflow = 'visible';
    },
        goTo(r) {
      if (r !== this.$route.path) {
        this.$router.push(r);
      }
    },
  },
    mounted() {
    document.querySelector('body').style.overflow = 'hidden';
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/nav.scss';
section {
  flex-basis: 45%;
  .icons-button {
    font-size: 24px;
  }
  &:hover {
    box-shadow: 0px 51px 95px #00000080;
    background: #ffffff;
    border-radius: 10px 10px 0 0;

    .icons-button {
      font-size: 24px;
      transform: rotate(45deg);
    }

    .dropdown--menu {
      height: 200px;
      visibility: visible;
      opacity: 1;
      .router {
        display: flex;
      }
      &.dropdown--seller {
        height: 340px;
      }
    }
  }
  .seller--img--name{
    display: flex;
    margin-left: -8px;
    width: 90%;
    margin-top: 10px;
    .seller-cover{
      margin-right: 10px;
    }
  }
  &.seller--section {
    width: 100%;
  }
  .seller-cover {
    display: inline-flex;
    font-size: 10px;
    margin-left: 6px;
    img {
      border-radius: 50%;
      box-shadow: 0px 0px 20px #bdc7ca75;
      height: 35px;
      border: 2px solid #d4d4d4;
      object-fit: cover;
      width: 35px;
      position: relative;
    }
  }
  .circle-count {
    align-items: center;
    background-color: #b100b2;
    border-radius: 50%;
    bottom: 0;
    color: white;
    display: flex;
    justify-content: center;
    height: 15px;
    position: absolute;
    letter-spacing: 0.12px;
    width: 15px;
    min-width: fit-content;
    padding: 5px;
    cursor: pointer;
    &.admin {
      right: 0;
    }
  }
  .name {
    text-transform: uppercase;
    width: 80%;
    white-space: nowrap;
    max-width: 100%;
    margin: 0;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .name-role{
    padding: 0 15px;
    white-space: nowrap;
  }
  span {
    opacity: 0.6;
    color: #6e6d7a;
    font-size: 11px;
  }
  .dropdown--menu {
    background: #ffffff;
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 5px;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: calc(100%);
    height: 0px;
    left: 0;
    width: 100%;
    @include transition(0.5s);
    &.dropdown--seller {
      .router {
        max-height: 55px;
        img {
          object-fit: scale-down;
          margin-right: 10px;
          margin-left: -10px;
          width: 27px;
        }
        div {
          margin-left: 2px;
        }
      }
    }
    &.dropdown--seller--closed{
      height: 0 !important;
        padding: 5px;
      .router{
        display: none;
      }
    }
    .router {
      align-items: center;
      background: #f2f2f2 0% 0% no-repeat padding-box;
      border-radius: 12px;
      cursor: pointer;
      display: none;
      flex-basis: 100%;
      padding: 0 15px;
      max-height: 35px;
      &:hover {
        background: #350887;
        color: white;
        span {
          color: white;
        }
        img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}

.modal-wrapper {
  top: -50%;
  height: 150%;

  &.user--menu {
    section {
      background: #ffffff;
      border-radius: 10px 10px 0 0;
      box-shadow: 0px 51px 95px #00000080;
      font-weight: 600;
      max-width: 300px;
      position: relative;
      width: 300px;
      .dropdown--menu {
        height: 200px;
        visibility: visible;
        opacity: 1;
        .router {
          display: flex;
          font-weight: bold;
        }
        &.dropdown--seller {
          height: 340px;
        }
      }
    }
  }
}

@media all and (max-width: $l) {
  section {
    justify-content: flex-start;

    .name {
      font-size: 12px;
    }
    .icons-button{
      font-size: 22px;
    }
  }
}
@media all and (max-width: $m) {
  section {
    .nav-link{
      padding: 0 5px;
    }
  }
}

</style>
