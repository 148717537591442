<template>
  <div
    class="auth modal-wrapper"
    v-if="$route.query.login || $route.query.register"
    @click.self="$router.push({ query: '' }), (userSelected = false)"
  >
    <div class="auth__panel">
      <header>
        <Button v-if="!userSelected"
                @click="hasHistory() ? $router.go(-1) : $router.push('/')"
          class="icons sqr back"
                style="z-index: 1; background-color: transparent; font-size: 30px; cursor: pointer"
        >b
      </Button>
       <span style="width: 100%; display: flex; justify-content: center; margin-left: -80px"> <img src="@/assets/img/preonow-logo.svg" alt="Preonow" /> </span>
      </header>
      <div
        class="auth__panel__done"
        v-if="$route.query.done === 'registration'"
      >
        <p>Благодарим ви за регистрацията</p>
        <p>
          Изпратихме мейл за потвърждение,<br />за да потвърдим вашата
          идентичност.
        </p>
        <p>Моля, проверете вашата поща и кликнете на линка за потвърждение.</p>
      </div>
      <div class="auth__panel__done" v-else-if="$route.query.done === 'reset'">
        <p>Заявката за смяна на парола е изпратена</p>
        <p>Ще получите инструкции на посочения e-mail адрес.</p>
        <p>Моля, проверете вашата поща и кликнете на линка за потвърждение.</p>
      </div>
      <div
        class="auth__panel__done"
        v-else-if="$route.query.done === 'successful-reset'"
      >
        <p>Паролата ви е сменена</p>
        <p>Ще получите новата на посочения e-mail адрес.</p>
        <p>Моля, проверете вашата поща.</p>
        <p>
          <router-link to="/?login=true">Вход в Preonow</router-link>
        </p>
      </div>
      <template v-else>
        <nav v-if="!userSelected">
          <router-link to="/?login=true">ВХОД</router-link>
          <router-link to="/?register=true">РЕГИСТРАЦИЯ</router-link>
        </nav>
        <Login v-if="$route.query.login" />
        <Register v-else-if="$route.query.register" @close="userSelect" />
      </template>
    </div>
  </div>
</template>

<script>
import Login from './Login';
import Register from './Register';
export default {
  components: {
    Login,
    Register,
  },
  data() {
    return {
      userSelected: false,
    };
  },
  watch: {
    '$route.query'() {
      if (this.$route.query.login || this.$route.query.register) {
        document.querySelector('body').style.overflow = 'hidden';
      } else {
        document.querySelector('body').style.overflow = 'visible';
      }
    },
  },
  methods: {
    userSelect(update) {
      this.userSelected = update;
    },
    hasHistory () {
      return window.history.length > 2;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.auth {
  align-items: center;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 350px;
}

.auth__panel {
  background-color: #e5e8ed;
  box-shadow: 0px 0px 90px #00000082;
  border-radius: 10px;
  height: fit-content;
  margin: 10px 0 ;
  overflow: auto;
  width: 600px;
  header {
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    height: 80px;
    justify-content: center;
    padding: 0 25px;
    img {
      display: block;
      height: 54px;
    }
  }
  nav {
    display: flex;
    padding: 30px 100px 0;
    justify-content: center;

    a {
      border-bottom: 1px solid #7e7e7e;
      font-size: $px16;
      display: block;
      padding: 10px 0;
      position: relative;
      text-align: center;
      width: 50%;
      @include transition($transition);
      &::after {
        background-color: $mainAccent;
        bottom: -1px;
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
        @include transform(scaleY(0));
        @include transition($transition);
      }
      &:first-child {
        margin-right: 5px;
      }
      &.router-link-active {
        border-bottom: 1px solid $mainAccent;
        color: $mainAccent;
        font-weight: 700;
        &::after {
          @include transform(scaleY(1));
        }
      }
    }
  }
}

.auth__panel__done {
  padding: 4rem 2rem;
  text-align: center;
  p {
    font-weight: 500;
    &:first-child {
      font-size: 1.25rem;
    }
    &:nth-child(2) {
      margin: 1rem 0;
    }
    &:nth-child(4) {
      color: $mainAccent;
      font-weight: 700;
      margin-top: 1rem;
      a {
        @include hover();
      }
    }
  }
}

@media screen and (max-width: $standard) {
  .auth__panel {
    width: 90%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .auth__panel nav a {
    width: 100%;
  }

  .auth__login {
    padding: 10% 8% 3%;
  }
}
</style>
