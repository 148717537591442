<template>
  <div class="home__what">
    <p class="wide-title">КАКВО Е PREONOW?</p>
    <main>
      <div class="content">
        <div class="texts">
          <h1>Preo<span>Now</span></h1>
            <h1>е платформа за</h1>
            <h1><span>предварителни</span> поръчки</h1>
          <p>
            Preonow Ви помага да планирате покупките си и да спестявате. А Вашите доставчици планират производството си и произвеждат точно за Вас, с Вашите пари. Ето заради това потребителите на Preonow са специални!
          </p>
          <div class="links-documents-wrapper">
            <div class="profile-more-info">              
            </div>
            <div v-if="!isAuthenticated" class="links">
            <router-link class="btn-link" to="/?register=true">РЕГИСТРИРАЙ СЕ СЕГА</router-link>
            <router-link to="/contacts">РАЗБЕРЕТЕ ПОВЕЧЕ ТУК</router-link>
            </div>
            <div v-else class="links">
            <router-link :to="`/${currentUser.role}/profile`" class="btn-link"
              >МОЯТ ПРОФИЛ</router-link>
            </div>
          </div>          
        </div>
        <img src="@/assets/img/home/what.svg" alt="Showcase image" />
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['isAuthenticated', 'currentUser']),
    staticUri() {
      return process.env.VUE_APP_STATIC_URI;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.home__what {
  margin-bottom: -1rem;

  main {
    background-color: #240460;
    color: #fff;
    padding: 3rem;
    position: relative;
    .content {
      max-width: $hdIn;
      margin: 0 auto;
      width: 100%;
      .texts {
        width: 60%;
        h1 {
          font-size: 80px;
          font-weight: 900;
          line-height: 80px;
          span {
            color: $lightAccentL;
          }
        }
        p {
          font-size: $px18;
          line-height: 2rem;
          margin: 2rem 0 3.4rem;
        }
        .links {
          align-items: center;
          display: flex;
          font-size: $px16;
          font-weight: 700;
          .btn-link {
            background-color: $lightAccentL;
            border-radius: 20px;
            color: #fff;
            display: inline-block;
            margin-right: 1.5rem;
            padding: $px12 2.9rem;
            @include hover();
          }
          a:not(.btn-link) {
            color: $lightAccentL;
            text-decoration: underline;
          }
        }
      }
      > img {
        height: 100%;
        left: 60%;
        position: absolute;
        top: 4%;
      }
    }
  }
}

.links-documentation {
  margin-top: 3%;
  color: $mainBg;
  margin-bottom: 1rem;
  a {
    font-weight: 600;
    text-decoration: underline;
    @include hover();
    &:not(:last-child) {
      margin-right: $px12;
    }
  }
}
</style>
