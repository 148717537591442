export default {
  methods: {
    updateRemaining(p, now) {
      if (!p.auctionDateFrom || !p.auctionDateTo) {
        return;
      }

      const f = new Date(p.auctionDateFrom)
      const d = new Date(p.auctionDateTo);

      this.$set(p, 'started', f < now)

      if (d < now) {
        this.$set(p, 'remaining', {
          finished: true,
        });
        return;
      }

      let delta = Math.abs(now - d) / 1000;

      const days = Math.floor(delta / 86400);
      delta -= days * 86400;

      const hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      const minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      this.$set(p, 'remaining', {
        days: days,
        hours: ('0' + hours).slice(-2),
        minutes: ('0' + minutes).slice(-2),
        seconds: ('0' + Math.floor(delta)).slice(-2),
      });
    },
  }
}