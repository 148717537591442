<template>
  <div class="auth-input-wrapper" :class="{'auth-input-wrapper--invalid':invalid}">
    <span class="icons" v-if="icon">{{ icon }}</span>
    <input
      :autocomplete="autocomplete"
      :value="value"
      :type="type || 'text'"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  props: ['value', 'type', 'placeholder', 'icon', 'disabled','autocomplete','invalid'],
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.auth-input-wrapper {
  position: relative;
  width: 100%;

  &.auth-input-wrapper--invalid{
    input{
      border: 1px solid $mainError;
    }
  }
  .icons {
    color: $mainAccent;
    font-size: 20px;
    left: 27.5px;
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%) translateX(-50%));
  }
  input {
    background-color: #fff;
    border-radius: 52px;
    border: 1px solid #C5CDD4;
    color: #000;
    font-size: $px16;
    font-weight: 700;
    height: 46px;
    outline: none;
    padding: 0 20px 0 50px;
    width: 100%;
    @include tr();
    &:disabled {
      color: rgba($color: #000, $alpha: 0.5)
    }
  }
}
</style>