var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"price",class:{
    'price--full': _vm.full,
    'price--discounted': _vm.discounted,
    'price--big': _vm.big,
    'price--semi': _vm.semi,
    'price--carousel': _vm.carousel,
    'price--full--noline': _vm.noLine,
    'price--full--noline--small': _vm.noLineSmall,
    'price--full--noline--cart': _vm.cartTotal,
  },style:({ color: _vm.priceColor })},[_vm._v(_vm._s(this.mainCurrency)+" "),_c('span',{staticClass:"decimal-and-currency"},[_c('span',{style:({ color: _vm.priceColor })},[_vm._v(_vm._s(this.fractionalCurrency))]),_c('span',{style:({ color: _vm.priceColor })},[_vm._v("лв.")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }