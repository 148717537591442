<template>
  <transition name="slide-in">
    <div class="cookies-prompt" v-if="cookieModalOpen  && currentUser.role !== 'admin'">
      <div class="cookies-content">
        <p class="cookies-text">
          За да направим използването на Preonow по-лесно и удобно, използваме
          бисквитки. Научете повече за това как използваме
          <a
            class="btn-link"
            :href="`${legalUri}/Cookie-Policy.pdf`"
            target="_blank"
            >бисквитки</a
          >
          и как да ги управлявате.
        </p>

        <Button
          class="btn-accept-prompt"
          @click="agree()"
          >ДАВАМ СЪГЛАСИЕ</Button
        >
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "@/components/Button";
import utils from "@/common/utils";

export default {
  data() {
    return {
      cookieModalOpen: false,
    };
  },
  components: {
    Button,
  },
  computed: {
    ...mapGetters(["currentUser"]),
    staticUri() {
      return process.env.VUE_APP_STATIC_URI;
    },
    legalUri() {
      return utils.legalUri();
    }
  },
  methods: {
    agree() {
      localStorage.setItem("termsAccepted", true);
      this.cookieModalOpen = false;
    },
  },
    mounted() {
      const accepted = localStorage.getItem("termsAccepted");
      setTimeout(() => {
        if (accepted) {
          this.cookieModalOpen = false;
        } else {
          this.cookieModalOpen = true;
        }
      }, 2000);
    },
};
</script>

<style lang="scss">
@import "@/scss/base.scss";
.cookies-prompt {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.97);
  border-radius: 6px;
  bottom: 10px;
  -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  display: flex;
  max-width: 800px;
  padding: 25px;
  position: fixed;
  width: 100%;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, 0);
}
.cookies-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.cookies-prompt-row {
  display: flex;
  justify-content: center;
  width: 30%;
}

.btn-accept {
  align-items: center;
  background-color: rgba(38, 97, 235, 0.93);
  border: 1px solid#1473E6;
  border-radius: 30px;
  cursor: pointer;
  color: white;
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  height: 35px;
  padding: 8px 15px 8px 15px;
  position: relative;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  white-space: nowrap;
}
.btn-accept span {
  margin-right: 0px;
  transform: rotate(-90deg);
}
.btn-accept:hover {
  background-color: rgba(36, 85, 221, 0.7);
  color: black;
}
.btn-link {
  color: #b100b2;
  text-decoration: underline;
}
.btn-link:hover {
  color: rgba(167, 35, 156, 0.8);
}
.cookies-text {
  width: 100%;
}
.slide-in-enter-active,
.slide-in-leave-active {
  -webkit-transition: all 0.8s ease;
  -moz-transition: all 0.8s ease;
  -ms-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
}
.slide-in-leave-to,
.slide-in-enter {
  opacity: 0.3;
  transform: translate(-50%, 150%);
}
@media screen and (max-width: $standard) {
  .cookies-prompt {
    flex-direction: column;
    bottom: 30px;
    right: 30px;
    width: calc(100% - 60px);
  }
  .cookies-content {
    flex-direction: column;
  }
}
</style>
