<template>
  <div
    class="input-wrapper"
    :class="{
      'input-wrapper--invalid': invalid,
      'input-wrapper-contacts': contacts,
      'input-wrapper--disabled': disabled,
      'input-wrapper--percent': percent && +value > 0,
    }"
  >
    <label v-if="label">
      {{ label }}
    </label>
    <input
      :value="value"
      :type="type || 'text'"
      :placeholder="placeholder"
      :disabled="disabled || lock"
      :maxlength="maxlength"
      :max="max"
      :min="min"
      :step="step"
      @input="input"
      @blur="$emit('blur')"
      :autocomplete="autocomplete || 'off'"
    />
    <span
      class="icons lock"
      v-if="lock"
      @click="$emit('input', ''), $emit('unlock')"
      >U</span
    >
  </div>
</template>

<script>
export default {
  props: [
    'autocomplete',
    'value',
    'type',
    'placeholder',
    'label',
    'disabled',
    'invalid',
    'maxlength',
    'contacts',
    'min',
    'max',
    'percent',
    'step',
    'lock', // disable + reset button
  ],
  methods: {
    input(e) {
    //   let v = e.target.value;
    //   if (this.type === 'number' && typeof this.min === 'number') {
    //     // v = +v < this.min ? this.min : v;
    //     // e.target.value = v
    //   }
      this.$emit('input', e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
@media screen and (max-width: $standard) {
  .input-wrapper.input-wrapper--disabled input:disabled,
  .input-wrapper.input-wrapper--disabled textarea:disabled {
    background-color: $mainBg;
    color: darkblue;
  }
}

.input-wrapper-contacts {
  textarea,
  input {
    background-color: #fff;
    border-radius: 4px;
    border: 0px solid #cacaca;
    color: #000;
    font-size: $px12;
    height: 32px;
    outline: none;
    padding: 9px 12px;
    width: 100%;
    @include tr();

    &::placeholder {
      color: #8e8e8e;
    }
  }
}
</style>
