import {ApiService} from "@/common/api.service";
import {getRandomNumber} from "vue-share-buttons/src/helpers";
import router from "@/router";

const state = {
  cart: {
    cartItems: [],
    cartItemsFromSession: [],
    cartTotal: 0.00,
    cartDeliveryTotal: 0.00,
    cartQuantity: 0,
    selectedPayment: { name: "Плати с карта", value: "fibank" }
  },
  productsWithDeliveryChoice:[],
  productsWithDeliveryPickUpChoice:[],
  response: {},
  cartLoaded: false,
  isLoading: false,
  deliveryFeatures: {
    productsWithPickUp: {
      onlyPickUp: [],
      deliveryAndPickUp: []
    },
    productsWithDeliveryToSpecificCities: []
  },
  deliveryAddressData: '',
  invoiceData: '',
  currentStep: null,
  deliveryFeaturesLoaded: false,
  cartItems: [],
}

const getters = {
  cart: state => state.cart,
  cartLoaded: state => state.cartLoaded,
  isLoading: state => state.isLoading,
  response: state => state.response,
  cartItems: state => state.cartItems,
  deliveryFeatures: state => state.deliveryFeatures,
  deliveryAddressData: state => state.deliveryAddressData,
  invoiceData: state => state.invoiceData,
  productsWithDeliveryChoice: state => state.productsWithDeliveryChoice,
  productsWithDeliveryPickUpChoice: state => state.productsWithDeliveryPickUpChoice,
  currentStep: state => state.currentStep,
}

const actions = {
  syncCart(context) {
    if (localStorage.getItem('cart')) {
      context.commit('setIsLoading', true)
      return ApiService.post("/cart/sync-data", {
        data: JSON.parse(localStorage.getItem('cart'))
      }).then((res) => {
        return res
      }).catch(() => {
      }).finally(() => {
        context.dispatch('getCart')
        context.dispatch('removeCartSession')
        context.commit('setIsLoading', false)
      });
    }
  },
  async addToCart(context, data) {
    context.commit('setIsLoading', true)
    if (context.getters.isAuthenticated) {
      return ApiService.post("/cart/add-to-cart", {
        data
      }).then((res) => {
        return res
      }).catch(() => {
      }).finally(() => {
        context.dispatch('getCart')
        context.commit('setIsLoading', false)
      });
    } else {
      let cart = [];
      const cartItem = await context.dispatch('getCartItem', {
        productId: data.product._id,
        discountIndex: data.discountIndex,
        isIncrease: true
      })

      if (!cartItem) {
        const discount = data.product.discounts[data.discountIndex]

        let currentPrice = discount.price.toLocaleString()
        let quantity = data.quantity || data.product.minQuantityPerOrder || 1
        let finalPrice = (currentPrice * quantity)

        cart = {
          _id: getRandomNumber(),
          product: data.product,
          currentPrice: currentPrice,
          discountIndex: data.discountIndex,
          finalPrice: finalPrice,
          quantity: quantity
        }

        context.commit('setCartItem', cart)
        context.commit('setCartTotals', {quantity: quantity, price: currentPrice, isIncrease: true})
        await context.dispatch('setCartSession')
        await context.dispatch('getCart')
        context.commit('setIsLoading', false)
      }

      return {
        data: {
          message: 'Продуктът е добавен успешно!'
        }
      }
    }
  },
  async changeQuantity(context, data) {
    if (context.getters.isAuthenticated) {
      context.commit('setIsLoading', true)
      return ApiService.post("/cart/change-quantity", {data})
        .then((res) => {
          return res
        }).catch(() => {
        }).finally(() => {
          context.dispatch('getCart')
          context.commit('setIsLoading', false)
        });
    } else {
      const cartItem = state.cart.cartItems.find(item => item._id === data.cartItemId)
      if (cartItem) {
        context.commit('changeQuantity', {cartItemId: data.cartItemId, isIncrease: data.isIncrease})
        context.commit('setCartTotals', {quantity: 1, price: cartItem.currentPrice, isIncrease: data.isIncrease})
        await context.dispatch('setCartSession')
        await context.dispatch('getCart')
        context.commit('setIsLoading', false)
      }
    }
  },

  async deleteItem(context, cartItemId) {
    if (context.getters.isAuthenticated) {
      context.commit('setIsLoading', true)
      return ApiService.delete(`/cart/${cartItemId}`)
        .then((res) => {
          return res
        }).catch(() => {
        }).finally(() => {
          context.dispatch('getCart')
          context.commit('setIsLoading', false)
        });
    } else {
      const cartItem = state.cart.cartItems.find(item => item._id === cartItemId)

      context.commit('setCartTotals', {quantity: cartItem.quantity, price: cartItem.currentPrice, isIncrease: false})
      context.commit('removeCartItem', cartItemId)
      await context.dispatch('setCartSession')
      await context.dispatch('getCart')

      if (state.cart.cartItems.length === 0) {
        await context.dispatch('removeCartSession')
      }
      return {
        data: {
          message: 'Продуктът е премахнат успешно!'
        }
      }
    }
  },
  async getCart(context) {
    if(router.history.current.path !== '/cart/checkout' && router.history.current.path !== '/cart/preview'){
      if (context.getters.isAuthenticated) {
        context.commit('setCartLoaded', false)
        await ApiService.get("/cart")
          .then((res) => {
            context.commit('setCart', res.data.data)
          })
          .catch(() => {
          }).finally(() => {
            context.commit('setCartLoaded', true)
          });
      } else {
        if (localStorage.getItem('cart')) {
          context.commit('setCart', JSON.parse(localStorage.getItem('cart')))
          context.commit('setCartItemsFromSession', await context.dispatch('sortByBrand'))
          context.commit('setCartLoaded', true)
        }
      }
    }

    await context.dispatch('setCartItemsId')
  },
  setCartItemsId(){
    state.cartItems = []
    Object.values(state.cart.cartItems).map(items => {
      items.products.map(item => {
        state.cartItems.push(item.product._id)
      })
    })
  },
  choosePayment(context, payment){
    context.commit('setPaymentMethod', payment)
  },
  sortByBrand(context) {
    const data = JSON.parse(localStorage.getItem('cart')).cartItems
    let sorted = {}

    data.map(async item => {
      if (sorted[item.product.seller._id]) {
        sorted[item.product.seller._id] = {
          ...sorted[item.product.seller._id],
          numberOfProducts: sorted[item.product.seller._id].numberOfProducts + item.quantity,
          sumOfProducts: sorted[item.product.seller._id].sumOfProducts + item.finalPrice,
          products: [...sorted[item.product.seller._id].products, {
            ...item
          }]
        }
      } else {

        sorted[item.product.seller._id] = {
          numberOfProducts: item.quantity,
          sumOfProducts: item.finalPrice,
          freeDeliveryForNumberOfItems: item.product.seller.company.freeDeliveryForNumberOfItems || null,
          freeDeliveryForSum: item.product.seller.company.freeDeliveryForSum || null,
          products: [{
            ...item,
          }],
          objects: await context.dispatch('getObjects', item.product.seller._id)
        }
      }
    })
    return sorted
  },
  getObjects(context, sellerId) {
    return ApiService.post("/protected-routes/get-objects-by-seller", {
      seller: sellerId
    }).then((res) => {
     return res.data.data
    }).finally(() => {

    });

  },
  setCartSession() {
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },

  removeCartSession(context) {
    localStorage.removeItem('cart')
    context.commit('resetCart')
  },

  getCartItem(context, data) {
    const cartItem = state.cart.cartItems.find(item => item.product._id === data.productId && item.discountIndex === data.discountIndex)
    if (cartItem) {
      context.commit('changeQuantity', {cartItemId: cartItem._id, isIncrease: data.isIncrease})
      context.commit('setCartTotals', {quantity: 1, price: cartItem.currentPrice, isIncrease: data.isIncrease})
    }
    return cartItem
  },

  async mapData(context) {
    state.deliveryFeatures.productsWithDeliveryToSpecificCities = [];
    state.deliveryFeatures.productsWithPickUp.onlyPickUp = []
    state.deliveryFeatures.productsWithPickUp.deliveryAndPickUp = []

    return new Promise(() => {
      Object.values(state.cart.cartItems).map(items => {
        items.products.map(async product => {
          if (product.product.clientTakesTheProductHimself) {
            context.commit('setProductsWithPickUpOption', {
              productId: product.product._id,
              cities: product.product.cities
            });
          }
          if (product.product.cities.length > 0 && product.product.cities[0] !== 'Всички градове') {
            context.commit('setProductsWithDeliveryToSpecificCities', {
              productId: product.product._id,
              cities: product.product.cities
            });
          }
        })
      })
    })
  },
  getProductsWithDeliveryOrPickUp(context) {
    const data = {
      productsWithDelivery: [],
      productsWithPickUp: []
    }
    return new Promise(() => {
      Object.values(state.cart.cartItems).map(brand => {
        brand.productWithDeliveryCount = 0
        brand.productWithPickUpCount = 0
        brand.products.map(product => {

          if (product.deliveryData.chosenOption == 2) {
            if (parseFloat(product.deliveryPrice) === 0) {
              context.commit('changeCartDeliveryTotal', {isPickUp: false, price: product.defaultDeliveryPrice})
              product.deliveryPrice =  product.defaultDeliveryPrice
              brand.deliveryTotal = brand.deliveryTotal + product.defaultDeliveryPrice
              // this.$set(product, 'deliveryPrice', product.defaultDeliveryPrice);
            }

            data.productsWithDelivery.push(product)
            brand.productWithDeliveryCount = brand.productWithDeliveryCount + 1
          } else {

            if (parseFloat(product.deliveryPrice) !== 0) {

              context.commit('changeCartDeliveryTotal', {isPickUp: true, price: product.defaultDeliveryPrice})
              // this.$set(product, 'deliveryPrice', 0);
              product.deliveryPrice = 0
              brand.deliveryTotal = brand.deliveryTotal - product.defaultDeliveryPrice
            }

            data.productsWithPickUp.push(product)
            brand.productWithPickUpCount = brand.productWithPickUpCount + 1
          }
        })
      })

      context.commit('setProductsWithChoice', data)
    })
  },
}

const mutations = {
  setCartLoaded(state, isLoaded) {
    state.cartLoaded = isLoaded
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setCartItemsFromSession(state, data) {
    state.cart.cartItemsFromSession = data
  },
  setCart(state, res) {
    state.cart = res
    state.cart.selectedPayment={ name: "Плати с карта", value: "fibank" }
  },
  setCartItem(state, item) {
    state.cart.cartItems.push(item)
  },
  resetCart(state) {
    state.cart = {
      cartItems: [],
      cartTotal: 0.00,
      cartDeliveryTotal: 0.00,
      cartQuantity: 0,
      cartItemsFromSession: []
    }
  },
  removeCartItem(state, id) {
    state.cart.cartItems = state.cart.cartItems.filter(item => item._id !== id)
  },
  setCartTotals(state, data) {
    if (data.isIncrease) {
      state.cart.cartQuantity = state.cart.cartQuantity + data.quantity;
      state.cart.cartTotal = state.cart.cartTotal + (data.quantity * data.price);
    } else {
      state.cart.cartQuantity = state.cart.cartQuantity - data.quantity;
      state.cart.cartTotal = state.cart.cartTotal - (data.quantity * data.price);
    }
  },
  changeQuantity(state, data) {
    if (data.isIncrease) {
      state.cart.cartItems.find(item => item._id === data.cartItemId).quantity++
    } else {
      state.cart.cartItems.find(item => item._id === data.cartItemId).quantity--
    }
  },
  setPaymentMethod(state, payment) {
    state.cart.selectedPayment = payment
  },
  setProductsWithPickUpOption(state, data) {
    if (data.cities.length === 0) {
      state.deliveryFeatures.productsWithPickUp.onlyPickUp = [...state.deliveryFeatures.productsWithPickUp.onlyPickUp, data.productId]
    } else {
      state.deliveryFeatures.productsWithPickUp.deliveryAndPickUp = [...state.deliveryFeatures.productsWithPickUp.deliveryAndPickUp, data.productId]
    }
  },
  setProductsWithDeliveryToSpecificCities(state, data) {
    state.deliveryFeatures.productsWithDeliveryToSpecificCities.push({[data.productId]: data.cities})
  },
  setDeliveryAddressData(state, deliveryId) {
    state.deliveryAddressData = deliveryId
  },
  setInvoiceData(state, invoiceId) {
    state.invoiceData = invoiceId
  },
  changeCartDeliveryTotal(state, data){
    state.cart.cartDeliveryTotal = data.isPickUp ? state.cart.cartDeliveryTotal - data.price : state.cart.cartDeliveryTotal + data.price
  },
  setProductsWithChoice(state, data){
    state.productsWithDeliveryChoice = data.productsWithDelivery
    state.productsWithDeliveryPickUpChoice = data.productsWithPickUp
  },
  setCartStoreData(state, data){
    state[data.state] = data.value
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};
