<template>
    <div class="widget-container">
        <section class="title">
            <p class="type-name">ТЪРГОВЦИ С НАЙ-ВИСОК РЕЙТИНГ</p>
            <router-link to="sellers">
                <span class="view-all">Виж всички</span>
            </router-link>
        </section>

        <section class="sellers-container">
            <div class="bg">
                <template v-if="!isMobile && totalPages > 1">
                    <span class="arrow arrow-prev" @click="prev()" />
                    <span class="arrow arrow-next" @click="next()" />
                </template>
                <template v-else-if="isMobile && sellers.length > 1">
                    <span class="arrow arrow-prev" @click="scrollBack()" />
                    <span class="arrow arrow-next" @click="scrollForward()" />
                </template>
            </div>
            <div
                class="sellers"
                :style="{ justifyContent: justifyContent }"
                ref="items"
                @touchstart="handleTouchStart"
                @touchmove="handleTouchMove"
            >
                <div class="seller" v-for="(s, i) in limitedSellers" :key="`${s._id}-${i}`" @click="openSeller(s._id)">
                    <div class="seller-wrapper">
                        <img
                            :data-src="`${uploads}/users/${s._id}/200x200-conv-pn-${s.cover}`"
                            data-sizes="auto"
                            alt="Product cover"
                            width="100px"
                            height="100px"
                            class="lazyload"
                            v-if="s.cover"
                        />
                        <img src="@/assets/img/home/no-cover.webp" alt="No cover" v-else width="100px" height="100px" />
                        <p class="name" :title="s.company ? s.company : s.name">{{ s.company ? s.company : s.name }}</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import 'lazysizes';

    export default {
        props: ['isMobile'],
        data () {
            return {
                limit: 5,
                active: 0,
                totalPages: 0,
                sellers: [],
                xDown: null,
                yDown: null,
                translateX: 0,
                justifyContent: ''
            };
        },
        computed: {
            uploads () {
                return process.env.VUE_APP_UPLOADS_URI;
            },
            limitedSellers () {
                const x = this.active * this.limit;
                return this.sellers.slice(x, x + this.limit);
            },
        },
        methods: {
            scroll (l) {
                const el = this.$refs["items"];
                el.scrollTo({
                    left: l,
                    behavior: "smooth",
                });
            },
            scrollForward () {
                const items = this.$refs.items;
                const l = items.scrollLeft + items.firstElementChild.offsetWidth;
                this.scroll(l);
            },
            scrollBack () {
                const items = this.$refs.items;
                const l = items.scrollLeft - items.firstElementChild.offsetWidth;
                this.scroll(l);
            },
            prev () {
                if (this.active > 0) {
                    this.active -= 1;
                } else {
                    this.active = this.totalPages - 1;
                }
            },
            next () {
                if (this.active < this.totalPages - 1) {
                    this.active += 1;
                } else {
                    this.active = 0;
                }
            },
            getSellers () {
                this.sellers = [];
                this.$apiService.get("/un/top/sellers").then((res) => {
                    this.sellers = res.data;

                    this.setTotalPages();
                    this.handleResize();
                    this.loaded = true;
                });
            },
            openSeller (s) {
                this.$router.push(`/sellers/${s}`);
            },
            setTotalPages () {
                let currentTotalPages = 0;
                this.totalPages = Math.ceil((this.sellers.length) / this.limit);
                if (currentTotalPages !== this.totalPages && this.active !== 0) {
                    this.active = this.active - 1;
                }
            },
            handleResize () {
                if (window.innerWidth < 900) {
                    this.limit = 20;
                } else if (window.innerWidth >= 900 && window.innerWidth < 1600) {
                    this.limit = 5;
                } else {
                    this.limit = 6;
                }
                this.setTotalPages();
                if ((0 < window.innerWidth && window.innerWidth <= 768 && this.sellers.length > 3)
                    || (768 < window.innerWidth && window.innerWidth <= 900 && this.sellers.length > 4)
                    || (900 < window.innerWidth && window.innerWidth <= 1280 && this.sellers.length > 5)
                    || (window.innerWidth > 1280 && this.sellers.length > 6)) {
                    this.justifyContent = 'flex-start'
                } else {
                    this.justifyContent = 'center'
                }
            },
            handleTouchStart (event) {
                const firstTouch = event.touches[0];
                this.xDown = firstTouch.clientX;
                this.yDown = firstTouch.clientY;
            },
            handleTouchMove (event) {
                if (!this.xDown || !this.yDown) {
                    return;
                }

                const xUp = event.touches[0].clientX;
                const yUp = event.touches[0].clientY;

                const xDiff = this.xDown - xUp;
                const yDiff = this.yDown - yUp;

                if (Math.abs(xDiff) > Math.abs(yDiff)) {
                    // left swipe
                    if (xDiff > 0) {
                        this.next("left", true);
                    }
                    // right swipe
                    else {
                        this.prev("right", true);
                    }
                }

                this.xDown = null;
                this.yDown = null;
            },
        },
        mounted () {
            this.getSellers();
            window.addEventListener("resize", this.handleResize);
        },
        destroyed () {
            window.removeEventListener("resize", this.handleResize);
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/scss/base.scss';

    .widget-container {
        position: relative;
        .title {
            text-align: center;
            display: grid;
            gap: 10px;
            margin-bottom: 30px;

            .type-name {
                color: $lightGrey;
                font-size: 22px;
                text-transform: uppercase;
            }

            .view-all {
                color: #b100b2;
                font-size: 14px;

                &:hover {
                    cursor: url('../../assets/img/cursor.png'), auto;
                }
            }
        }
    }

    .sellers-container {
        display: flex;
        justify-content: center;
        position: relative;
        .sellers {
            display: flex;
            //width: 90%;
            text-align: center;
            position: relative;
            justify-content: center;
            .seller {
                width: 180px;
                height: 180px;
                border: 1px solid #e9e8e8;
                display: flex;
                margin: 0 20px;
                cursor: url('../../assets/img/cursor.png'), auto;
                .seller-wrapper {
                    margin: auto;
                }
                img {
                    display: block;
                    margin: auto;
                }
                &:first-child {
                    margin-left: 0;
                    margin-right: 20px;
                }
                .name {
                    margin-top: 30px;
                }
            }
        }
    }

    .bg {
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;

        .arrow {
            width: 40px;
            height: 40px;
            background-image: url('../../assets/img/arrow-new.svg');
            position: absolute;
            top: 50%;
            z-index: 2;
            background-repeat: no-repeat;
            cursor: url('../../assets/img/cursor.png'), auto;
            filter: invert(93%) sepia(0%) saturate(1145%) hue-rotate(140deg) brightness(91%) contrast(87%);
            &:hover {
                filter: invert(12%) sepia(98%) saturate(5691%) hue-rotate(296deg) brightness(84%) contrast(108%);
            }
            &.arrow-prev {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(0, -50%);
            }
            &.arrow-next {
                position: absolute;
                transform: translate(0, -50%) rotate(180deg);
                top: 50%;
                right: 0;
            }
        }
    }
    @media all and (max-width: $m) {
        .sellers-container .sellers .seller .name {
            margin-top: 10px;
        }
    }
    @media all and (max-width: $sm) {
        .sellers-container {
            .sellers {
                .seller {
                    img {
                        width: 80px;
                        height: 80px;
                    }

                    .name {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    @media all and (max-width: $standard) {
        .sellers-container {
            .sellers {
                overflow: scroll;
                width: 85%;
                .seller {
                    min-width: 120px;
                    img {
                        width: 70px;
                        height: 70px;
                    }
                    .name {
                        margin-top: 20px;
                        font-size: 12px;
                    }
                }
            }
        }
        .bg {
            width: 105%;
            .arrow {
                background-position: center;
                width: 35px;
                border-top-right-radius: 25px;
                border-bottom-right-radius: 25px;
                height: 60px;
                background-color: rgba(196, 196, 196, 0.14);
                &.arrow-prev {
                    transform: translate(0, -50%);
                }

                &.arrow-next {
                    transform: translate(0, -50%) rotate(180deg);
                }
            }
        }
    }
    @media all and (max-width: $m) {
        .sellers-container {
            .sellers {
                .seller {
                    width: 150px;
                    height: 150px;
                }
            }
        }
    }

    @media all and (max-width: $sm) {
        .sellers-container {
            .sellers {
                .seller {
                    width: 120px;
                    height: 120px;
                }
            }
        }
    }
</style>
