<template>
  <div class="home__mobile__notifications-modal-wrapper" @click.self="close()">
    <transition name="modal" appear>
      <div class="home__mobile__notifications-modal">
        <p class="title-notifications" v-if="currentUser.role === 'client'">
          Нови съобщения/плащания за
        </p>
        <p class="title-notifications" v-if="currentUser.role === 'seller'">
          Нови поръчки/плащания за
        </p>
        <p class="title-notifications" v-if="currentUser.role === 'admin'">
          Нови заявки за
        </p>

        <div class="notifications-nav">
          <div v-if="currentUser.role === 'client'" @click="$emit('close')">
            <router-link
              v-if="notify.notifyProductServicesClient > 0"
              :to="`/${currentUser.role}/orders`"
              class="profile__nav__button router-link-active"
            >
              <div class="icons">i</div>
              <div class="texts">
                <p>Поръчка на стоки и услуги</p>
                <span>{{ notify.notifyProductServicesClient }}</span>
              </div>
            </router-link>
            <router-link
              v-if="
                notify.notifyAuctionsClient + notify.notifyRevAuctionsClient > 0
              "
              :to="`/${currentUser.role}/auctions`"
              class="profile__nav__button router-link-active"
            >
              <div class="icons">i</div>
              <div class="texts">
                <p>Участия в търгове</p>
                <span>{{
                  notify.notifyAuctionsClient + notify.notifyRevAuctionsClient
                }}</span>
              </div>
            </router-link>
            <router-link
              v-if="notify.preoPaymentNotifications > 0"
              :to="`/client/payment-request`"
              class="profile__nav__button router-link-active"
            >
              <div class="icons">i</div>
              <div class="texts">
                <p>Направени Preo ваучери</p>
                <span v-if="notify.preoPaymentNotifications === 1"
                  >{{ notify.preoPaymentNotifications }} непотвърдено
                  плащане</span
                >
                <span v-else
                  >{{ notify.preoPaymentNotifications }} непотвърдени
                  плащания</span
                >
              </div>
            </router-link>
          </div>

          <div v-if="currentUser.role === 'seller'" @click="$emit('close')">
            <div class="sellerAsClient-nav">
              <router-link
                v-if="notify.sellerAsClientNotifyProductServices > 0"
                :to="`/${currentUser.role}/orders-sellerAsClient`"
                class="profile__nav__button router-link-active"
              >
                <div class="icons">i</div>
                <div class="texts">
                  <p>Поръчка на стоки и услуги</p>
                  <span>{{ notify.sellerAsClientNotifyProductServices }}</span>
                </div>
              </router-link>
              <router-link
                v-if="notify.preoPaymentNotificationssellerAsClient > 0"
                :to="`/${currentUser.role}/microwallets-sellerAsClient`"
                class="profile__nav__button router-link-active"
              >
                <div class="icons">i</div>
                <div class="texts">
                  <p>Направени Preo ваучери</p>
                  <span
                    v-if="notify.preoPaymentNotificationssellerAsClient === 1"
                    >{{
                      notify.preoPaymentNotificationssellerAsClient
                    }}
                    непотвърдено плащане</span
                  >
                  <span v-else
                    >{{
                      notify.preoPaymentNotificationssellerAsClient
                    }}
                    непотвърдени плащания</span
                  >
                </div>
              </router-link>
              <router-link
                v-if="notify.sellerAsClientNotifyB2B > 0"
                :to="`/${currentUser.role}/b2b-sellerAsClient`"
                class="profile__nav__button router-link-active"
              >
                <div class="icons">i</div>
                <div class="texts">
                  <p>Участия в B2B</p>
                  <span>{{ notify.sellerAsClientNotifyB2B }}</span>
                </div>
              </router-link>
            </div>
            <div class="seller-nav">
              <router-link
                v-if="notify.notifyProductServicesSeller > 0"
                :to="`/${currentUser.role}/orders`"
                class="profile__nav__button router-link-active"
              >
                <div class="icons">i</div>
                <div class="texts">
                  <p>Клиентски поръчки стоки/услуги</p>
                  <span>{{ notify.notifyProductServicesSeller }}</span>
                </div>
              </router-link>
              <router-link
                v-if="notify.notifyMicrowalletsSeller > 0"
                :to="`/${currentUser.role}/microwallets`"
                class="profile__nav__button router-link-active"
              >
                <div class="icons">i</div>
                <div class="texts">
                  <p>Клиентски Preo ваучери</p>
                  <span>{{ notify.notifyMicrowalletsSeller }}</span>
                </div>
              </router-link>
              <router-link
                v-if="notify.notifyB2BSeller > 0"
                :to="`/${currentUser.role}/b2b`"
                class="profile__nav__button router-link-active"
              >
                <div class="icons">i</div>
                <div class="texts">
                  <p>Клиентски участия в B2B</p>
                  <span>{{ notify.notifyB2BSeller }}</span>
                </div>
              </router-link>
            </div>
          </div>

          <div v-if="currentUser.role === 'admin'" @click="$emit('close')">
            <div class="admin-nav">
              <router-link
                v-if="notify.sellersCount > 0"
                :to="`/${currentUser.role}/sellers?new=true`"
                class="profile__nav__button router-link-active"
              >
                <div class="icons">i</div>
                <div class="texts">
                  <p>Активация на нов търговец</p>
                  <span>{{ notify.sellersCount }}</span>
                </div>
              </router-link>
              <router-link
                v-if="notify.nsProductService > 0"
                :to="`/${currentUser.role}/products/product-service?new=true`"
                class="profile__nav__button router-link-active"
              >
                <div class="icons">i</div>
                <div class="texts">
                  <p>Активация на нов продукт</p>
                  <span>{{ notify.nsProductService }}</span>
                </div>
              </router-link>
              <router-link
                v-if="notify.nsMicrowallets > 0"
                :to="`/${currentUser.role}/products/microwallet?new=true`"
                class="profile__nav__button router-link-active"
              >
                <div class="icons">i</div>
                <div class="texts">
                  <p>Активация на нов Preo ваучер</p>
                  <span>{{ notify.nsMicrowallets }}</span>
                </div>
              </router-link>
              <router-link
                v-if="notify.nsB2B > 0"
                :to="`/${currentUser.role}/products/b2b?new=true`"
                class="profile__nav__button router-link-active"
              >
                <div class="icons">i</div>
                <div class="texts">
                  <p>Активация на нов B2B</p>
                  <span>{{ notify.nsB2B }}</span>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["notify", "currentUser"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.home__mobile__notifications-modal-wrapper {
  align-items: center;
  background-color: #00000080;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.home__mobile__notifications-modal {
  width: 80%;
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  top: 8%;
  right: 10%;
  z-index: 10;
  border-radius: 10px;
}

.title-notifications {
  padding: 4%;
  background-color: white;
  text-align: center;
  font-weight: 700;
  white-space: nowrap;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.notifications-nav {
  background-color: white;
  z-index: 10;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;

  > div {
    > div:not(:first-child) {
      margin-top: 3px;
    }
  }

  .sellerAsClient-nav {
    padding-bottom: 0%;
  }

  .seller-nav {
    padding-top: 0%;
  }
}

.profile__nav__button {
  align-items: center;
  background-color: $mainBg;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 7vh;
  padding: 12px 15px;
  position: relative;
  @include tr();
  @include noselect();
  &:not(:last-child) {
    margin-bottom: 3px;
  }
  &.profile__nav__button--add {
    padding-right: 38px;
  }
  .icons {
    color: #212121;
    font-size: 20px;
    margin-right: 15px;
  }
  .icons-button {
    position: absolute;
    right: 4px;
    top: 50%;
    @include transform(translateY(-50%));
  }
  .texts {
    color: #171717;
    overflow: hidden;
    white-space: nowrap;
    font-size: 3vw;
    p {
      font-weight: 700;
      overflow: hidden;
    }
    span {
      font-weight: 300;
      opacity: 0.7;
    }
  }
  &:hover {
    background-color: $hoverPurple;
  }
  & + p {
    margin-top: 17px;
  }

  &.router-link-active {
    background-color: #5c3882;
    .texts,
    .icons,
    .icons-button:not(:hover) {
      color: #fff;
    }
  }
}

.notifications-nav .seller-nav {
  .profile__nav__button {
    &.router-link-active {
      background-color: #469aea;
      .texts,
      .icons,
      .icons-button:not(:hover) {
        color: #fff;
      }
    }
  }
}

.notifications-nav .admin-nav {
  .profile__nav__button {
    &.router-link-active {
      background-color: #665eff;
      .texts,
      .icons,
      .icons-button:not(:hover) {
        color: #fff;
      }
    }
  }
}

@media screen and (min-width: $xs) {
  .profile__nav__button {
    .texts {
      color: #171717;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
      p {
        font-weight: 700;
        overflow: hidden;
      }
      span {
        font-weight: 300;
        opacity: 0.7;
      }
    }
  }
}
</style>
