<template>
  <div>
    <div
      class="profile__nav"
      v-if="$route.name !== 'employeePaymentRequest' && !mobileOnDesktop"
    >
      <template v-if="!mobile">
        <template
          v-if="currentUser.role === 'client' || currentUser.role === 'seller'"
        >
          <p class="orders-from-clients" v-if="!currentUser.employee">
            ОБЯВИ И ПОРЪЧКИ
          </p>
          <template>
            <div v-if="currentUser.role === 'client'">
              <router-link
                :to="`/${currentUser.role}/orders`"
                class="profile__nav__button"
                :class="{
                  'router-link-active':
                    ($route.name === 'clientOrders' && !$route.query.type) ||
                    ($route.name === 'clientOrder ' &&
                      $route.query.type === 'product-service'),
                  'router-link-inactive':
                    $route.name === 'clientOrder' &&
                    $route.query.type !== 'product-service',
                }"
              >
                <div class="icons">Q</div>
                <div class="texts">
                  <p>ПОРЪЧКИ</p>
                  <span>Моите поръчки на стоки и услуги</span>
                </div>
              </router-link>
              <router-link
                :to="`/${currentUser.role}/preo-vouchers`"
                class="profile__nav__button"
                :class="{
                  'router-link-active':
                    ($route.name === 'clientMicrowallets' &&
                      !$route.query.type) ||
                    ($route.name === 'clientOrder' &&
                      $route.query.type === 'microwallet'),
                  'router-link-inactive':
                    $route.name === 'clientOrder' &&
                    $route.query.type !== 'microwallet',
                }"
              >
                <div class="icons">V</div>
                <div class="texts">
                  <p>PREO ВАУЧЕРИ</p>
                  <span>Моите Preo ваучери</span>
                </div>
              </router-link>
              <router-link
                v-if="currentUser.role !== 'employee'"
                :to="`/${currentUser.role}/favourites`"
                class="profile__nav__button"
              >
                <div class="icons">
                  <img :src="`${uploads}/assets/img/nav/favs.svg`" alt="Preonow" />
                </div>
                <div class="texts">
                  <p>ЛЮБИМИ</p>
                  <span>Моите любими обяви в Preonow</span>
                </div>
              </router-link>
            </div>
          </template>

          <router-link
            v-if="currentUser.employee"
            class="profile__nav__button"
            :to="`/${currentUser.role}/microwallets-sellerAsClient`"
            :class="{
              'router-link-active':
                $route.name === 'sellerAsClientMicrowallets' ||
                $route.query.from === 'sellerAsClientMicrowallets',
              'router-link-inactive':
                $route.name !== 'sellerAsClientMicrowallets' &&
                $route.query.from !== 'sellerAsClientMicrowallets',
            }"
          >
            <div class="icons-img-wrapper">
              <img :src="`${uploads}/assets//img/nav/pr2-s.svg`" alt="Preonow" />
            </div>
            <div class="texts">
              <p>Клиентски Preo ваучери</p>
              <span>Закупени клиентски Preo ваучери</span>
            </div>
          </router-link>
        </template>

        <template v-if="currentUser.role === 'seller' && !currentUser.employee">
          <div>
            <router-link
              :to="`/${currentUser.role}/orders-sellerAsClient`"
              class="profile__nav__button"
              :class="{
                'router-link-active':
                  $route.query.from === 'sellerAsClientOrders' ||
                  $route.name === 'sellerAsClientMicrowallets' ||
                  $route.name === 'sellerAsClientAuctions' ||
                  $route.name === 'sellerAsClientReversedAuctions' ||
                  $route.name === 'sellerAsClientB2b',

                'router-link-inactive':
                  $route.name === 'clientOrder' &&
                  $route.query.type !== 'ordersTabs',
              }"
            >
              <div class="icons">Q</div>
              <div class="texts">
                <p>МОИТЕ ПОРЪЧКИ</p>
                <span>Моите поръчки като клиент</span>
              </div>
            </router-link>
          </div>

          <router-link
            :to="`/${currentUser.role}/favourites`"
            class="profile__nav__button"
          >
            <div class="icons">
              <img :src="`${uploads}/assets/img/nav/favs.svg`" alt="Preonow" />
            </div>
            <div class="texts">
              <p>ЛЮБИМИ</p>
              <span>Моите любими обяви в Preonow</span>
            </div>
          </router-link>
        </template>
        <template v-if="currentUser.role === 'admin'">
          <p>ОПЕРАЦИИ</p>
          <router-link
            :to="`/${currentUser.role}/orders`"
            class="profile__nav__button"
            :class="{
              'router-link-active':
                $route.name === 'sellerAsClientAuctions' ||
                $route.query.from === 'sellerAsClientAuctions',
            }"
          >
            <div class="icons">i</div>
            <div class="texts">
              <p>ПОРЪЧКИ</p>
              <span>Всички нови и потвърдени поръчки</span>
            </div>
          </router-link>
          <p>БАЗА ДАННИ</p>
          <router-link
            to="/admin/products/product-service"
            class="profile__nav__button profile__nav__button--add"
          >
            <div class="icons">Q</div>
            <div class="texts">
              <p>СТОКИ И УСЛУГИ</p>
              <span>{{ count['product-service'] }} бр.</span>
            </div>
          </router-link>
          <router-link
            to="/admin/products/microwallet"
            class="profile__nav__button profile__nav__button--add"
          >
            <div class="icons">V</div>
            <div class="texts">
              <p>PREO ВАУЧЕРИ</p>
              <span>{{ count['microwallet'] }} бр.</span>
            </div>
          </router-link>
          <router-link
            to="/admin/products/auction"
            class="profile__nav__button profile__nav__button--add"
          >
            <div class="icons">S</div>
            <div class="texts">
              <p>ТЪРГОВЕ</p>
              <span>{{ count['auction'] }} бр.</span>
            </div>
          </router-link>
          <router-link
            to="/admin/products/reversed-auction"
            class="profile__nav__button profile__nav__button--add"
          >
            <div class="icons">R</div>
            <div class="texts">
              <p>ОБРАТНИ ТЪРГОВЕ</p>
              <span>{{ count['reversed-auction'] }} бр.</span>
            </div>
          </router-link>
          <router-link
            to="/admin/products/b2b"
            class="profile__nav__button profile__nav__button--add"
          >
            <div class="icons">!</div>
            <div class="texts">
              <p>B2B</p>
              <span>{{ count['b2b'] }} бр.</span>
            </div>
          </router-link>
          <router-link
            to="/admin/sellers"
            class="profile__nav__button profile__nav__button--add"
            :class="{
              'router-link-active':
                [
                  'sellerCompany',
                  'sellerContract',
                  'sellerContract',
                  'sellerPreonow',
                  'sellerPlan',
                  'sellerProducts',
                  'sellerProduct',
                  'sellerUserSettings',
                  'sellerEmployees'
                ].indexOf($route.name) > -1,
            }"
          >
            <div class="icons">F</div>
            <div class="texts">
              <p>ТЪРГОВЦИ</p>
              <span>{{ count['seller'] }} бр.</span>
            </div>
          </router-link>

          <router-link
            to="/admin/not-verified-sellers"
            class="profile__nav__button profile__nav__button--add"
            :class="{
              'router-link-active':
                [
                  'not-verified-sellerUserSettings',
                  'not-verified-sellerCompany',
                  'not-verified-preonow',
                  'not-verified-contract'
                ].indexOf($route.name) > -1,
            }"
          >
            <div class="icons">]</div>
            <div class="texts">
              <p>ЮРИДИЧЕСКИ ЛИЦА</p>
              <span>{{ count['not-verified-seller'] }} бр.</span>
            </div>
          </router-link>
          <router-link
            to="/admin/clients"
            class="profile__nav__button profile__nav__button--add"
            :class="{
              'router-link-active':
                [
                  'clientPersonalData',
                  'clientDeliveryAddresses',
                  'clientInvoiceData',
                  'clientUserSettings',
                ].indexOf($route.name) > -1,
            }"
          >
            <div class="icons">D</div>
            <div class="texts">
              <p>ФИЗИЧЕСКИ ЛИЦА</p>
              <span>{{ count['client'] }} бр.</span>
            </div>
          </router-link>
        </template>

        <p
          class="orders-from-clients"
          v-if="
            currentUser.role === 'seller' &&
            !currentUser.employee
          "
        >
          ТЪРГОВСКА ДЕЙНОСТ
        </p>

        <div
          class="sellerActivity"
          v-if="
            currentUser.role === 'seller' &&
            !currentUser.employee
          "
        >
          <router-link
            to="/seller/products/product-service"
            class="profile__nav__button profile__nav__button--add"
            :class="{
              'router-link-active': $route.name === 'sellerProducts' || $route.name === 'sellerProduct' ,
              notSeller: !currentUser.verified,
            }"
          >
            <div class="icons">
              <img :src="`${uploads}/assets/img/nav/myProducts.svg`" alt="" />
            </div>

            <div class="texts">
              <p>МОИТЕ ОБЯВИ</p>
              <span>Всички създадени обяви</span>
            </div>

            <UserMenu
              v-if="openUserMenu"
              inNav="true"
              @close="openUserMenu = false"
            />

            <span
              v-if="!currentUser.deactivated"
              title="Създай нов продукт или услуга"
              class="icons-button"
              @click.prevent="openUserMenu = true"
              >U</span
            >
          </router-link>

          <router-link
            v-if="!currentUser.employee"
            :to="`/${currentUser.role}/orders`"
            class="profile__nav__button"
            :class="{
              'router-link-active':
                $route.name === 'sellerOrders' ||
                $route.name === 'sellerMicrowallets' ||
                $route.name === 'sellerAuctions' ||
                $route.name === 'receivedOffers' ||
                $route.name === 'sellerB2b',
              'router-link-inactive':
                $route.query.from === 'sellerAsClientOrders' ||
                $route.query.from === 'sellerAsClientMicrowallets' ||
                $route.query.from === 'sellerAsClientAuctions' ||
                $route.query.from === 'sellerAsClientReversedAuctions' ||
                $route.query.from === 'sellerAsClientB2b' ||
                $route.query.from === 'sellerMicrowallets' ||
                $route.query.from === 'sellerAuctions' ||
                $route.query.from === 'sellerReversedAuctions' ||
                $route.query.from === 'sellerB2b',
              notSeller: !currentUser.verified,
            }"
          >
            <div class="icons-img-wrapper">
              <img :src="`${uploads}/assets/img/nav/clientOrders.svg`" alt="" />
            </div>
            <div class="texts">
              <p>ПОРЪЧКИ ОТ КЛИЕНТА</p>
              <span>Поръчки на клиента и други търговци</span>
            </div>
          </router-link>

          <router-link
            to="/seller/objects"
            class="profile__nav__button"
            :class="{ notSeller: !currentUser.verified }"
          >
            <div class="icons">^</div>
            <div class="texts">
              <p>ОБЕКТИ</p>
              <span>Моите обекти</span>
            </div>
            <span v-if="!currentUser.deactivated"
              title="Добави обекти"
              class="icons-button"
              @click.prevent.stop="goTo('/seller/objects/new')"
              >U</span
            >
          </router-link>

          <router-link
            to="/seller/employees"
            class="profile__nav__button"
            :class="{ notSeller: !currentUser.verified }"
          >
            <div class="icons">
              <img :src="`${uploads}/assets/img/nav/employees.svg`" alt="Preonow" />
            </div>
            <div class="texts">
              <p>СЛУЖИТЕЛИ</p>
              <span>Акаунти на моите служители</span>
            </div>
            <span v-if="!currentUser.deactivated"
              title="Добави служители, които да обслужват Preo ваучерите"
              class="icons-button"
              @click.prevent.stop="goTo('/seller/employees/new')"
              >U</span
            >
          </router-link>

          <router-link
            :to="`/${currentUser.role}/tips`"
            class="profile__nav__button"
            :class="{ notSeller: !currentUser.verified }"
          >
            <img class="icons-img-wrapper" :src="`${uploads}/assets/img/nav/tips.svg`" alt="Preonow" />
            <div class="texts">
              <p>БАКШИШИ</p>
              <span>Списък и отчетност</span>
            </div>
          </router-link>
          <div
            class="notSellerDiv--hover"
            :class="{ notSellerDiv: !currentUser.verified }"
            v-if="!currentUser.verified"
          >
            <p>Станете търговец и предлагайте вашите стоки и услуги</p>
            <img :src="`${uploads}/assets/img/nav/activateSeller.svg`" alt="Preonow" />
            <Button
              @click="wantsToBeSeller()"
              class="btn-notSeller"
              :class="{ sentSellerReq: currentUser.wantsToBeSeller }"
            >
              <p v-if="currentUser.wantsToBeSeller">В ПРОЦЕС НА ОДОБРЕНИЕ</p>
              <p v-else>АКТИВИРАЙ СЕГА</p>
            </Button>
          </div>
        </div>

        <p class="orders-from-clients">ПРОФИЛ И НАСТРОЙКИ</p>
        <router-link
          v-if="currentUser.role === 'seller' && currentUser.employee"
          :to="`/seller/profile/employee/${currentUser._id}`"
          class="profile__nav__button"
        >
          <div class="icons">T</div>
          <div class="texts">
            <p>{{ currentUser.name }}</p>
            <span>Моят профил</span>
          </div>
        </router-link>
        <router-link
          v-else
          :to="`/${currentUser.role}/profile`"
          class="profile__nav__button"
        >
          <div class="icons">
            <img :src="`${uploads}/assets/img/nav/profile.svg`" alt="Preonow" />
          </div>
          <div class="texts">
            <p v-if="currentUser.role === 'seller'">
              {{ currentUser.company.name }}
            </p>
            <p v-else>{{ currentUser.name }}</p>
            <span>Моят профил</span>
          </div>
        </router-link>
        <router-link
          to="/admin/homepage"
          class="profile__nav__button"
          v-if="currentUser.role === 'admin'"
        >
          <div class="icons">5</div>
          <div class="texts">
            <p>НАЧАЛНА СТРАНИЦА</p>
            <span>Управление на банерите и категориите</span>
          </div>
        </router-link>
        <router-link
          to="/admin/admins"
          class="profile__nav__button"
          v-if="currentUser.role === 'admin' && !currentUser.employee"
        >
          <div class="icons">
            <img
              class="img-inactive"
              src="../../assets/img/nav/employees.svg"
              alt="Preonow"
            />
          </div>
          <div class="texts">
            <p>СЛУЖИТЕЛИ</p>
            <span>Preonow оператори</span>
          </div>
          <span
            class="icons-button"
            @click.prevent.stop="goTo('/admin/admins/new')"
            >U</span
          >
        </router-link>
        <router-link
          :to="`/${currentUser.role}/tips`"
          class="profile__nav__button"
          v-if="currentUser.role === 'seller' && currentUser.employee"
        >
            <img class="icons-img-wrapper" :src="`${uploads}/assets/img/nav/tips.svg`" alt="Preonow" />
          <div class="texts">
            <p>МОИТЕ БАКШИШИ</p>
            <span>Списък и отчетност</span>
          </div>
        </router-link>
        <a class="profile__nav__button" @click="logout">
          <div class="icons">W</div>
          <div class="texts">
            <p>ИЗХОД</p>
            <span>Излизане от профила в Preonow</span>
          </div>
        </a>
      </template>
    </div>

    <!-- MOBILE NAV -->
    <template v-if="mobile">
      <div class="horizont-nav">
        <div
          @click="(selected = 'clientNav'), (isActivated = true)"
          class="router-div"
          v-if="currentUser.role === 'client'"
        >
          <section
            class="router-wrapper"
            :class="{
              active: 'clientNav',
              inactive: selected !== 'clientNav',
            }"
          >
            <span class="icons">+</span>
            <a>МОИТЕ ПОРЪЧКИ</a>
          </section>
        </div>
        <div
          @click="(selected = 'sellerProductsNav'), (isActivated = true)"
          class="router-div"
          v-if="currentUser.role === 'seller' && !currentUser.employee"
        >
          <section
            class="router-wrapper"
            :class="{
              active: selected === 'sellerProductsNav',
              inactive: selected !== 'sellerProductsNav',
            }"
            v-if="!currentUser.deactivated && currentUser.verified"
          >
            <span class="icons">
              <img
                class="img-inactive"
                src="../../assets/img/nav/mobile/myProducts.svg"
                alt="Preonow"
              />
              <img
                class="img-active"
                src="../../assets/img/nav/mobile/myProducts-active.svg"
                alt="Preonow"
              />
            </span>
            <p>ОБЯВИ</p>
          </section>
        </div>
        <div
          @click="(selected = 'sellerNav'), (isActivated = true)"
          class="router-div"
          v-if="
            currentUser.role === 'seller' &&
            !currentUser.employee &&
            currentUser.verified
          "
        >
          <section
            class="router-wrapper"
            :class="{
              active: selected === 'sellerNav',
              inactive: selected !== 'sellerNav',
            }"
          >
            <span class="icons">
              <img
                class="img-inactive"
                src="../../assets/img/nav/mobile/clientOrders.svg"
                alt="Preonow"
              />
              <img
                class="img-active"
                src="../../assets/img/nav/mobile/clientOrders-active.svg"
                alt="Preonow"
              />
            </span>
            <a>ПОРЪЧКИ ОТ КЛИЕНТА</a>
          </section>
        </div>
        <router-link
          to="/employee/payment-request"
          class="router-wrapper"
          :class="{
            active: selected === 'sellerNav',
            inactive: selected !== 'sellerNav',
          }"
          @click.native="myProfil = false"
          v-if="currentUser.role === 'seller' && currentUser.employee"
        >
          <span class="icons">,</span>
          <a>ИЗПРАЩАНЕ НА ЗАЯВКА</a>
        </router-link>
        <div
          @click="(selected = 'sellerAsClientNav'), (isActivated = true)"
          class="router-div"
          v-if="currentUser.role === 'seller' && !currentUser.employee"
        >
          <section
            class="router-wrapper"
            :class="{
              active: 'sellerAsClientNav',
              inactive: selected !== 'sellerAsClientNav',
            }"
          >
            <span class="icons">
              <img
                class="img-inactive"
                src="../../assets/img/nav/mobile/cart.svg"
                alt="Preonow"
              />
              <img
                class="img-active"
                src="../../assets/img/nav/mobile/cart-active.svg"
                alt="Preonow"
              />
            </span>
            <a>МОИТЕ ПОРЪЧКИ</a>
          </section>
        </div>
        <div
          @click="
            (selected = 'sellerFavouritesNav'), (isActivated = !isActivated)
          "
          class="router-div"
          v-if="!currentUser.position && currentUser.role !== 'admin'"
        >
          <router-link
            class="router-wrapper"
            :class="{
              active: 'sellerFavouritesNav',
              inactive: selected !== 'sellerFavouritesNav',
            }"
            :to="`/${currentUser.role}/favourites`"
          >
            <span class="icons">8</span>
            <a>Любими</a>
          </router-link>
        </div>

        <div
          @click="(selected = 'adminPanel'), (isActivated = true)"
          class="router-div"
          v-if="currentUser.role === 'admin'"
        >
          <section
            class="router-wrapper"
            :class="{
              active: selected === 'adminPanel',
              inactive: selected !== 'adminPanel',
            }"
          >
            <span class="icons">+</span>
            <a>ПОРЪЧКИ И ОПЕРАЦИИ</a>
          </section>
        </div>
        <div
          @click="(selected = 'database-products'), (isActivated = true)"
          class="router-div"
          v-if="currentUser.role === 'admin'"
        >
          <section
            class="router-wrapper"
            :class="{
              active: selected === 'database-products',
              inactive: selected !== 'database-products',
            }"
          >
            <span class="icons">*</span>
            <a>БД ПРОДУКТИ </a>
          </section>
        </div>

        <div
          @click="(selected = 'database-users'), (isActivated = true)"
          class="router-div"
          v-if="currentUser.role === 'admin'"
        >
          <section
            class="router-wrapper"
            :class="{
              active: selected === 'database-users',
              inactive: selected !== 'database-users',
            }"
          >
            <span class="icons">3</span>
            <a>БД ПОТРЕБИТЕЛИ</a>
          </section>
        </div>

       <router-link
          to="/seller/tips"
          class="router-wrapper"
          :class="{
            active: isActivated && selected === 'sellerNav',
            inactive: selected !== 'sellerNav',
          }"
          v-if="currentUser.role === 'seller' && currentUser.employee"
        >
          <!-- <span class="icons">'</span> -->
<img class="margin--bottom" :src="`${uploads}/assets/img/nav/tips.svg`" alt="Preonow" />

          <a>МОИТЕ БАКШИШИ</a>
        </router-link>

        <div
          @click="(selected = 'profilesAndSettings'), (isActivated = true)"
          class="router-div"
        >
          <section
            class="router-wrapper"
            :class="{
              active: isActivated && selected === 'profilesAndSettings',
              inactive: selected !== 'profilesAndSettings',
            }"
            @click.prevent="myProfil = true"
            :to="`/${currentUser.role}/profile/menu`"
          >
            <span class="icons">
              <img
                class="img-inactive"
                :src="`${uploads}/assets/img/nav/mobile/profile.svg`"
                alt="Preonow"
              />
              <img
                class="img-active"
                src="../../assets/img/nav/mobile/profile-active.svg"
                alt="Preonow"
              />
            </span>
            <a v-if="!currentUser.verified">ПРОФИЛИ И НАСТРОЙКИ</a>
            <a v-else>МОЯТ ПРОФИЛ</a>
          </section>
        </div>

      </div>

      <!-- VERTCAL NAV -->
      <div class="vertical-nav">
        <!-- МОИТЕ ПОРЪЧКИ CLIENT -->
        <section
          class="myProfile leftBubbleClient"
          v-if="
            currentUser.role === 'client' &&
            selected === 'clientNav' &&
            isActivated
          "
        >
          <p class="profile">МОИТЕ ПОРЪЧКИ</p>
          <main @click="isActivated = false">
            <router-link :to="`/${currentUser.role}/orders`" class="router">
              <p class="icons">i</p>
              <p>Поръчки</p>
            </router-link>
            <router-link
              class="router"
              :to="`/${currentUser.role}/microwallets`"
            >
              <p class="icons">V</p>
              <p>Preo ваучери</p>
            </router-link>
          </main>
        </section>
        <!-- МОИТЕ ПОРЪЧКИ CLIENT -->

        <!-- ОБЯВИ SELLER-->
        <section
          class="myProfile leftBubble"
          v-if="
            currentUser.role === 'seller' &&
            currentUser.verified &&
            selected === 'sellerProductsNav' &&
            isActivated
          "
        >
          <p class="profile">МОИТЕ ОБЯВИ</p>
          <main @click="isActivated = false">
            <router-link
              :to="`/${currentUser.role}/products/product-service`"
              class="router"
            >
              <p class="icons">Q</p>
              <p>Продукти</p>
            </router-link>
            <router-link
              :to="`/${currentUser.role}/products/microwallet`"
              class="router"
            >
              <p class="icons">V</p>
              <p>Preo ваучери</p>
            </router-link>
            <router-link
              :to="`/${currentUser.role}/products/b2b`"
              class="router"
            >
              <p class="icons">!</p>
              <p>B2B</p>
            </router-link>
          </main>
        </section>
        <!-- ОБЯВИ SELLER-->

        <!-- ПОРЪЧКИ ОТ КЛИЕНТА SELLER-->
        <section
          class="myProfile slightLeftBubble"
          v-if="
            currentUser.role === 'seller' &&
            currentUser.verified &&
            selected === 'sellerNav' &&
            isActivated
          "
        >
          <p class="profile">ПОРЪЧКИ ОТ КЛИЕНТА</p>
          <main @click="isActivated = false">
            <router-link class="router" :to="`/${currentUser.role}/orders`">
              <p class="icons">Q</p>
              <p>Клиентски поръчки</p>
            </router-link>
            <router-link
              class="router"
              :to="`/${currentUser.role}/preo-vouchers`"
            >
              <p class="icons">V</p>
              <p>Preo ваучери</p>
            </router-link>
            <router-link :to="`/${currentUser.role}/b2b`" class="router">
              <p class="icons">!</p>
              <p>B2B</p>
            </router-link>
          </main>
        </section>
        <!-- ПОРЪЧКИ ОТ КЛИЕНТА SELLER-->

        <!-- МОИТЕ ПОРЪЧКИ SELLER-->
        <section
          class="myProfile centerBubble"
          :class="{centerBubbleNotVerifiedSeller: !currentUser.verified}"
          v-if="
            currentUser.role === 'seller' &&
            selected === 'sellerAsClientNav' &&
            isActivated
          "
        >
          <p class="profile">МОИТЕ ПОРЪЧКИ</p>
          <main @click="isActivated = false">
            <router-link
              :to="`/${currentUser.role}/orders-sellerAsClient`"
              class="router"
            >
              <p class="icons">i</p>
              <p>Поръчки</p>
            </router-link>
            <router-link
              class="router"
              :to="`/${currentUser.role}/preoVouchers-sellerAsClient`"
            >
              <p class="icons">V</p>
              <p>Preo ваучери</p>
            </router-link>
            <router-link
              v-if="currentUser.verified"
              class="router"
              :to="`/${currentUser.role}/b2b-sellerAsClient`"
            >
              <p class="icons">!</p>
              <p>B2B</p>
            </router-link>
          </main>
        </section>
        <!-- МОИТЕ ПОРЪЧКИ SELLER-->

        <!-- ПОРЪЧКИ ADMIN-->
        <section
          class="myProfile leftBubbleAdmin"
          v-if="
            currentUser.role === 'admin' &&
            selected === 'adminPanel' &&
            isActivated
          "
        >
          <p class="profile">ПОРЪЧКИ</p>
          <main @click="isActivated = false">
            <router-link :to="`/${currentUser.role}/orders-sellerAsClient`" class="router">
              <p class="icons">i</p>
              <p>Поръчки</p>
            </router-link>
          </main>
        </section>
        <!-- ПОРЪЧКИ ADMIN-->

        <!-- БД ПРОДУКТИ ADMIN-->
        <section
          class="myProfile slightLeftAdmin"
          v-if="
            currentUser.role === 'admin' &&
            selected === 'database-products' &&
            isActivated
          "
        >
          <p class="profile">БД ПРОДУКТИ</p>
          <main @click="isActivated = false">
            <router-link to="/admin/products/product-service" class="router">
              <p class="icons">Q</p>
              <p>СТОКИ И УСЛУГИ</p>
            </router-link>
            <router-link to="/admin/products/microwallet" class="router">
              <p class="icons">V</p>
              <p>PREO ВАУЧЕРИ</p>
            </router-link>
            <router-link to="/admin/products/auction" class="router">
              <p class="icons">S</p>
              <p>ТЪРГОВЕ</p>
            </router-link>
            <router-link to="/admin/products/reversed-auction" class="router">
              <p class="icons">R</p>
              <p>ОБРАТНИ ТЪРГОВЕ</p>
            </router-link>
            <router-link to="/admin/products/b2b" class="router">
              <p class="icons">!</p>
              <p>B2B</p>
            </router-link>
          </main>
        </section>
        <!-- БД ПРОДУКТИ ADMIN-->

        <!-- БД ПОТРЕБИТЕЛИ ADMIN-->
        <section
          class="myProfile slightRightAdmin"
          v-if="
            currentUser.role === 'admin' &&
            selected === 'database-users' &&
            isActivated
          "
        >
          <p class="profile">БД ПОТРЕБИТЕЛИ</p>
          <main @click="isActivated = false">
            <router-link to="/admin/sellers" class="router">
              <p class="icons">F</p>
              <p>ТЪРГОВЦИ</p>
            </router-link>
            <router-link to="/admin/not-verified-sellers" class="router">
              <p class="icons">]</p>
              <p>ЮРИДИЧЕСКИ ЛИЦА</p>
            </router-link>
            <router-link to="/admin/clients" class="router">
              <p class="icons">D</p>
              <p>ФИЗИЧЕСКИ ЛИЦА</p>
            </router-link>
          </main>
        </section>
        <!-- БД ПОТРЕБИТЕЛИ ADMIN-->

        <!-- МОЯТ ПРОФИЛ / ПРОФИЛ И НАСТРОЙКИ-->
        <section
          class="myProfile rightBubble"
          :class="{rightBubbleClient:currentUser.role === 'client', rightBubbleEmployee:currentUser.employee,
          rightBubbleAdmin:currentUser.role === 'admin',
          rightBubbleNotVerifiedSeller: !currentUser.verified && currentUser.role === 'seller'&& !currentUser.employee
          }"
          v-if="selected === 'profilesAndSettings' && myProfil"
        >
          <p class="profile">ПРОФИЛ И НАСТРОЙКИ</p>
          <main @click="myProfil = false">
            <router-link
              v-if="!currentUser.employee"
              :to="`/${currentUser.role}/profile/menu`"
              class="router"
            >
              <img :src="`${uploads}/assets/img/nav/profile.svg`" alt="Preonow" />
              <section>
                <p>
                  {{
                    currentUser.role === 'seller'
                      ? currentUser.company.name
                      : currentUser.name
                  }}
                </p>
                <span>Моят профил</span>
              </section>
            </router-link>
            <router-link
              v-if="currentUser.employee"
              :to="`/seller/profile/employee/${currentUser._id}`"
              class="router"
            >
              <img :src="`${uploads}/assets/img/nav/profile.svg`" alt="Preonow" />
              <section>
                <p>{{ currentUser.name }}</p>
                <span>Моят профил</span>
              </section>
            </router-link>
            <router-link
              v-if="currentUser.verified"
              to="/seller/objects"
              class="router"
            >
              <section class="icons">^</section>
              <section>
                <p>ОБЕКТИ</p>
                <span>Моите обекти</span>
              </section>
            </router-link>
            <router-link
              v-if="currentUser.verified"
              to="/seller/employees"
              class="router"
            >
              <img :src="`${uploads}/assets/img/nav/employees.svg`" alt="Preonow" />
              <section>
                <p>СЛУЖИТЕЛИ</p>
                <span>Акаунти на моите служители</span>
              </section>
            </router-link>
            <router-link
              v-if="currentUser.verified"
              :to="`/${currentUser.role}/tips`"
              class="router"
            >
              <img :src="`${uploads}/assets/img/nav/tips.svg`" alt="Preonow" />
              <section>
                <p>БАКШИШИ</p>
                <span>Списък и отчетност</span>
              </section>
            </router-link>
            <!-- admin -->
            <router-link
              to="/admin/homepage"
              class="router"
              v-if="currentUser.role === 'admin'"
            >
              <p class="icons">5</p>
              <p>НАЧАЛНА СТРАНИЦА</p>
            </router-link>
            <router-link
              to="/admin/admins"
              class="router"
              v-if="currentUser.role === 'admin' && !currentUser.employee"
            >
              <img :src="`${uploads}/assets/img/nav/employees.svg`" alt="" />
              <p>ПОТРЕБИТЕЛИ</p>
            </router-link>
            <!-- admin -->

            <a class="router" @click="logout">
              <section class="icons">W</section>
              <section>
              <p>ИЗХОД</p>
              <span>Preonow</span>
              </section>
            </a>
          </main>
        </section>
        <!-- МОЯТ ПРОФИЛ / ПРОФИЛ И НАСТРОЙКИ-->
      </div>

      <!-- VERTCAL NAV -->
    </template>
    <!-- MOBILE NAV -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/Button';
import UserMenu from '@/components/UserMenu';

export default {
  props: ['mobileOnDesktop'],
  components: { Button, UserMenu },
  data() {
    return {
      count: {},
      selected: '',
      mobile: false,
      isActivated: false,
      open: false,
      myProfil: false,
      openUserMenu: false,
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    uploads() {
      return `${process.env.VUE_APP_UPLOADS_URI}`;
    },
  },
  watch: {
    $route: {
      handler: 'routeChange',
      immediate: true,
    },
  },
  methods: {
    routeChange() {
      if (this.currentUser.role === 'admin') {
        this.countProducts();
        this.countUsers();
      } else if (this.currentUser.role === 'seller') {
        this.countProducts(true);
      }
      this.open = false;
    },
    countProducts(seller) {
      const types = [
        'product-service',
        'microwallet',
        'auction',
        'reversed-auction',
        'b2b',
      ];

      for (let t of types) {
        this.$apiService
          .get(
            `/products/seller/${t}${
              seller ? `/${this.currentUser._id}` : ''
            }?count=1`
          )
          .then((res) => {
            this.$set(this.count, t, res.data || 0);
          })
          .catch(() => this.$set(this.count, t, 0));
      }
    },
    countUsers() {
      const roles = [
        { role: 'client' },
        { role: 'seller', verified: 'true' },
        { role: 'not-verified-seller', verified: 'false' },
      ];
      let url = '';

      for (let r of roles) {
        if (r.role !== 'client') {
          url = `/users/?role=seller&employee=false&count=1&verified=${r.verified}`;
        } else {
          url = `/users/?role=client&employee=false&count=1`;
        }
        this.$apiService
          .get(url)
          .then((res) => {
            this.$set(this.count, r.role, res.data || 0);
          })
          .catch(() => this.$set(this.count, r.role, 0));
      }
    },
    goTo(r) {
      if (r !== this.$route.path) {
        this.$router.push(r);
      }
    },
    logout() {
      this.$store.dispatch('logout').then(() => this.$router.push('/'));
    },
    isMobileMode() {
      if (
        window.innerWidth < 900 ||
        this.$route.name === 'employeePaymentRequest' ||
        this.mobileOnDesktop
      ) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    },
    wantsToBeSeller() {
      this.$set(this.currentUser, 'wantsToBeSeller', true);
      this.$apiService
        .put(`/user/wantsToBeSeller`, this.currentUser)
        .then(() => {
          this.$router.push('/seller/profile/menu');
        })
        .catch((err) => {
          this.error = err.response.data.message;
        });
    },
  },
  mounted() {
    this.isMobileMode();
    window.addEventListener('resize', this.isMobileMode);
    if (this.$route.query.s) {
      this.selected = this.$route.query.s;
      this.active = true;
      this.isActivated = true;
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.isMobileMode);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.profile__nav {
  margin-right: 13px;
  min-width: 280px;
  width: 280px;
  > p {
    color: rgba($color: #171717, $alpha: 0.5);
    font-size: $px12;
    margin-bottom: 5px;
    padding: 0 $px12;
  }
  &.router-link-active:not(.router-link-inactive) {
    img {
      filter: invert(100%);
    }
  }
}

.create-new {
  background: rgb(252, 251, 255);
  border-radius: 15px;
  box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.25);
  margin-left: 275px;
  height: fit-content;
  position: absolute;
  padding: 0 3vh;
  width: 350px;
  z-index: 1;

  p {
    font-weight: 900;
    text-align: center;
    padding: 1vh 0;
  }

  div {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(107, 55, 107);
    border-radius: 10px;
    cursor: pointer;
    padding: 1vh;
    margin-bottom: 0.3vh;
    @include tr();
    @include noselect();
    p {
      color: rgb(107, 55, 107);
      padding-bottom: 0vh;
      text-align: left;
    }
    span {
      color: gray;
    }
  }
  .create-new-button:hover {
    background-color: $hoverPurple;
  }
  .create-new-last-button {
    margin-bottom: 2vh;

    &:hover {
      background-color: $hoverPurple;
    }
  }
}
.orders-from-clients {
  margin-top: 5%;
}

.profile__nav__button {
  align-items: center;
  background-color: $mainBg;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  height: 55px;
  padding: 15px 5px;
  margin-bottom: 1.5px;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 3px;
  }
  @include tr();
  @include noselect();
  &.profile__nav__button--add {
    padding-right: 38px;
  }
  .icons {
    color: #212121;
    font-size: 20px;
    margin-right: 15px;
  }
  .icons-button {
    position: absolute;
    right: 4px;
    top: 50%;
    @include transform(translateY(-50%));
  }
  .icons-img-wrapper {
    width: 23px;
    height: 23px;
    margin-right: 15px;
  }
  .texts {
    color: #171717;
    overflow: hidden;
    p {
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    span {
      font-size: $px12;
      font-weight: 300;
      line-height: 10px;
      opacity: 0.7;
    }
  }
  &:hover:not(.inactive) {
    background-color: $hoverPurple;
  }
  & + p {
    margin-top: 17px;
  }

  &.router-link-active:not(.router-link-inactive) {
    background-color: $mainPurple;
    .texts,
    .icons,
    .icons-button:not(:hover) {
      color: #fff;
    }

    img {
      filter: invert(100%);
    }
  }

  &.inactive {
    opacity: 0.6;
  }
  &.notSeller {
    opacity: 0.6;
    pointer-events: none;
  }
}
@media all and (max-width:900px) {
  .profile__nav__button {
    height: 15px;
    margin-bottom: 0;
    overflow-y: hidden;

    .texts {
      color: #654d91;
      max-width: fit-content;

      p {
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
        text-align: center;
        white-space: pre-line;
      }
      span {
        font-size: $px12;
        font-weight: 300;
        line-height: 10px;
        opacity: 0.7;
      }
    }
    &:hover:not(.inactive) {
      background-color: $mainBg;
    }
    & + p {
      margin-top: 17px;
    }

    &.router-link-active:not(.router-link-inactive) {
      background-color: $mainBg;
      p {
        background-color: rgba(36, 4, 96, 0.5);
      }
      .texts,
      .icons,
      .icons-button:not(:hover) {
        color: #fff;
      }
      .icons-img-wrapper {
        img {
          filter: invert(100%);
        }
      }
    }

    &.inactive {
      opacity: 0.6;
    }

    &.profile__nav__button--add {
      padding-right: 0px;
    }
  }
  .create-new {
    background: rgb(252, 251, 255);
    border-radius: 15px;
    box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.8);
    bottom: 160px;
    height: fit-content;
    position: fixed;
    padding: 0 3vh;
    width: fit-content;
    right: 10px;

    p {
      font-weight: 900;
      text-align: center;
      padding: 1vh 0;
    }

    div {
      background: rgb(255, 255, 255);
      border: 1px solid rgb(107, 55, 107);
      border-radius: 10px;
      cursor: pointer;
      padding: 1vh;
      margin-bottom: 0.3vh;
      @include tr();
      @include noselect();
      p {
        color: rgb(107, 55, 107);
        padding-bottom: 0vh;
        text-align: left;
      }
      span {
        color: gray;
      }
    }
  }
}
</style>
