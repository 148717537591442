<template>
  <div class="order-info-wrapper">
    <div class="title" style="padding-bottom: 20px">Информация за поръчката</div>
    <div class="row">
      <span>Всички продукти:</span>
      <Price :value="cart.cartTotal" noLineSmall="1"/>
    </div>
    <div class="row" v-if="cart.cartDeliveryTotal !== null && isAuthenticated && showDeliveryPrice && showDeliveryTotal">
      <span>Цена на доставка:</span>
      <Price :value="cart.cartDeliveryTotal" noLineSmall="1"/>
    </div>
    <div class="row" style="color: #666666">
      <span style="color: #666666; font-weight: 600">ОБЩО</span>
      <Price :value="getFinalPrice" noLine="1" style="color: #666666"/>
    </div>
    <ContinueButton
      v-if="showButton"
      :redirect-to="redirectTo"
      :redirect-from="redirectFrom"
      :should-validate="shouldValidate"
      :is-valid="isValid"
      @validateData="$emit('validateData')"
      @changeStatus="changeCartStatus"
    />
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import Price from "@/components/Price.vue";
import ContinueButton from "@/components/checkout/ContinueButton.vue";

export default {
  components: {ContinueButton, Price},
  props:['redirectTo', 'redirectFrom', 'validateData', 'shouldValidate', 'isValid', 'showButton', 'status'],
  computed: {
    ...mapGetters(['cart', 'isLoading', 'isAuthenticated']),
    getFinalPrice(){
      return parseFloat(this.$store.getters.cart.cartTotal) +  parseFloat(this.$store.getters.cart.cartDeliveryTotal)
    },
    showDeliveryPrice(){
      let show = true
      Object.values(this.cart.cartItems).map(brand=>{
        if(brand.deliveryTotal === 0){
          brand.products.map( product => {
            if(product.product.priceIsCalculatedByCourier){
              show = false
            }
          })
        }
      })
      return show
    },
    showDeliveryTotal(){
      let show = false

      Object.values(this.cart.cartItems).map(brand => {
        brand.products.map(product=> {
          if(product.product.type !== 'microwallet'){
            show = true
          }
        })
      })

      return show
    }
  },
  methods:{
    setCartStoreAndRedirect(){
      this.$store.commit('setCartStoreData', {state: 'currentStep', value: this.redirectFrom})
      this.$router.push(this.redirectTo)
    },
    async redirect() {
      if(this.shouldValidate){
        await this.$emit('validateData')

        if(this.isValid){
         await this.setCartStoreAndRedirect()
        }
      }else{
        await this.setCartStoreAndRedirect()
      }
    },
    changeCartStatus(){
      let data = {
        status: this.status
      }

      return this.$apiService
        .post(
          `/cart/change-status`, {data}
        )
        .then(() => {

        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/base';

.title {
  font-size: 22px;
  color: $darkGrey;
}

.order-info-wrapper {
  width: 30%;

  .row {
    color: $lightGrey;
    padding: 5px 0;
    font-size: 16px;
    display: flex;
    justify-content: space-between;

    span {
      margin: auto 0;
    }
  }

  .cart-button {
    width: 70%;
    height: 40px;
    border-radius: 50px;
    background-color: #b50db6;
    color: white;
    font-size: 14px;
    justify-content: center;
    cursor: url('../assets/img/cursor.png'), auto;
  }
}


@media all and (max-width: $m) {
  .order-info-wrapper {
    width: 30%;
  }
  .title {
    font-size: 18px;
  }
}

@media all and (max-width: 1050px) {
  .title {
    font-size: 20px;
  }
}

@media all and (max-width: $standard) {
  .order-info-wrapper {
    padding-top: 30px;
    width: 70%;
    margin: auto;
  }
}

@media all and (max-width: 650px) {
  .order-info-wrapper {
    width: 100%;
    padding: 30px 0;
  }
}

@media all and (max-width: $xs) {
  .title {
    padding-bottom: 15px;
  }
}
</style>
