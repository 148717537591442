<template >
  <section @click="redirectToFavourite">
    <div style="position: relative">
      <img  :src="`${uploads}/assets/img/header/favourite-icon.svg`" alt="" />
      <span class="items-counter">{{ favouriteProducts.length }}</span>
    </div>
    <p class="nav-link" :style="{display: hideText ? 'none' : '' } ">Любими</p>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: ['hideText'],
  components: {  },
  computed: {
    ...mapGetters(['isMobile', 'currentUser', 'isAuthenticated', 'favouriteProducts']),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
  },
  data() {
    return{}
  },
  watch: {},
  methods: {
    redirectToFavourite(){
      if(this.isAuthenticated && this.currentUser.role !== 'admin'){
        this.$router.push(`/${this.currentUser.role}/favourites`);
      }

    }
  },
    mounted() {
    document.querySelector('body').style.overflow = 'hidden';
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/nav.scss';

</style>
