import {
  mapGetters
} from 'vuex';

export default {
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    paymentQueries(to) {
      if (to.query.o && to.query.t) {
        const type = {
          client: {
            'product-service': 'clientOrders',
            'microwallet': 'clientMicrowallets',
          },
          seller: {
            'product-service': 'sellerAsClientOrders',
            'microwallet': 'sellerAsClientMicrowallets',
            'auction': 'sellerAsClientAuctions',
            'reversed-auction': 'sellerAsClientReversedAuctions',
            'b2b': 'sellerAsClientB2b'
          }
        }

        const url = `/${this.currentUser.role}/orders/${to.query.o}?type=${to.query.t}&from=${type[this.currentUser.role][to.query.t]}`

        this.$router.push(url);
      } else if (to.query.pp) {
        this.$router
          .push("/")
          .then(() => this.$snotify.success("Плащането е успешно"));
      }
    }
  }
}
