const state = {
  now: new Date(),
}

const getters = {
  now: state => state.now,
}

const actions = {
  now(context) {
    context.commit('setNow');
  }
}

const mutations = {
  setNow(state) {
    state.now = new Date();
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};