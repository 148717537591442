
const ABANDONED_CART = {
  id: 1,
  status: 'abandonedCart',
  label: 'Изоставена количка'
}
const PROCESSED = 10 //when client go to checkout page
const STATUSES= [
  {
    'id': 1,
    'status': 'abandonCart',
  },
  {
    'id': 2,
    'status': 'pending',
  },
  {
    'id': 3,
    'status': 'completed',
  },
  {
    'id': 4,
    'status': 'canceled',
  },
  {
    'id': 5,
    'status': 'declined',
  },
  {
    'id': 6,
    'status': 'refunded',
  },
  {
    'id': 7,
    'status': 'awaitingPayment',
  },
  {
    'id': 8,
    'status': 'confirmed',
  },
  {
    'id': 9,
    'status': 'created', //when cart is created
  },
  {
    'id': 10,
    'status': 'processed', //when forward to checkout
  },
]


module.exports = {
  STATUSES, ABANDONED_CART, PROCESSED
};
