<template>
  <div class="modal-wrapper" @click.self="close()">
    <transition name="modal" appear>
      <div class="modal">
        <header>
          <span class="icons sqr back" @click.self="close()">b</span>
          <p class="center confirm-title">
            <template>
              <p>Добави бакшиш</p>
            </template>
          </p>
        </header>
        <main>
          <p class="center confirm-title">
            Служител, който ви обслужва: {{ preoPayment.createdBy.name }}
          </p>
          <p class="center modal-text">
            Бакшиш се дава по желание, лично към служителя и се приспада от
            вашите ПРИО точки. Може да изберете някой от нашите готови бакшиши,
            или да дадете по ваш избор:
          </p>

          <div class="preo_points">
            <img
              v-for="o in preoOptions"
              :key="o"
              :class="{ selected: o.toFixed(2) === tip || o === tip }"
              @click="(tip = o), format()"
              :src="`${require(`@/assets/img/preo-payment/${o}-preo.svg`)}`"
              alt="preo tip option"
            />
          </div>
        </main>

        <section>
          <p class="center">Или стойност по избор:</p>
          <div class="quantity">
            <span class="quantity-toggle minus" @click="removeQuantity">
              -
            </span>
            <Input
              class="quantity-order"
              type="number"
              v-model="tip"
              @input="tip = +tip"
              @blur="format()"
              step="0.01"
              min="0"
              :max="this.ballanceLeft"
              value="00.00"
            />
            <span class="quantity-toggle plus" @click="addQuantity"> + </span>
          </div>
          <h1 class="center purple">ПРИО ТОЧКИ</h1>
        </section>

        <p class="error">
          {{ error }}
        </p>
        <div class="gift-button-div">
          <button class="accept-button" :disabled="loading" @click="save()">
            ДОБАВИ БАКШИШ
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Input from '@/components/Input';
export default {
  components: {
    Input,
  },
  props: ['preoPayment', 'ballance', 'preo', 'additionalMwBallance'],
  data() {
    return {
      error: '',
      loading: false,
      microwallets: [],
      tip: null,
      preoOptions: [1, 2, 5, 10],
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    ballanceLeft() {
      let ballanceLeft = 0;
      if (this.additionalMwBallance) {
        ballanceLeft =
          this.additionalMwBallance.preoLeft - (this.preo - this.ballance);
      } else {
        ballanceLeft = this.ballance - this.preo;
      }
      return ballanceLeft;
    },
  },
  methods: {
    close(update) {
      this.$emit('close', update);
    },
    save() {
      this.error = '';
      if (this.tip >= 0.1 && this.tip <= this.ballanceLeft) {
        this.close(Number(this.tip));
      } else {
        this.error = 'Невалидна сума за бакшиш';
      }
    },
    removeQuantity() {
      if (this.tip >= 0.1) {
        this.tip = Number(this.tip);
        let test = (this.tip -= 0.1);
        this.tip = test.toFixed(2);
      } else {
        this.$snotify.error(`Минималната стойност за бакшиш е 0.10!`);
        return false;
      }
    },
    addQuantity() {
      if (this.tip < this.ballanceLeft) {
        this.tip = Number(this.tip);
        let test = (this.tip += 0.1);
        this.tip = test.toFixed(2);
      } else {
        this.$snotify.error(
          this.tip > 0
            ? `Максималната стойност за бакшиш, е ${this.ballanceLeft}!`
            : 'Няма наличност'
        );
        return false;
      }
    },
    format() {
      this.tip = +this.tip;
      if (this.tip < 0) {
        this.tip = 0;
      } else if (this.tip > this.ballanceLeft) {
        this.tip = this.ballanceLeft;
      }
      this.tip = this.tip.toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.modal {
  .confirm-title {
    font-size: 1.42857rem;
    font-weight: 500;
  }
  .error {
    display: flex;
    justify-content: center;
  }
  .quantity {
    padding: 10px 0;
  }
  .purple {
    color: #350887;
  }
  .preo_points {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px auto;
    max-width: 400px;
    img {
      cursor: pointer;
      filter: grayscale(100%);
      height: 100%;
      margin: 2px;
      width: 45%;
      @include tr();
      &:hover {
        filter: grayscale(70%);
      }
      &.selected {
        filter: grayscale(0%);
      }
    }
  }
  .gift-button-div {
    display: flex;
    margin: auto;
    padding: 20px;
    width: fit-content;
    button {
      color: #fff;
      margin: 0 5px;
    }
    .accept-button {
      background-color: #1473e6;
    }
  }
}
</style>
