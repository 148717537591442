<template>
  <div class="modal-wrapper" @click="hideModal">
    <div class="discounts-container">
      <div class="discount-wrapper"
           v-for="(discount, i) in product.discounts"
           :key="`discount--${i}`"
           @click="chooseDiscount(i)"
      >
        <div class="background"/>

        <div class="text-wrapper">
          <div class="title">СПЕСТЯВАТЕ</div>
          <div class="discount"> {{ discount.percent }}%</div>
          <div class="dates small">
            <span style="width: 100%">Доставка в периода: </span>
            <span>{{ discount.dateFrom | formatDateDot }}</span> -
            <span>{{ discount.dateTo | formatDateDot }}</span>
          </div>
          <div class="small" style="opacity: 40%">Спестявате {{ discount.percent }} % от базовата цена.</div>

        </div>
        <div class="choose">
          <img :src="`${uploads}/assets/img/check.svg`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: ['product'],
  components: {},
  computed: {
    ...mapGetters([]),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
  },
  data() {
    return {}
  },
  watch: {},
  methods: {
    hideModal(){
      this.$emit('hideModal');
    },
    chooseDiscount(i) {
      this.$emit('discountIndex', i);
    }
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/scss/nav.scss';
.modal-wrapper{
  overflow: auto;
}
.discounts-container {
  width: 960px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  top: auto;

  .discount-wrapper {
    background-color: white;
    width: 200px;
    height: 200px;
    border-radius: 10px;
    position: relative;
    margin: 20px;
    float: left;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &:nth-child(4n-1) {
      background: linear-gradient(86deg, rgba(53, 8, 135, 1) 0%, rgba(177, 0, 178, 0.8729692560617998) 0%, rgba(53, 8, 135, 1) 85%);
      background-size: 350%;

    }

    &:nth-child(4n-2) {

      background: linear-gradient(86deg, rgba(53, 8, 135, 1) 0%, rgba(53, 8, 135, 1) 0%, rgba(177, 0, 178, 0.8729692560617998) 98%);
    }

    &:nth-child(4n-3) {
      background: linear-gradient(180deg, rgba(53, 8, 135, 1) 0%, rgba(53, 8, 135, 1) 37%, rgba(177, 0, 178, 0.8729692560617998) 98%);
    }

    &:nth-child(4n-4) {
      background: linear-gradient(0deg, rgba(53, 8, 135, 1) 0%, rgba(53, 8, 135, 1) 26%, rgba(177, 0, 178, 0.8729692560617998) 98%);
    }

    .background {
      background-image: url(/img/preonow-logo-white.f3592f84.svg);
      background-repeat: no-repeat;
      background-size: 300%;
      width: 100%;
      height: 100%;
      opacity: 30%;
      background-position: -90px 0;
      filter: blur(8px);
      -webkit-filter: blur(8px);
      position: absolute;
      top: 0;
    }

    .text-wrapper {
      z-index: 9;
      margin: 10px; /* for demo purpose  */
      color: white;
      font-family: 'Roboto', sans-serif;
      height: 80%;
      padding: 0 20px;

      .title {
        font-size: 20px;
        font-weight: 600;
      }

      .discount {
        font-size: 28px;
        font-weight: 600;
      }

      .dates {
        border-top: 1px solid rgba(255, 255, 255, 0.6);
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        padding: 5px 0;
        margin: 5px 0;
      }

      .small {
        font-size: 12px;
      }
    }

    .choose {
      position: absolute;
      width: 60px;
      height: 15%;
      margin: auto;
      border-top-right-radius: 60% 100%;
      border-top-left-radius: 60% 100%;
      background-color: white;
      bottom: 0;
      left: 35%;

      img {
        left: 0;
      }

      &:hover {
        background-color: #f7d1f9;

        img {
          filter: invert(15%) sepia(93%) saturate(6382%) hue-rotate(295deg) brightness(84%) contrast(108%);
        }
      }
    }

  }
}

@media all and (max-width: $sm) {
  .discounts-container {
    .discount-wrapper {
      background-color: white;
      width: 180px;
      height: 180px;


      .text-wrapper {
        .title {
          font-size: 16px;
          font-weight: 600;
        }

        .discount {
          font-size: 16px;
          font-weight: 500;
        }

        .small {
          font-size: 12px;
        }
      }

      .choose {
        width: 60px;
        height: 15%;
        margin: auto;
      }
    }
  }
}

@media all and (max-width: 440px) {
  .discounts-container{
    top: 50%;
  }
}

@media all and (max-width: 650px) {

}
</style>
