export default {
  data: () => {
    return {
      loaderActive: false,
    }
  },
  methods: {
    showLoader () {
      this.loaderActive = true;
      console.log("SHOW LOADER");
    },
    hideLoader () {
      this.loaderActive = false;
      console.log("HIDE LOADER");

    },
  }
}
