<template>
  <div class="middle-banner">
    <div class="logo">
      <img :src="`${uploads}/assets/img/preonow-logo.svg`" alt="Preonow"/>
    </div>

    <div class="middle-banner-text">
      <div class="text">
        <span class="large-text" style="color: white">превръща <br/></span>
        <span class="small-text" style="color: white">пазаруването в
            <span class="large-text" style="color: #fa7269">спестяване</span></span>

        <img :src="`${uploads}/assets/img/home/middle-banner.svg`" alt="banner"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: [],
  components: {},
  computed: {
    ...mapGetters([]),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
  },
  data() {
    return {}
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';

.middle-banner {
  font-family: 'Comfortaa', cursive;
  height: 150px;
  display: flex;

  .logo {
    width: 400px;
    border-top-right-radius: 25% 99%;
    background-color: white;
    z-index: 1;
    display: flex;

    img {
      display: block;
      margin: auto 5px;
      height: 70px;
    }
  }

  .middle-banner-text {
    flex-basis: 100%;
    background-color: #3e24a3;
    margin-left: -150px;
    position: relative;

    img {
      position: absolute;
      right: 20px;
      top: 10px;
    }

    .text {
      display: grid;
      margin-left: 180px;
      margin-top: 35px;

      .large-text {
        font-size: 38px;
      }

      .small-text {
        font-size: 28px;
      }
    }
  }
}

@media screen and (max-width: $l) {
  .middle-banner {
    .logo {
      width: 300px;
    }

    .middle-banner-text {
      img {
        width: 200px;
        top: 50px;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .middle-banner {
    height: 120px;
    display: flex;

    .logo {
      width: 250px;

      img {
        height: 60px;
      }
    }

    .middle-banner-text {
      .text {
        .large-text {
          font-size: 32px;
        }

        .small-text {
          font-size: 22px;
        }
      }

      img {
        height: 130px;
        margin-top: 20px;
        right: 5px;
      }
    }
  }
}

@media screen and (max-width: $standard) {
  .middle-banner {
    .middle-banner-text {
      .text {
        .large-text {
          font-size: 26px;
        }

        .small-text {
          font-size: 18px;
        }
      }

      img {
        height: 110px;
        margin-top: 0;
        right: 5px;
      }
    }
  }
}

@media screen and (max-width: $s) {
  .middle-banner {
    height: 120px;
    display: flex;

    .logo {
      border-top-right-radius: 60% 100%;
      width: 150px;

      img {
        height: 40px;
      }
    }

    .middle-banner-text {
      .text {
        margin-left: 155px;

        .large-text {
          font-size: 22px;
        }

        .small-text {
          font-size: 16px;
        }

        img {
          height: 100px;
          margin-top: 0;
          right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 630px) {
  .middle-banner {
    height: 220px;
    width: 100%;

    .logo {
      position: absolute;
      border-bottom-right-radius: 190% 200%;
      border-top-right-radius: 25% 0;
      height: 120px;
      width: 190px;


      img {
        height: 45px;
        margin-top: 30px;
        margin-left: 20px;
      }
    }

    .middle-banner-text {
      width: 100%;
      margin-left: 0;

      .text {
        margin-left: 20px;
        margin-top: 145px;

        .small-text {
          font-size: 24px;
        }

        .large-text {
          font-size: 30px;
        }

        img {
          height: 170px;
          margin-top: -40px;
          right: 15px;
        }
      }

      img {
        height: 250px;
      }
    }
  }
}

@media screen and (max-width: $xs) {
  .middle-banner {
    height: 150px;

    .logo {
      border-bottom-right-radius: 300% 300%;
      height: 90px;
      width: 145px;

      img {
        width: 90px;
        margin-top: 15px;
        margin-left: 10px;
      }
    }

    .middle-banner-text {
      .text {
        margin-left: 20px;
        margin-top: 95px;
        .small-text {
          font-size: 12px;
        }
        .large-text {
          font-size: 20px;
        }

        img {
          margin-right: -15px;
          height: 100px;
        }
      }
    }
  }
}
</style>
