<template>
  <div id="app">
    <loader :active="loaderActive" message="Намираме най-добрите оферти за вас..." />
    <Header v-if="!currentUser.employee"/>
    <Tabs  v-if="!currentUser.employee"/>
    <Navigation  v-if="$route.name === 'Home' || isMobile"/>
    <MobileNav />
    <router-view />
    <vue-snotify />
    <Footer v-if="!currentUser.employee"/>
    <ConfirmPreoPayment />
    <GiftModalActions />
    <Cookies />
    <Auth />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import ConfirmPreoPayment from "@/views/user/shared/ConfirmPreoPayment.vue";
import GiftModalActions from "@/views/product/GiftModalActions";
import Auth from "@/views/auth/Auth";
import paymentQueries from "@/mixins/paymentQueries";
import { mapGetters } from "vuex";
import Cookies from "@/components/Cookies";
import Loader from "@/components/Loader.vue";
import loaderMixin from "@/mixins/loader";
import Navigation from "@/components/navigation/Navigation.vue";
import Tabs from "@/components/navigation/Tabs.vue";
import MobileNav from "@/components/MobileNav.vue";

export default {
  mixins: [paymentQueries,loaderMixin],
  computed: {
    ...mapGetters(["isAuthenticated", "currentUser", "isMobile" , 'carouselStore']),
  },
  watch: {
    $route(to) {
      this.$store.dispatch("error", null);
      if (this.isAuthenticated) {
        this.paymentQueries(to);
      }
    },
    "$route.name"() {
      window.scrollTo(0, 0);
    },
  },
  components: {
    MobileNav,
    Tabs,
    Navigation,
    Header,
    Footer,
    ConfirmPreoPayment,
    GiftModalActions,
    Cookies,
    Auth,
    Loader
  },
  async mounted() {
    await this.$store.dispatch("setIsMobile");
    await this.$store.dispatch("getCart");
    await this.$store.dispatch('getCategories')
    setInterval(() => {
      this.$store.dispatch("now");
    }, 1000);
    window.addEventListener('resize', () => {
      this.$store.dispatch("setIsMobile");
    })
  },
};
</script>

<style lang="scss">
@import "@/scss/base.scss";
@import "~vue-snotify/styles/material";
@import "@/scss/snotify.scss";

@font-face {
  font-family: "Nunito";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: "Nunito";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Nunito";
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "icons";
  src: url("/fonts/icons.eot");
  src: url("/fonts/icons.eot?#iefix") format("embedded-opentype"),
  url("/fonts/icons.woff") format("woff"),
  url("/fonts/icons.ttf") format("truetype"),
  url("/fonts/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class*="icon-"]:before {
  display: inline-block;
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icons {
  font-family: "icons";
}
html {
  box-sizing: border-box;
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

body {
  margin: 0;
  min-width: 320px;
  padding: 0;

  &.noscroll {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: url('assets/img/cursor.png'), auto;

  &.link {
    color: $mainAccent;
    text-decoration: underline;
    @include hover();
  }
}

input,
textarea,
button {
  font-family: Nunito, Helvetica, Arial, sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.accent {
  color: #ef5656;
}

.error {
  color: $mainError;
}

.center {
  text-align: center;
}
.right {
  text-align: right;
}

.icons-button {
  border-radius: 50%;
  color: $mainPurple;
  cursor: url('assets/img/cursor.png'), auto;
  font-family: "icons";
  font-size: 30px;
  @include tr();
  @include noselect();

  &:hover {
    color: $lightAccent;
  }

  &.icons-button--remove {
    color: $mainRemove;
    &:hover {
      color: $hoverRemove;
    }
  }
}

.icons--prev,
.icons--next {
  border-radius: 50%;
  color: #fff;
  cursor: url('assets/img/cursor.png'), auto;
  display: flex;
  filter: drop-shadow(0px 0px 19px #00000080);
  font-size: 48px;
  @include tr();
  @include noselect();
  &:hover {
    color: rgb(245, 245, 245);
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

@import "@/scss/input.scss";
@import "@/scss/mini-nav.scss";
@import "@/scss/product.scss";
@import "@/scss/price.scss";
@import "@/scss/wide-title.scss";
@import "@/scss/sqr.scss";
@import "@/scss/remaining.scss";
@import "@/scss/user__header.scss";
@import "@/scss/modal.scss";

@media screen and (max-width: 400px) {
  .icons--prev,
  .icons--next {
    font-size: 30px;
    filter: drop-shadow(0px 0px 19px #000000);
  }
}
</style>
