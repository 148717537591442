<template>
  <div class="container">
  <div v-if="$route.name !== 'Invoice'" class="nav">
    <div
      class="nav__content"
      v-if="$route.name !== 'employeePaymentRequest' && !mobileOnDesktop"
    >
      <router-link to="/" class="nav__logo">
        <img :src="`${uploads}/assets/img/preonow-logo.svg`" fetchpriority="high" alt="Preonow" />
      </router-link>

      <Search class="nav__search"/>

      <div class="nav__links">
        <UserMenu :getTotalCount="getTotalCount" :getTotalCountAdmin="getTotalCountAdmin" :notify="notify" :hideText="hideText"/>
        <FavouriteProducts :hideText="hideText"/>
        <Cart :hideText="hideText"/>
      </div>
    </div>
    <div
      class="nav--mobile__content"
      :class="{
        'nav--mobile__content--visible-on-desktop':
          $route.name === 'employeePaymentRequest' || mobileOnDesktop || isMobile,
        'nav--mobile__content--light':
          $route.name === 'employeePaymentRequest' || light,
      }"
    >
      <div class="header-wrapper">
      <div class="menu">
        <button v-on:click="handleMenu"><img
          :src="`${uploads}/assets/img/nav/mobile/menu-icon.svg`"
          alt="Menu"/>
        </button>
      </div>
      <!-- LOGO -->
      <router-link to="/" class="nav__logo">
        <img
          :src="`${uploads}/assets/img/preonow-logo.svg`"
          alt="Preonow"
          v-if="$route.name === 'employeePaymentRequest' || light"
        />
        <img :src="`${uploads}/assets/img/preonow-logo.svg`" alt="Preonow" v-else/>
      </router-link>

        <Search class="nav__search"/>

        <div class="options">
          <div class="logged-status">
            <template v-if="isAuthenticated">
              <!-- ICON FOR CLIENT/SELLER/ADMIN -->
              <div
                class="bell-notifications"
                @click="isNotificationModalOpen = true"
                v-if="
              !currentUser.employee && getTotalCount
            "
              >
                <p :class="{ threeDigits: getTotalCount >= 100 }">
                  {{ getTotalCount }}
                </p>
                <img :src="`${uploads}/assets/img/header/notif.svg`" alt=""/>
              </div>

              <img
                class="bell-notifications"
                v-else-if="!currentUser.employee && !getTotalCount"
                :src="`${uploads}/assets//img/header/nonotif.svg`"
                alt=""
              />

              <p v-if="currentUser.role === 'client'" @click="openProfil = true">
                <img class="login-icon" :src="`${uploads}/assets/img/header/login-icon.svg`" alt=""/>
              </p>
              <p
                @click="openProfil = true"
                v-else-if="currentUser.role === 'seller' && !currentUser.employee"
                class="icons-button"
              >
                <img class="login-icon user" :src="`${uploads}/assets/img/header/login-icon.svg`" alt=""/>
              </p>
              <p
                @click="openProfil = true"
                v-else-if="currentUser.role === 'admin'"
              >
                <img  class="login-icon" :src="`${uploads}/assets/img/header/login-icon.svg`" alt=""/>
              </p>
            </template>
            <div @click="openProfil=true" v-else>
              <img  class="login-icon" :src="`${uploads}/assets/img/header/login-icon.svg`" alt=""/>
            </div>
          </div>

          <FavouriteProducts :isMobile="isMobile" :hideText="hideText"/>
          <Cart :isMobile="isMobile" :hideText="hideText"/>
        </div>
      </div>
    </div>
    <UserMenu v-if="openProfil" inNav="true" @close="openProfil = false" />

    <MobileNotificationsModal
      v-if="isNotificationModalOpen"
      @close="isNotificationModalOpen = false"
      :notify="notify"
      :currentUser="currentUser"
    />

    <MobileDropdown
      class="mobile-dropdown"
      :currentUser="currentUser"
      :isMobileOpen="isMobileOpen"
      :uploads="uploads"
      @close="isMobileOpen = false"
    />
  </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MobileDropdown from "./MobileDropdown";
import MobileNotificationsModal from "./MobileNotificationsModal";
import Search from "../views/home/Search";
import UserMenu from "./UserMenu";
import FavouriteProducts from "@/components/FavouriteProducts.vue";
import Cart from "@/components/Cart.vue";
export default {
  components: {
    Cart,
    FavouriteProducts,
    MobileDropdown,
    MobileNotificationsModal,
    Search,
    UserMenu,
  },
  props: ["mobileOnDesktop", "light"],
  computed: {
    ...mapGetters(["isAuthenticated", "currentUser", "isMobile"]),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
    getTotalCount() {
      const result = Object.values(this.notify);
      let count=0;

      for(let num of result){
        if(num > 0){
          count++;
        }
      }
      return count;
    },

    getTotalCountAdmin(){
      const result = Object.values(this.notify).reduce((a,b)=>a+b);
      return result;
    }
  },
  data() {
    return {
      isMobileOpen: false,
      isNotificationModalOpen: false,
      loading: true,
      openProfil:false,
      notSeenNotifications: 0,
      notify: {
        // Clients
        notifyProductServicesClient: 0,
        notifyAuctionsClient: 0,
        notifyRevAuctionsClient: 0,
        preoPaymentNotifications: 0,
        // Seller
        notifyProductServicesSeller: 0,
        notifyMicrowalletsSeller: 0,
        notifyAuctionsSeller: 0,
        notifyRevAuctionsSeller: 0,
        notifyB2BSeller: 0,
        // Seller as client
        sellerAsClientNotifyProductServices: 0,
        preoPaymentNotificationssellerAsClient: 0,
        sellerAsClientNotifyAuctions: 0,
        sellerAsClientNotifyRevAuctions: 0,
        sellerAsClientNotifyB2B: 0,
        // Admin
        nsProductService: 0,
        nsMicrowallets: 0,
        nsAuctions: 0,
        nsReverseAuctions: 0,
        nsB2B: 0,
        sellersCount: 0,
      },
      hideText: false
    };
  },
  watch: {
    $route: {
      handler: "getNotifications",
      immediate: true,
    },
  },
  methods: {
    getNotifications() {
      this.orders = [];
      this.notify={
        // Clients
        notifyProductServicesClient: 0,
        notifyAuctionsClient: 0,
        notifyRevAuctionsClient: 0,
        preoPaymentNotifications: 0,
        // Seller
        notifyProductServicesSeller: 0,
        notifyMicrowalletsSeller: 0,
        notifyAuctionsSeller: 0,
        notifyRevAuctionsSeller: 0,
        notifyB2BSeller: 0,
        // Seller as client
        sellerAsClientNotifyProductServices: 0,
        preoPaymentNotificationssellerAsClient: 0,
        sellerAsClientNotifyAuctions: 0,
        sellerAsClientNotifyRevAuctions: 0,
        sellerAsClientNotifyB2B: 0,
        // Admin
        nsProductService: 0,
        nsMicrowallets: 0,
        nsAuctions: 0,
        nsReverseAuctions: 0,
        nsB2B: 0,
        sellersCount: 0,};
      let orderTypes = [
        "product-service",
        "microwallet",
        "auction",
        "reversed-auction",
        "b2b",
      ];

      // Clients notifications
      if (this.currentUser.role === "client") {
        orderTypes.forEach((ot) => {
          this.$apiService
            .get(
              `/orders/not-seen/client/${this.currentUser._id}?productType=${ot}`
            )
            .then((res) => {
              this.notSeenNotifications = res.data || 0;
              if (ot === "product-service") {
                // Unseen cancelled orders from CLIENT
                this.$apiService
                  .get(
                    `/orders/not-seen-product-statuses?userRole=clientOrders`
                  )
                  .then((res) => {
                    this.notSeenNotifications = res.data || 0;
                    this.notify.notifyProductServicesClient =
                      this.notSeenNotifications;
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
              if (ot === "microwallet") {
                // Microwallets/preo-payments unseen count notifications
                this.$apiService
                  .get(`/orders/not-seen-microwallet`)
                  .then((res) => {
                    this.notify.preoPaymentNotifications = res.data || 0;
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
              if (ot === "auction") {
                this.notify.notifyAuctionsClient = this.notSeenNotifications;
                this.totalNotifications += this.notify.notifyAuctionsClient;
              }
              if (ot === "reversed-auction") {
                this.notify.notifyRevAuctionsClient = this.notSeenNotifications;
                this.totalNotifications += this.notify.notifyRevAuctionsClient;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }

      //  Sellers notifications
      if (this.currentUser.role === "seller") {
        // Sellers notifications - for orders from clients
        orderTypes.forEach((ot) => {
          this.$apiService
            .get(
              `/orders/not-seen/seller/${this.currentUser._id}?productType=${ot}`
            )
            .then((res) => {
              this.notSeenNotifications = res.data || 0;
              if (ot === "product-service") {
                this.notify.notifyProductServicesSeller =
                  this.notSeenNotifications;
              }
              if (ot === "microwallet") {
                this.notify.notifyMicrowalletsSeller =
                  this.notSeenNotifications;
              }
              if (ot === "auction") {
                this.notify.notifyAuctionsSeller = this.notSeenNotifications;
              }
              if (ot === "reversed-auction") {
                this.notify.notifyRevAuctionsSeller = this.notSeenNotifications;
              }
              if (ot === "b2b") {
                this.notify.notifyB2BSeller = this.notSeenNotifications;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });

        // Sellers as client notifications
        orderTypes.forEach((ot) => {
          this.$apiService
            .get(
              `/orders/not-seen/sellerAsClient/${this.currentUser._id}?productType=${ot}`
            )
            .then((res) => {
              this.notSeenNotifications = res.data || 0;
              if (ot === "product-service") {
                // Unseen cancelled orders from CLIENT
                this.$apiService
                  .get(
                    `/orders/not-seen-product-statuses?userRole=sellerAsClientOrders`
                  )
                  .then((res) => {
                    this.notSeenNotifications = res.data || 0;
                    this.notify.sellerAsClientNotifyProductServices =
                      this.notSeenNotifications;
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
              if (ot === "microwallet") {
                // Microwallets/preo-payments unseen count notifications
                this.$apiService
                  .get(`/orders/not-seen-microwallet`)
                  .then((res) => {
                    this.notSeenNotifications = res.data || 0;
                    this.notify.preoPaymentNotificationssellerAsClient =
                      res.data || 0;
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
              if (ot === "auction") {
                this.notify.sellerAsClientNotifyAuctions =
                  this.notSeenNotifications;
              }
              if (ot === "reversed-auction") {
                this.notify.sellerAsClientNotifyRevAuctions =
                  this.notSeenNotifications;
              }
              if (ot === "b2b") {
                this.notify.sellerAsClientNotifyB2B = this.notSeenNotifications;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }

      // Admin notifications
      if (this.currentUser.role === "admin") {
        // Users (only sellers for now) count notifications
        this.$apiService
          .get(`/users/inactive`)
          .then((res) => {
            this.notify.sellersCount = res.data || 0;
            this.totalNotifications += this.notify.sellersCount;
          })
          .catch((err) => {
            console.log(err);
          });

        orderTypes.forEach((product) => {
          this.$apiService
            .get(`/products/inactive/${product}`)
            .then((res) => {
              if (product === "product-service") {
                this.notify.nsProductService = res.data || 0;
                this.totalNotifications += this.notify.nsProductService;
              }
              if (product === "microwallet") {
                this.notify.nsMicrowallets = res.data || 0;
                this.totalNotifications += this.notify.nsMicrowallets;
              }
              if (product === "auction") {
                this.notify.nsAuctions = res.data || 0;
                this.totalNotifications += this.notify.nsAuctions;
              }
              if (product === "reversed-auction") {
                this.notify.nsReverseAuctions = res.data || 0;
                this.totalNotifications += this.notify.nsReverseAuctions;
              }
              if (product === "b2b") {
                this.notify.nsB2B = res.data || 0;
                this.totalNotifications += this.notify.nsB2B;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }
    },
      handleResize(){
      this.hideText = window.innerWidth <= 1280;
    },
    handleMenu(){
      this.$store.dispatch('handleMenu')
    }
  },mounted() {
    this.handleResize();
    window.addEventListener('resize', () => {
      this.handleResize();
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.nav {
  display: flex;
  height: $nav;
}

.nav__content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 100%;
  max-width: $hd;
  padding: 0 ;
  width: 100%;
}

.nav__logo {
  flex-basis: 10%;
  img {
    display: block;
    height: 50px;
    @include tr();
    &:hover{
      transform: scale(0.95);
    }
  }
}
.nav__search{
  flex-basis: 33%;
  position: relative;
  z-index: 2;
}
.nav__links {
  align-items: center;
  display: flex;
  font-weight: 700;
  //width: 650px;
  justify-content: flex-end;
  @include noselect();
  a {
    align-items: center;
    background-color: transparent;
    border-radius: 50px;
    cursor: pointer;
    display: inline-flex;
    padding: 12px 10px;
    margin-right: 10px;
    @include tr();
    .icons {
      font-size: $px16;
      margin-right: 0.5rem;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: #efefef;
    }
    &.black {
      background-color: #000;
      color: #fff;
      white-space: nowrap;
      &:hover {
        background-color: $mainPurple;
      }
    }
  }
}

.nav__links-logged {
  margin-right: 10px;
  position: relative;
  a {
    margin-right: auto;
    cursor: pointer;
    .mail-box-dropdown {
      font-size: $px30;
      font-weight: normal;
      margin-right: 0.5rem;
    }
  }
}

.nav--mobile__content {
  align-items: center;
  color: $mainAccent;
  display: flex;
  height: 50px;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  &:not(.nav--mobile__content--visible-on-desktop) {
    display: none;
  }
  &.nav--mobile__content--light {
    color: #fff;
    .circle-count {
      border: 1px solid #fff;
    }
  }
  .black {
    background-color: #000;
    color: #fff;
    padding: 15px 35px 15px 35px;
    font-weight: 700;
    font-size: 14px;
    border-radius: 50px;
  }
  .home-dropdown--wrapper {
    flex-basis: 0;
    flex-grow: 1;
    .home-dropdown {
      font-size: 22px;
      @include hover();
    }
  }

  .mail-box-dropdown {
    font-size: 35px;
  }

  .options{
    display: flex;
    gap: 15px;
  }
  .logged-status {
    align-items: center;
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    height: 100%;
    justify-content: flex-end;
    > .icons {
      font-size: 22.4px;
      margin-left: 6px;
    }
    .icons-button{
      height: 27px;
      object-fit: scale-down;
      width: 28px;
    }
  }

    .bell-notifications{
        position: relative;
        margin-right: 10px;
        width: 30px;
      p{
        color: white;
        position: absolute;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 11px;
        font-size: 12px;
        z-index: 1;
        &.threeDigits{
          font-size: 10px;
          top: 25%;
        }
      }
    }

  .seller-cover {
    display: inline-flex;
    font-size: 10px;
    margin-left: 6px;
    img {
      border-radius: 50%;
      box-shadow: 0px 0px 20px #bdc7ca75;
      height: 22px;
      object-fit: cover;
      width: 22px;
    }
  }

  .nav__logo {
    img {
      display: block;
      height: 48px;
    }
  }
  .header-wrapper{
    display: flex;
    width: 100%;
  }
}

@media screen and (max-width: $xl) {
  .nav__search{
    flex-basis: 37%;
  }
}
@media screen and (max-width: $sm) {
  .nav__links{
    gap: 15px;
  }
  .nav__logo img {
      height: 40px;
  }
  .nav__search{
    flex-basis: 40%;
  }
}

@media screen and (max-width: $standard) {
  .nav__content{
    display: none;
  }
  .nav__search{
    flex-basis: 52%;
    margin: auto;
  }
  .nav__logo{
    width: 150px;
    margin-right: 10px;
    margin-top: 5px;
  }
  .nav{
    height: 80px;
  }

  .logged-status {
    p{
      margin-right: 0;
    }
    img {
      width: 24px;
      margin-right: 10px;
      margin-top: 3px;
      //width: 100%;
    }
  }
  .menu {
    width: 40px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
    button{
      border: none;
      background-color: transparent;
      &:hover{
        background-color: transparent;
        filter: invert(17%) sepia(100%) saturate(7480%) hue-rotate(297deg) brightness(89%) contrast(102%);
      }
    }
    img{
      width: 30px;
    }
  }
  .icons-button{
    font-size: 24px;
  }

  .nav--mobile__content {
    padding-right: 10px;
  }
}

@media screen and (max-width: $s) {
  .nav{
    height: 100px;
  }
  .nav--mobile__content {
    display: flex;
    margin-top: 2px;
    width: 100%;
    position: relative;

    .nav__search {
      position: absolute;
      margin-top: 50px;
      width: 100%;
      max-width: 100%;
    }
    .nav__logo {
      width: 150px;
      margin-right: 10px;
      margin-top: 1px;

      img {
        height: 38px;
      }
    }
    .options{
      width: 90%;
      justify-content: end;
    }
    .bell-notifications{
      margin-right: 5px;
      p{
        font-size: 10px;
        margin-left: 0;
        margin-top: 4px;
      }
    }
    .logged-status {
      flex-basis: 36%;
      flex-grow: 0;
      p {
        margin-right: 7px;
        width: 25px;
        top: 3px;
      }
      .login-icon{
        width: 32px;
      }
      .user{
        padding: 0;
        margin-top: -2px;
        margin-right: 0px;
        height: 28px;
      }
      img {
        padding-bottom: 0;
        margin-right: 10px;
        height: 24px;
        //width: 100%;

      }

    }
    .menu {
      width: 40px;
      img {
        width: 30px;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .nav{
    height: 90px;
  }
  .nav--mobile__content {
    display: flex;
    margin-top: 7px;
    height: 40px;
    width: 100%;
    position: relative;
    .nav__search {
      position: absolute;
      margin-top: 40px;
      width: 100%;
      max-width: 100%;
    }
    .nav__logo {
      width: 90px;
      margin-right: 10px;
      margin-top: 1px;
      img {
        //height: 34px;
      }
    }
    .nav {
      height: 100px;
    }
    .options{
      width: 90%;
      justify-content: end;
    }
    .bell-notifications{
      margin-right: 0;
      p{
        //font-size: 10px;
        //margin-left: 2px;
        //margin-top: 1px;
      }
    }
    .logged-status {
      flex-basis: 36%;
      flex-grow: 0;
      p {
        margin-right: 0;
        width: 25px;
      }
      img {
        //padding: 5px;
        //padding-bottom: 0px;
        //width: 28px;
        //margin-right: 10px;
        //width: 100%;
      }
      .login-icon{
        //width: 28px;
      }
    }
    .menu {
      width: 30px;
    }
  }
}
</style>
