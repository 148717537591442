<template>
  <div
    class="input-wrapper input-wrapper--dropdown tooltip-visible"
    :class="{
      'input-wrapper--invalid': invalid,
      'input-wrapper--disabled': disabled,
      'input-wrapper--up': up,
      'input-wrapper--arrow': arrowLeft,
      'input-wrapper--auth': auth,
    }"
    v-click-outside="hideIfOpen"
  >
    <label v-if="label">
      {{ label }}
    </label>
    <div class="input" @click="disabled ? null : (open = !open)">
      <input
        :autocomplete="autocomplete"
        :value="getValue"
        :type="type || 'text'"
        :placeholder="placeholder"
        :disabled="typing ? false : true"
        @input="$emit('input', $event.target.value)"
      />
    </div>
    <span class="icons left" v-if="icon">{{ icon }} </span>
    <span
      class="icons arrow"
      :class="{ active: open }"
      @click="disabled ? null : (open = !open)"
      >c
    </span>
    <div class="input-wrapper__dropdown" v-if="open">
      <template v-if="multi && all">
        <p
          class="center"
          v-if="value.length !== list.length"
          @click="selectAll()"
        >
          <strong>Избери всички</strong>
        </p>
        <p class="center" v-else @click="deselectAll()">
          <strong>Изчисти всички</strong>
        </p>
      </template>
      <template v-if="multi && value.length">
        <p class="center">Избрано</p>
        <p
          v-for="(l, i) in getSelected"
          class="selected"
          :class="{ 'disabled-item': disabledItem ? disabledItem(l) : false }"
          :key="`list-item-selected-${i}`"
          @click.stop="select(l)"
        >
          <template v-if="!l">{{ placeholder || 'Изчисти' }}</template>
          <template v-else>
            {{ prop ? l[prop] : l
            }}<template class="p--additional" v-if="additionalProps"
              >, {{ l[additionalProps] }}</template
            >
          </template>
        </p>
        <p class="center" v-if="getList.length">За избиране</p>
      </template>
      <p
        v-for="(l, i) in getList"
        :class="{ 'disabled-item': disabledItem ? disabledItem(l) : false }"
        :key="`list-item-${i}`"
        @click.stop="select(l)"
      >
        <template v-if="!l">{{ placeholder || 'Изчисти' }}</template>
        <template v-else>
          {{ prop ? l[prop] : l }}
          <template class="p--additional" v-if="additionalProps">
            <template v-for="p in additionalProps">
              {{ l[p] ? `, ${l[p]}` : '' }}
            </template>
          </template>
        </template>
      </p>
    </div>
  </div>
</template>

<script>
    import ClickOutside from 'vue-click-outside';
    export default {
        props: [
            'all', // add select/deselect all
            'value',
            'type',
            'placeholder',
            'label',
            'disabled',
            'disabledItem',
            'invalid',
            'filter', // when dd is multiselect and data is array of objects we filter them by this prop
            'list',
            'multi',
            'typing', // allow typing
            'prop', // if list of objects, displaying the prop as a name
            'additionalProps', //
            'up',
            'arrowLeft',
            'auth',
            'icon',
            'autocomplete',
            'shouldSort',
        ],
        directives: {
            ClickOutside,
        },
        computed: {
            getValue () {
                if (this.multi) {
                    if (this.type === 'object') {
                        // working with objects
                        let values = [];
                        this.value.forEach((object) => {
                            values.push(object[this.prop]);
                        });
                        return values ? values.join(', ') : '';
                    } else {
                        // working with primitive types
                        return this.value ? this.value.join(', ') : '';
                    }
                } else {
                    let value = '';

                    if (this.prop && this.value) {
                        if (this.value[this.prop]) value = this.value[this.prop];
                    } else {
                        if (this.value) value = this.value;
                    }

                    if (this.additionalProps) {
                        for (let p of this.additionalProps) {
                            if (this.value ? this.value[p] : false) {
                                value += `, ${this.value[p]}`;
                            }
                        }
                    }
                    return value;
                }
            },
            getSelected () {
                const output = this.value ? this.value.slice() : [];
                return this.shouldSort ? output.sort() : output
            },
            getList () {
                if (this.multi) {
                    return this.list.filter((l) => {
                        if (this.type === 'object' && this.filter) {
                            return (
                                this.value.map((v) => v[this.filter]).indexOf(l[this.filter]) < 0
                            );
                        } else {
                            return this.value.indexOf(l) < 0;
                        }
                    });
                } else {
                    return this.list;
                }
            },
        },
        data () {
            return {
                open: false,
                allLabel: 'Всички градове',
            };
        },
        methods: {
            select (l) {
                if (this.disabledItem ? !this.disabledItem(l) : true) {
                    if (this.multi) {
                        let output = this.value ? this.value.slice() : [];
                        if (output.length === 1 && output[0] === this.allLabel) {
                            output = []
                        }
                        const i = output.indexOf(l);
                        if (i < 0) output.push(l);
                        else output.splice(i, 1);
                        this.$emit('input', output);
                    } else {
                        this.$emit('input', l);
                        this.open = false;
                    }
                }
            },
            selectAll () {
                this.$emit('input', [this.allLabel]);
            },
            deselectAll () {
                this.$emit('input', []);
            },
            hideIfOpen () {
                if (this.open) {
                    this.open = false;
                }
            }
        },
    };
</script>

<style lang="scss">
    @import '@/scss/base.scss';
</style>
