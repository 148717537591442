<template>
  <div class="categories-container">
    <div v-for="(item, i) in categoryData" :key="`category-${i}`">
      <div v-if="item.productCount" :class="{'subcategory' : isSubcategory, 'category' : !isSubcategory}">
        <div v-on:click="activeCategory = activeCategory === i ? '' : i">
          <router-link
            :to="{path: 'products', query: { search: '', categories: item.id }}"
            @click.native="closeMenu"
          >
            {{ item.label }}
            <span v-if="(item.productCount)" class="has-products">
          ({{ item.productCount }})
        </span>
          </router-link>
          <img v-if="isMobile && !isSubcategory && item.children?.length > 0"
               class="category-arrow-cat"
               :class="activeCategory === i && 'menu-open'"
               :src="`${uploads}/assets/img/arrow-new.svg`">
        </div>
        <CategoryTree v-if="item.children?.length > 0 && (!isMobile||(isMobile && activeCategory === i))"
                      :categoryData="item.children"
                      :isSubcategory="true"
        >
        </CategoryTree>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name:"CategoryTree",
  props: ["categoryData", "isSubcategory"],
  computed: {
    ...mapGetters(["isMobile"]),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
  },
  data() {
    return {
      activeCategory: null
    };
  },
  methods:{
    closeMenu(){
      this.$store.dispatch('handleMenu')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.categories-container{
  div{
    font-size: 16px;
  }
}
.category{
  color: #350887;
}
.category:not(:first-child){
  margin-top: 30px;
}
.subcategory{
  margin: 10px 0;
  color: #707070;
}

a:hover{
  color: #b100b2;
}
@media all and (max-width: $l){
  .categories-container{
    div{
      font-size: 14px;
    }
  }
}

@media all and (max-width: $sm){
  .categories-container{
    div{
      font-size: 12px;
    }
  }
  .category:not(:first-child){
    margin-top: 10px;
  }
  .subcategory{
    margin: 5px 0;
    color: #707070;
  }
}

@media all and (max-width: $standard){
  .categories-container{
    div{
      font-size: 14px;
    }
  }
  .category:not(:first-child){
    margin-top: 10px;
  }
  .subcategory{
    margin: 10px 0;
    color: #707070;
  }
  .category-arrow-cat{
    margin-top: 8px;
    transform: translate(0, -50%) rotate(180deg) scale(0.3);
    position: absolute;
    right: 0;
    filter: invert(42%) sepia(1%) saturate(0%) hue-rotate(216deg) brightness(93%) contrast(94%);
  }
  .menu-open{
    transform: translate(0, -50%) rotate(270deg) scale(0.3);
  }
}
</style>
