<template >
  <img fetchpriority="high" rel="preload" class="video-and-banner" :src="`${uploads}/assets/img/home/save-with-preo.webp`" alt="banner"/>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: [],
  components: {  },
  computed: {
    ...mapGetters([]),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
  },
  data() {
    return{}
  },
  watch: {},
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/banner-and-video.scss';
</style>
