<template>
  <div class="auth__register">
    <div class="auth__register__choose">
      <span
        v-if="user.role"
        class="icons sqr back"
        @click="(user.role = ''), $emit('close', false), clearForm()"
        >b
      </span>
      <p><strong>Изберете тип профил, с който да се регистрирате</strong></p>
    </div>
    <div class="auth__register__section" :class="{ active: user.role }">
      <div
        class="auth__register__toggle"
        :class="{
          'auth__register__toggle--client': user.role !== 'seller',
          'auth__register__toggle--seller': user.role === 'seller',
        }"
        @click="
          !user.role ? (user.role = 'client') : null,
            $emit('close', true),
            clearForm()
        "
      >
        <template v-if="user.role === 'seller'">
          <p><strong>Регистрация като юридическо лице</strong></p>
          <p>
            Като регистриран потребител ти ще имаш достъп до всички обяви за стоки, услуги, Preo ваучери и B2B обяви. В допълнение, имаш възможност да станеш част от нашите търговци, да предлагаш твоите стоки и услуги в preonow.bg
          </p>
        </template>
        <template v-else>
          <p><strong>Регистрация като физическо лице</strong></p>
          <p>
            Като регистриран потребител ти ще имаш достъп до най-добрите обяви на стоки, услуги и Preo ваучер в Preonow.bg
          </p>
        </template>
      </div>
      <form @submit.prevent v-show="user.role" ref="regform">
        <template v-if="user.role === 'seller'">
          <InputAuth
            placeholder="МОЛ *"
            icon="D"
            :invalid="invalid.mol"
            @input="invalid.mol = false"
            v-model="user.company.mol"
          />
          <InputAuth
            placeholder="Име на ЮЛ *"
            icon="M"
            :invalid="invalid.name"
            @input="invalid.name = false"
            v-model="user.company.name"
          />
          <Dropdown
            placeholder="Държава на фирмена регистрация *"
            :arrowLeft="true"
            :auth="true"
            icon="4"
            v-model="user.company.countryOfRegistration"
            :list="countriesOfRegistration"
            :invalid="invalid.countryOfRegistration"
          />
          <InputAuth
            placeholder="ЕИК *"
            icon="A"
            :invalid="invalid.eik"
            @input="invalid.eik = false"
            v-model="user.company.eik"
          />
          <InputAuth
            placeholder="Имейл *"
            icon="B"
            :invalid="invalid.email"
            @input="invalid.email = false"
            v-model="user.email"
          />
          <InputAuth
            placeholder="Телефон *"
            icon="J"
            :invalid="invalid.phone"
            @input="invalid.phone = false"
            v-model="user.company.phone"
          />
        </template>
        <template v-else>
          <InputAuth
            placeholder="Име, Фамилия *"
            autocomplete="new-password"
            icon="D"
            :invalid="invalid.clientName"
            @input="invalid.clientName = false"
            v-model="user.name"
          />
          <InputAuth
            placeholder="Имейл *"
            autocomplete="new-password"
            icon="B"
            :invalid="invalid.email"
            @input="invalid.email = false"
            v-model="user.email"
          />
          <InputAuth
            placeholder="Телефон *"
            autocomplete="new-password"
            icon="J"
            :invalid="invalid.phone"
            @input="invalid.phone = false"
            v-model="user.phone"
          />
          <Dropdown
            placeholder="Пол"
            icon="3"
            autocomplete="new-password"
            v-model="user.gender"
            :list="genders"
            :arrowLeft="true"
            :auth="true"
          />
          <Dropdown
            placeholder="Година на раждане *"
            icon="4"
            autocomplete="new-password"
            :typing="true"
            :list="years"
            :arrowLeft="true"
            :auth="true"
            :invalid="invalid.birthYear"
            @input="invalid.birthYear = false"
            v-model="user.birthYear"
          />
        </template>
        <InputAuth
          placeholder="Парола *"
          autocomplete="new-password"
          type="password"
          icon="C"
          :invalid="invalid.password"
          @input="(invalid.password = false), (invalid.passwordRepeat = false)"
          v-model="user.password"
        />
        <InputAuth
          placeholder="Повтори Парола *"
          autocomplete="new-password"
          type="password"
          icon="C"
          :invalid="invalid.passwordRepeat"
          @input="(invalid.passwordRepeat = false), (invalid.password = false)"
          v-model="password"
        />
        <Dropdown
          placeholder="Таен въпрос *"
          icon="4"
          :list="secretQuestions"
          :arrowLeft="true"
          :auth="true"
          :invalid="invalid.secretQuestion"
          @input="invalid.secretQuestion = false"
          v-model="user.secretQuestion"
        />
        <InputAuth
          placeholder="Таен отговор *"
          icon="4"
          :invalid="invalid.secretAnswer"
          @input="invalid.secretAnswer = false"
          v-model="user.secretAnswer"
        />
        <p class="agreement">
          <Checkbox v-model="agreed" />
          <span>
            Запознах се с
            <a :href="`${legalUri}/Obshti-uslovia.pdf`" target="_blank"
              >Общите условия</a
            >
            и
            <a :href="`${legalUri}/Privacy-Policy.pdf`" target="_blank"
              >Политиката за поверителност</a
            >
            и ги приемам
          </span>
        </p>
        <Button @click="recaptcha">РЕГИСТРАЦИЯ</Button>
        <p class="shopWisely"><strong>Пазарувайте разумно!</strong></p>
        <p class="auth__register__error" v-if="error">{{ error }}</p>
      </form>
    </div>
    <!-- just a toggle  -->
    <div class="auth__register__section" v-if="!user.role">
      <div
        class="auth__register__toggle auth__register__toggle--client"
        v-if="user.role === 'seller'"
        @click="(user.role = 'client'), (error = '')"
      >
        <p><strong>Регистрация като физическо лице</strong></p>
        <p>
          Като регистриран потребител ти ще имаш достъп до най-добрите обяви на стоки, услуги и Preo ваучер в Preonow.bg
        </p>
      </div>
      <div
        class="auth__register__toggle auth__register__toggle--seller"
        v-else
        @click="
          (user.role = 'seller'),
            $emit('close', true),
            (error = ''),
            clearForm()
        "
      >
        <p><strong>Регистрация като юридическо лице</strong></p>
        <p>
          Като регистриран потребител ти ще имаш достъп до всички обяви за стоки, услуги, Preo ваучери и B2B обяви. В допълнение, имаш възможност да станеш част от нашите търговци, да предлагаш твоите стоки и услуги в preonow.bg
        </p>
      </div>
    </div>
  </div>
</template>

<script>
    import InputAuth from '@/components/InputAuth';
    import Button from '@/components/Button';
    import Checkbox from '@/components/Checkbox';
    import Activities from '@/views/user/seller/data/activities.json';
    import CountriesOfRegistration from '@/views/user/seller/data/CountriesOfRegistration.json'
    import Genders from '@/views/user/client/data/genders.json';
    import SecretQuestions from '@/views/user/client/data/secret-questions.json';
    import Dropdown from '@/components/Dropdown';
    import eikValidator from "../../utils/eikValidator";
    import utils from "@/common/utils";

    export default {
        computed: {
            staticUri () {
                return process.env.VUE_APP_STATIC_URI;
            },
          legalUri () {
                return utils.legalUri();
            },
            years () {
                const year = new Date().getFullYear();
                return Array.from(
                    { length: year - 1900 },
                    (value, index) => 1901 + index
                );
            },
            getCurrYear () {
                return Number(new Date().getFullYear());
            },
        },
        components: {
            InputAuth,
            Button,
            Checkbox,
            Dropdown,
        },
        data () {
            return {
                activities: Activities,
                countriesOfRegistration: CountriesOfRegistration,
                genders: Genders,
                secretQuestions: SecretQuestions,
                error: '',
                agreed: '',
                password: '',
                user: {
                    role: '',
                    name: '',
                    company: {},
                    field: '',
                    eik: '',
                    email: '',
                    phone: '',
                    password: '',
                    gender: '',
                    age: null,
                    birthYear: null,
                    secretQuestion: '',
                    secretAnswer: '',
                    token: ''
                },
                invalid: {
                    invalid: false,
                },
                done: false,
            };
        },
        watch: {
            'user.role' () {
                window.scrollTo(0, 0);
            },
        },
        methods: {
            async recaptcha () {
                // (optional) Wait until recaptcha has been loaded.
                await this.$recaptchaLoaded()
                const recaptcha = this.$recaptchaInstance
                recaptcha.showBadge();

                // Execute reCAPTCHA with action "login".
                const token = await this.$recaptcha('register');
                this.register(token)
                // Do stuff with the received token.
            },
            clearForm () {
                this.$refs.regform.reset();
            },
            isValid () {
                const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[_!@#\$%\^&\*])(?=.{8,})"); /* eslint-disable-line */

                if (this.user.role === 'seller') {
                    if (!this.user.company.mol) {
                        this.error = 'МОЛ е задължителен';
                        this.invalid.mol = true;
                        return false;
                    }
                    if (!this.user.company.name) {
                        this.error = 'Име на ЮЛ е задължително';
                        this.invalid.name = true;
                        return false;
                    }
                  if (this.user.role === 'seller' && !this.user.company.countryOfRegistration) {
                    this.error='Държава на фирмена регистрация е задължително!';
                    this.invalid.countryOfRegistration = true;
                    return false;
                  }

                  if (this.user.company.countryOfRegistration==='България') {
                    if (!this.user.company.eik) {
                      this.error = 'ЕИК/Булстат на ЮЛ е задължителен';
                      this.invalid.eik = true;
                      return false;
                    }

                    if (!/^\d+$/.test(this.user.company.eik)) {
                      this.error = 'ЕИК/Булстат на ЮЛ следва да съдържа само цифрови знаци';
                      this.invalid.eik = true;
                      return false;
                    }

                    if (this.user.company.eik.length < 9) {
                      this.error =
                        'ЕИК/Булстат на ЮЛ следва да бъде с минумум 9 цифрови знака';
                      this.invalid.eik = true;
                      return false;
                    }

                    if (!eikValidator.isValidEIK(this.user.company.eik)) {
                      this.error =
                        'ЕИК/Булстат не е валиден';
                      this.invalid.eik = true;
                      return false;
                    }
                  }

                } else {
                    if (!this.user.name) {
                        this.error = 'Името е задължително';
                        this.invalid.clientName = true;
                        return false;
                    }
                }
                if (!this.user.email) {
                    this.error = 'E-mail адресът е задължителен';
                    this.invalid.email = true;
                    return false;
                }
                if (this.user.role === 'seller') {
                    if (!this.user.company.phone) {
                        this.error = 'Телефонът е задължителен';
                        this.invalid.phone = true;
                        return false;
                    }
                }
                if (this.user.role === 'client') {
                    if (!this.user.phone) {
                        this.error = 'Телефонът е задължителен';
                        this.invalid.phone = true;
                        return false;
                    }
                    if (this.user.birthYear) {
                        if (this.getCurrYear - this.user.birthYear < 18) {
                            this.error =
                                'За да се регистрирате следва да сте навършили 18 години';
                            this.invalid.birthYear = true;
                            return false;
                        }

                        if (this.user.birthYear < 1901) {
                            this.error = 'Годината не може да бъде преди 1901 година';
                            this.invalid.birthYear = true;
                            return false;
                        }
                    }
                    if (!this.user.birthYear) {
                        this.error = 'Година на раждане е задължителна';
                        this.invalid.birthYear = true;
                        return false;
                    }
                }
                if (!this.user.password) {
                    this.error = 'Паролата е задължителна';
                    this.invalid.password = true;
                    return false;
                }
                if (!passwordRegex.test(this.user.password)) {
                    this.error = 'Парола трябва да съдържа главни и малки английски букви, поне един от следните символи: _+!@#$%^&* и да бъде поне 8 символа.';
                    this.invalid.password = true;
                    return false;
                }
                if (this.user.password !== this.password) {
                    this.error = 'Паролите не съвпадат';
                    this.invalid.password = true;
                    this.invalid.passwordRepeat = true;
                    return false;
                }
                if (!this.user.secretQuestion) {
                    this.error = 'Тайният въпрос е задължителен';
                    this.invalid.secretQuestions = true;
                    return false;
                }
                if (!this.user.secretAnswer) {
                    this.error = 'Тайният отговор е задължителен';
                    this.invalid.secretAnswer = true;
                    return false;
                }
                if (!this.agreed) {
                    this.error = 'Не сте приели условията!';
                    return false;
                }
                return true;
            },
            register (token) {
                this.user.token = token;
                this.error = '';
                if (this.isValid()) {
                    this.$apiService
                        .post('/auth/register', this.user)
                        .then(() => {
                            if (this.user.role === 'client' || this.user.role === 'seller') {
                                this.$router.push('/?register=true&done=registration');
                            } else {
                                this.$store
                                    .dispatch('login', this.user)
                                    .then(() => {
                                        this.$router.push('/');
                                    })
                                    .catch((err) => {
                                        let errMessageArray = err.response.data.message.split(':');
                                        let messageToReturn =
                                            errMessageArray[errMessageArray.length - 1];
                                        this.error = messageToReturn;
                                    });
                            }
                        })
                        .catch((err) => {
                            let errMessageArray = err.response.data.message.split(':');
                            let messageToReturn = errMessageArray[errMessageArray.length - 1];
                            this.error = messageToReturn;
                        });
                }
            },
        },
        mounted () {
            if (this.$route.query.sellerQuery) {
                this.user.role = 'seller';
                this.$emit('close', true)
            }
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/scss/base.scss';
    .auth__register {
      display: flex;
      flex-direction: column;
      padding: 1rem 35px 35px;
    }
    .shopWisely {
      text-align: center;
      padding-top: 10px;
    }
    .auth__register__choose {
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: center;
      margin-bottom: 1rem;
      .back {
        background: initial;
        font-size: 30px;
        @include hover();
      }
      p {
        width: 100%;
      }
    }
    .auth__register__section {
      border: 2px solid #c5cdd4;
      border-radius: 10px;
      height: 100%;
      overflow: auto;
      &:last-child {
        margin-top: 1rem;
      }
      form {
        display: none;
        padding: 10px 63px 30px;
        .auth-input-wrapper,
        #dropdown-menu {
          &:not(:last-child) {
            margin-bottom: $px16;
          }
        }
      }
      &.active {
        background-color: #f8fafc;
        form {
          display: block;
        }
      }
    }

    .additional {
      margin-top: 2rem;
      text-align: center;
      padding: 0 10%;
    }

    .agreement {
      align-items: center;
      display: flex;
      margin: 2rem 0;
      justify-content: center;
      text-align: center;
      @include noselect();
      .checkbox-container {
        margin-right: 0.6rem;
      }
      a {
        color: $mainAccent;
        font-weight: 700;
        text-decoration: underline;
      }
    }

    .auth__register__error {
      color: $mainError;
      margin-top: 1rem;
      text-align: center;
    }

    .auth__register__toggle {
      padding: 18px 73px;
      position: relative;
      @include tr();
      @include noselect();
      p {
        &:first-child {
          font-size: $px16;
          margin-bottom: $px16;
        }
        &:last-child {
          line-height: $px18;
        }
      }

      &::before {
        color: #000;
        font-size: 2.5rem;
        font-family: 'icons';
        left: 1rem;
        top: 50%;
        position: absolute;
        @include transform(translateY(-50%));
        @include tr();
      }

      &::after {
        color: rgba($color: $mainColor, $alpha: 0.5);
        content: 'H';
        font-size: 2.5rem;
        font-family: 'icons';
        top: 50%;
        right: 1.5rem;
        position: absolute;
        @include transform(translateY(-50%));
        @include tr();
      }

      &.auth__register__toggle--client {
        &::before {
          content: 'E';
        }
      }
      &.auth__register__toggle--seller {
        &::before {
          content: 'F';
        }
      }
    }

    .auth__register__section.active {
      .auth__register__toggle {
        &::before,
        &::after {
          color: $mainAccent;
        }

        &::after {
          content: 'G';
        }
      }
    }

    .auth__register__section:not(.active) {
      .auth__register__toggle {
        cursor: pointer;
        &:hover {
          background-color: #f8fafc;
        }
      }
    }

    @media screen and (max-width: $standard) {
      .auth__register {
        padding: 5% 3% 3% 3%;
      }

      .auth__register__toggle {
        padding: 5% 15% 5% 15%;

        &::before {
          left: 5%;
        }

        &::after {
          right: 5%;
        }
      }

      .auth__register__section form {
        padding: 5% 5% 5% 5%;
      }
    }

    @media screen and (max-width: $xs) {
      .auth__register {
        padding: 5% 3% 3% 3%;
      }

      .auth__register__toggle {
        padding: 10% 17% 8% 20%;

        &::after {
          right: 0.8rem;
        }
      }

      .auth__register__toggle--client,
      .auth__register__toggle--seller {
        left: 0rem;
      }
    }
</style>
