<template>
    <div class="row" style="justify-content: center">
      <button class="cart-button" style="font-weight: 600" @click="redirect()">Продължи</button>
    </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {

  props:['redirectTo', 'redirectFrom', 'validateData', 'shouldValidate', 'isValid', 'status', 'changeStatus'],
  computed: {
    ...mapGetters(['cart', 'isLoading']),
  },
  methods:{
    async setCartStoreAndRedirect() {
      await this.$emit('changeStatus')
      this.$store.commit('setCartStoreData', {state: 'currentStep', value: this.redirectFrom})
      await this.$router.push(this.redirectTo)
    },
    async redirect() {
      if(this.shouldValidate){
        await this.$emit('validateData')

        if(this.isValid){
         await this.setCartStoreAndRedirect()
        }
      }else{
        await this.setCartStoreAndRedirect()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/base';

.row{
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.cart-button {
  width: 70%;
  height: 40px;
  border-radius: 50px;
  background-color: #b50db6;
  color: white;
  font-size: 14px;
  justify-content: center;
  cursor: url('../../assets/img/cursor.png'), auto;
}


@media all and (max-width: $m) {
  .order-info-wrapper {
    width: 30%;
  }
  .title {
    font-size: 18px;
  }
}

@media all and (max-width: 1050px) {
  .title {
    font-size: 20px;
  }
}

@media all and (max-width: $standard) {
  .order-info-wrapper {
    padding-top: 30px;
    width: 70%;
    margin: auto;
  }
}

@media all and (max-width: 650px) {
  .order-info-wrapper {
    width: 100%;
    padding: 30px 0;
  }
}

@media all and (max-width: $xs) {
  .title {
    padding-bottom: 15px;
  }
}
</style>
