<template>
  <div>
    <div
      class="panel__content"
      v-if="forgottenPassword"
      key="panel__content--0"
    >
      <form class="auth__login" @submit.prevent>
      <p class="forgotten-password-text">
        Въведете имейл, на който да изпратим инструкции за възстановяване на
        паролата:
      </p>
        <InputAuth
          placeholder="Имейл"
          type="email"
          icon="B"
          v-model.trim="user.email"
          :disabled="loading || secretQuestion"
          @enter="!secretQuestion ? getSecretQuestion() : reset()"
        />
        <template v-if="secretQuestion">
          <p class="secret-question-text">{{ secretQuestion }}</p>
          <InputAuth
            placeholder="Таен отговор"
            icon="4"
            v-model.trim="user.secretAnswer"
            :disabled="loading"
            @enter="reset"
          />
        </template>
        <Button
          class="button--bigger round accent"
          @click="!secretQuestion ? getSecretQuestion() : reset()"
          :disabled="loading"
        >
          <template v-if="loading">МОЛЯ ИЗЧАКАЙТЕ</template>
          <template v-else>ИЗПРАЩАНЕ</template>
        </Button>
        <p class="auth__login__error" v-if="error">{{ error }}</p>
      </form>
    </div>

    <div class="panel__content" v-else-if="loginMode" key="panel__content--1">
      <form class="auth__login" @submit.prevent>
        <InputAuth
          placeholder="Имейл"
          type="email"
          icon="B"
          v-model="user.email"
        />
        <InputAuth
          placeholder="Парола"
          type="password"
          icon="C"
          v-model="user.password"
        />

        <Button @click="recaptcha">ВЛЕЗ</Button>
        <p class="auth__login__error" v-if="error">{{ error }}</p>
        <a
          class="forgotten-password"
          @click="
            (forgottenPassword = true)
          "
          ><strong>Забравена парола?</strong>
        </a>
        <div class="google_copyright">
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import InputAuth from "@/components/InputAuth";
import Button from "@/components/Button";
export default {
  components: {
    InputAuth,
    Button,
  },
  data() {
    return {
      loginMode: true,
      forgottenPassword: false,
      error: "",
      loading: false,
      secretQuestion: null,
      user: {
        email: "",
        password: "",
        secretAnswer: "",
        token: ""
      },
    };
  },
  methods: {
  async recaptcha() {
    // (optional) Wait until recaptcha has been loaded.
    await this.$recaptchaLoaded()
    const recaptcha = this.$recaptchaInstance
    recaptcha.showBadge();

    // Execute reCAPTCHA with action "login".
    const token = await this.$recaptcha('login');
    this.login(token)
    // Do stuff with the received token.
  },

    login(token) {
      if (this.user.email && this.user.password) {
        this.user.token = token;
        this.error = "";
        this.$store
          .dispatch("login", this.user)
          .then((res) => {
            if (res.data.user.role === "admin" && !this.$route.query.from) {
              this.$router.push("/admin/products/product-service");
            } else {
              this.$router.push(
                this.$route.query.from ? this.$route.query.from : "/"
              );
            }
          })
          .catch((err) => {
            this.error = err.response.data.message;
          });
      } else {
        this.error = "Моля въведете имейл и парола";
      }
    },
    getSecretQuestion() {
      this.secretQuestion = null;
      if (!this.user.email)
        return (this.error = "Не сте добавили e-mail адрес!");

      this.error = "";

      this.loading = true;

      this.$apiService
        .get(`/auth/secret-question/${this.user.email}`)
        .then((res) => {
          this.secretQuestion = res.data;
        })
        .catch((err) => {
          this.error = err.response.data.message;
        })
        .then(() => (this.loading = false));
    },
    reset() {
      if (!this.user.email)
        return (this.error = "Не сте добавили e-mail адрес!");

      if (!this.user.secretAnswer)
        return (this.error = "Не сте отговорили на тайния въпрос");

      this.loading = true;

      this.$apiService
        .post("/auth/forgotten-password", this.user)
        .then(() => {
          this.user.email = "";
          this.user.secretAnswer = "";
          this.secretQuestion = null;

          this.$router.push("/?register=true&done=reset");
        })
        .catch((err) => {
          this.error = err.response.data.message;
        })
        .then(() => (this.loading = false));
    },
  },
  mounted() {
    if (this.$route.query.verified) {
      if (this.$route.query.verified === "true") {
        if (this.$route.query.email) {
          this.user.email = this.$route.query.email;
        }
        this.$snotify.success("Профилът е успешно верифициран");
      } else {
        this.$snotify.error("Няма такъв профил или вече е бил верифициран.");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.auth__login {
  padding: 40px 100px;
  text-align: center;
  .auth-input-wrapper {
    &:not(:last-child) {
      margin-bottom: $px16;
    }
    & + button {
      margin-top: $px16;
    }
  }
  .auth__login__error {
    color: $mainError;
    margin-top: 1rem;
    text-align: center;
  }
  .forgotten-password {
    display: inline-block;
    margin-top: 1rem;
    @include hover();
    @include noselect();
  }
}
.panel__content .forgotten-password-text {
  margin-top: 1%;
  margin-bottom: 1%;
  text-align: center;
}

.secret-question-text {
  margin-bottom: $px16;
}

@media screen and (max-width: $standard) {
  .auth__login {
    padding: 10% 8% 3%;
  }
}
.google_copyright {
  padding-top: 10px;
  font-size: 10px;
  text-align: center;
  color: #707070;

}
</style>
