import {ApiService} from "@/common/api.service";

const state = {
  activeOption: null,
  invalidFields: {
    message: '',
    invalidIds: []
  },
  carouselOptionId: null,
  isLoading: false,
  carouselOptions: [
    {
      id: 1,
      label: 'Най-големи отстъпки',
      carouselItems: [],
      route: 'biggest-discounts'
    },{
      id: 2,
      label: 'Най-продавани',
      carouselItems: [],
      route: 'best-sellers'
    },{
      id: 3,
      label: 'Стоки и услуги на едро',
      carouselItems: [],
      route: 'goods-and-service'
    },{
      id: 4,
      label: 'Топ продукти',
      carouselItems: [],
      route: 'top-products'
    },
  ],
  filteredProducts: []
}

const getters = {
  carouselOptions: state => state.carouselOptions,
  activeOption: state => state.activeOption,
  invalidFields: state => state.invalidFields,
  isLoading: state => state.isLoading,
}

const actions = {
  getCarouselData(context) {
    context.commit('setIsLoading', true);
    ApiService.get("/un/carousels-data")
      .then((response) => {
        context.commit('handleCarouselData', response.data);
        context.commit('calculateDiscountedPrices');
      }).catch((e)=>{
      context.commit('setInvalidFields', e.response.data);
      context.commit('setError', state.invalidFields.message ?? '');
    }).finally(()=>{
      context.commit('setIsLoading', false);
    })
  },
  save(context){
    context.commit('removeEmptyFields');

    if(state.carouselOptions[state.activeOption].carouselItems.length > 0){
      context.commit('setIsLoading', true);
      context.commit('setInvalidFields', {
        message: '',
        invalidIds: []
      });
      const data = {
        productIds: state.carouselOptions[state.activeOption].carouselItems.filter(product => typeof product.carouselId === 'undefined'),
        carouselOption: state.carouselOptionId
      };

      ApiService
        .post("/protected-routes/add-carousel-items", {data})
        .then((res) => {
          context.commit('setInvalidFields', res.data.invalidData)

          if(res.data.validData.length){
            res.data.validData.map(item =>{
              let find = state.carouselOptions[state.activeOption].carouselItems.find(product => product.product._id === item.product)

              if(find){
                find.carouselId = item._id
              }
            })
          }
          if(state.invalidFields.invalidIds.length === 0){
            context.commit('setInvalidFields', {
              message: '',
              invalidIds: []
            });
            context.commit('setError', '');
          }
        }).catch((e) => {
        context.commit('setInvalidFields', e.response.data.invalidData);
        context.commit('setError', state.invalidFields.message ?? '');
      }).finally(()=>{
        context.commit('setIsLoading', false);
      })
    }
  },
  delete(context, id){
    context.commit('setIsLoading', true);
    ApiService
      .delete(`/protected-routes/delete-carousel-item/${id}`)
      .then((res) => {
        if(res.data.status === 'success'){
          context.commit('filterData', id);
        }
      }).catch((e) => {
      context.commit('setInvalidFields', e.response.data);
      context.commit('setError', state.invalidFields.message ?? '');
    }).finally(()=>{
      context.commit('setIsLoading', false);
    })
  },
  addNewInput(context) {
    context.commit('addNewInput');
  },
  setActiveOption(context, activeOption){
    context.commit('setActiveOption', activeOption)
  },
}

const mutations = {
  setActiveOption(state, activeOption){
    state.activeOption = activeOption;
    state.carouselOptionId = activeOption + 1

    if(state.carouselOptions[state.activeOption].carouselItems.length === 0){
      state.carouselOptions[state.activeOption].carouselItems.push({product: {_id:''}});
    }
  },
  handleCarouselData(state, data) {
    Object.entries(data).map(values=> {
      state.carouselOptions.find(carousel => parseInt(values[0]) === parseInt(carousel.id)).carouselItems = values[1]
    })
  },
  addNewInput(state) {
    state.carouselOptions[state.activeOption].carouselItems.push({product: {_id:''}});
  },
  removeEmptyFields(state) {
    state.carouselOptions[state.activeOption].carouselItems = state.carouselOptions[state.activeOption].carouselItems.filter(value => value.product._id.length > 0)
  },
  filterData(state, id) {
    state.carouselOptions[state.activeOption].carouselItems = state.carouselOptions[state.activeOption].carouselItems.filter(value => value.carouselId !== id)
  },
  setIsLoading(state, isLoading){
    state.isLoading = isLoading
  },
  setInvalidFields(state, invalidFields){
    let messages= []
    let invalidIds = [];

    if(invalidFields.length > 0){
      invalidFields.map(error => {

        if(typeof  state.carouselOptions[state.activeOption].carouselItems.find(item => item.product._id === error.product && typeof item.carouselId === 'undefined') !== 'undefined'){
          invalidIds.push(error.product)
          messages.push(error.message)
        }
      })
    }
    messages = [...new Set(messages)];
    state.invalidFields = {message: messages.toString(), invalidIds: invalidIds};
  },
  calculateDiscountedPrices(state) {
    state.carouselOptions.map(carouselItems =>{
      carouselItems.carouselItems.map(item => {
        if(item.product !== null) {
          if (item.product.discounts) {
            item.product.discounts.sort((a, b) => {
              return b.percent - a.percent;
            });

            for (let d of item.product.discounts) {
              const price = item.product.price - (item.product.price / 100) * d.percent;
              d.price = price;
            }
          }
        }
      })

      carouselItems.carouselItems.sort((a, b) => {
          return ((b.product.discounts[0].percent || 0) -( a.product.discounts[0].percent || 0))
      })
    })
  },
}

export default {
  state,
  actions,
  mutations,
  getters
};
