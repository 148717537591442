<template>
  <section class="tabs-wrapper">
    <div class="container">
      <div class="tabs-container">
        <div v-if="showBackButton"><BackButton /></div>
        <div class="tabs" v-for="(tab, i) in tabs" :key="`tab-${i}`">
          <a class="tab"  :href="tab.route">{{ tab.name }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BackButton from "@/components/BackButton.vue";

export default {
  components: {BackButton},
  props: ["mobileOnDesktop", "light"],
  computed: {
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
    showBackButton(){
      if(['checkout', 'cart', 'preview'].includes(this.$route.name)){
        return true
      }
      return false
    }
  },
  data() {
    return {
      tabs:[]
    };
  },
  watch: {},
  methods: {
    getTabs(){
      this.$store.dispatch('getTabs').then((res)=>{
        this.tabs = res
      })
    }
  },
  mounted() {
    this.getTabs()
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';

.tabs-wrapper{
  background: linear-gradient(to bottom, #51199d 30%, #830ca8 80%);
  height: 50px;
  display: flex;
}
.tabs-container {
  height: 50px;
  display: flex;
  justify-content: flex-start;
  margin-left: 350px;
  width: calc(100% - 350px);
  gap: 50px;

  a{
    cursor: url('../../assets/img/cursor.png'), auto;
  }
  .tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tab {
    color: white;
    font-size: 13pt;
    font-weight: 550;
  }
}

@media screen and (max-width: $l) {
  .tabs-container {
    margin-left:300px ;
    width: calc(100% - 300px);
    .tabs {
      //margin-right: 20px;
      .tab{
        font-size: 12pt;
      }
    }
  }
}
@media screen and (max-width: $sm) {
  .tabs-container {
    margin-left: 280px;
    width: calc(100% - 280px);
    .tabs {
      //margin-right: 20px;
      .tab{
        text-align: center;
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: $standard) {
  .tabs-container {
    margin: 0;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .tabs-wrapper{
    height: auto;
  }
  .tabs-container {
    gap: 30px;
  }
}

@media screen and (max-width: 600px) {
  .tabs-container {
    gap: 15px;
    .tab{
      font-size: 11px;
    }
  }
}
</style>
