<template>
  <div
    class="input-wrapper input-wrapper--dropdown"
    :class="{
      'input-wrapper--hide': !isOpen,
      'hidden':isMobile
    }"
  >
    <div class="custom-select">
      <div v-if="isLoading">
        <loader :active="isLoading"/>
      </div>
      <div v-else>
        <div v-if="products.length > 0" class="items">
          <div
            v-for="p in products"
            :key="p._id"
            @click="openProduct(p.slug)"
          >
            {{ p.name }}
          </div>
        </div>
        <div class="message" v-else>
          <img class="img-default" :src="`${uploads}/assets/img/search/;(.svg`" alt="" />
          <h1>Няма обяви, които да отговарят на търсенето.</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import Loader from "@/components/Loader.vue";

    export default {
      components: {Loader},
      props: [
        "isOpen",
        "products",
        "isLoading",
        "isMobile"
      ],
      computed:{
        uploads() {
          return `${process.env.VUE_APP_UPLOADS_URI}`;
        },
      },
      data() {
          return {}
      },
      methods:{
        openProduct(p) {
          this.$router.push(`/products/${p}`);
        },
      }
    };
</script>

<style lang="scss" scoped>
    @import '@/scss/base.scss';
    .input-wrapper{
      position: absolute;
      min-height: auto;
      background-color: #e7edef;
      width: 90%;
      margin-left: 5%;
      z-index: 1;
    }

    .custom-select {
      position: relative;
      width: 100%;
      text-align: left;
      outline: none;
      line-height: 47px;
    }

    .input-wrapper--hide{
      display: none;
    }

    .custom-select .selected {
      border-radius: 6px;
      border: 1px solid #350887;
      color: #fff;
      padding-left: 1em;
      cursor: pointer;
      user-select: none;
    }

    .custom-select .items {
      color: #000000;
      border-radius: 0px 0px 6px 6px;
      overflow: hidden;
      border-right: 1px solid #350887;
      border-left: 1px solid #350887;
      border-bottom: 1px solid #350887;
      position: absolute;
      background-color: #ffffff;
      left: 0;
      right: 0;
      z-index: 1;
    }

    .custom-select .items div {
      color: #000000;
      padding-left: 1em;
      cursor: pointer;
      user-select: none;
    }

    .custom-select .items div:hover {
      background-color: #350887C5;
    }

    .hidden {
      display: none;
    }

    .message{
      color: black;
      text-align: center;
      font-size: 10px;
      line-height: 20px;
      margin-top: 20px;
      padding-bottom: 30px;
    }

    .img-default{
      width: 60px;
    }
</style>
