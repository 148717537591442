import Vue from 'vue'
import Vuex from 'vuex'
import error from './error.module'
import auth from './auth.module'
import now from './now.module'
import commonStore from "@/store/commonStore";
import carouselStore from "@/store/carouselStore";
import cartStore from "@/store/cartStore";
import adminStore from "@/store/adminStore";
import favouriteStore from "@/store/favouriteStore";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    error,
    auth,
    now,
    commonStore,
    carouselStore,
    adminStore,
    cartStore,
    favouriteStore
  }
})
