<template>
  <span
    class="price"
    :style="{ color: priceColor }"
    :class="{
      'price--full': full,
      'price--discounted': discounted,
      'price--big': big,
      'price--semi': semi,
      'price--carousel': carousel,
      'price--full--noline': noLine,
      'price--full--noline--small': noLineSmall,
      'price--full--noline--cart': cartTotal,
    }"
    >{{this.mainCurrency}}
    <span class="decimal-and-currency">
      <span :style="{ color: priceColor }">{{this.fractionalCurrency}}</span>
      <span :style="{ color: priceColor }">лв.</span>
    </span>
  </span>
</template>

<script>
export default {
  props: ["value", "full", "discounted", "big", "semi", "carousel",'priceColor', "noLine", "noLineSmall", "cartTotal"],
  data() {
    return {
      mainCurrency: 0,
      fractionalCurrency: 0
    };
  },watch:{
    value(){
      this.getPrice(this.value)
    }
  },
  methods:{
    getPrice(value){
      this.mainCurrency = Math.floor(value).toLocaleString().replace(/,/g, '.');
      this.fractionalCurrency = ("0" + Math.round((value % 1) * 100) || "00").slice(-2);
    }
  },
  mounted() {
    this.getPrice(this.value)
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
@import "@/scss/remaining.scss";
@media screen and (max-width: $standard) {
  .prices {
    flex-wrap: wrap;
    margin: 0;
    margin-top: 5px;

    .price--full {
      font-size: max(min(10vw, 20px), 10px);
    }

    .price--discounted {
      font-size: max(min(10vw, 25px), 10px);
    }
  }
}

@media screen and (max-width: 600px) {
  .prices {
    flex-wrap: wrap;
    margin: 0;
    margin-top: 5px;

    .price--full {
      font-size: max(min(10vw, 16px), 8px);
    }

    .price--discounted {
      font-size: max(min(10vw, 18px), 8px);
    }
  }
}
</style>
