<template>
    <div class="breadcrumbs" style="display: flex">
        <button
            @click="returnBack()"
            :class="{
                'icons sqr back category': true
            }"
        >
            b
        </button>
    </div>
</template>

<script>

    import { mapGetters } from "vuex";

    export default {
        props: ['hideText', 'isMobile', 'category'],
        computed: {
            ...mapGetters(['categoriesUnhandled']),
        },
        watch: {},
        methods: {
            returnBack () {
                if (this.$route.query.categories) {
                    const pushTo = this.category.parent ? `products?search=&categories=${this.category.parent}` : '/'
                    this.$router.push(pushTo);
                } else {
                    if (this.$route.name === 'cart') {
                        return this.$router.push('/')
                    }
                    this.hasHistory() ? this.$router.go(-1) : this.$router.push('/')
                }
            },
            hasHistory () {
                return window.history.length > 2;
            },
        },
        mounted () {
            document.querySelector('body').style.overflow = 'hidden';
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/scss/nav.scss';

    .category {
        border: none;
        background-color: transparent;
        color: white;
    }
    .category-label {
        font-size: 16px;
        height: 25px;
        margin: auto 0;
    }
    .cart-dropdown-wrapper {
        position: absolute;
        width: 300px;
        background-color: white;
        border-radius: 25px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        display: block;
        left: -100px;
        top: 100%;

        span {
            margin: auto 0;
        }

        .cart-row {
            border-bottom: 1px solid #dbdbdb;
            display: flex;
            padding: 10px 20px;
            justify-content: space-between;

            .product-info {
                width: calc(100% - 80px);
            }

            .image {
                width: 80px;
                padding-right: 10px;
            }

            img {
                width: 60px;
                height: 60px;
                border-radius: 10px;
            }

            .product-name {
                display: -webkit-box;
                //width: 200px;
                margin: 0;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .price-wrapper {
                justify-content: space-between;
                display: flex;
            }

            .dark {
                color: #7a7a7a;
                justify-content: end;
                width: auto;
            }

            .total {
                width: 80px;
                padding: 0 10px;
            }

            .total-quantity {
                width: 100px;
            }

            .total-amount {
                width: calc(100% - 180px);
            }

            .right {
                position: relative;
                right: 0;
            }

            .cart-button {
                width: 100%;
                height: 30px;
                border-radius: 50px;
                background-color: #b50db6;
                font-size: 14px;
            }
        }

        .cart-row:last-child {
            border: none;
        }

        .title {
            position: relative;
            justify-content: center;
            padding: 20px 0;
            color: #b50db6;
            font-size: 16px;
        }

        .light {
            color: $lightGrey;
        }
    }
</style>
