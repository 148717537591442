<template>
  <div class="container navigation-container">
    <div v-if="isMobile && isMenuOpen" class="overlay" @click="handleMenu()"/>
    <div class="custom-section-wrapper">
      <div class="navigation"
           v-on:mouseleave="getCategoryData([])">
        <div class="menu-banner-r"
             :class="{'shadow': category.length > 0 }" >
          <ProductCategories :getCategoryData="getCategoryData" :filtered="false" v-if="!isMobile || isMenuOpen" />

          <div v-if="category.length > 0 && !isMobile" class="megamenu-container" style="width: 100%">
            <div :class="`masonry-container${columns}`">
              <CategoryTree
                :categoryData="category"
                :isSubcategory="false"
              >
              </CategoryTree>
            </div>
          </div>

          <div class="categories-banner-container-r" v-if="$route.name === 'Home'">
            <section class="banner-r">
              <div v-if="banners.length > 0">
                <transition-group>
                  <img v-for="(banner, i) in banners" :key="`banner--${i}`"
                       class="img-default"
                       :src="`${uploads}/banners/${banner}`"
                       :style="`display: ${active === i ? 'block' : 'none'}`"
                       alt="preonow"
                  />

                </transition-group>
              </div>
              <img v-else
                   class="img-default"
                   :src="`${uploads}/assets/img/banner2.webp`"
                   alt="preonow"
                   fetchpriority="high"
              />
            </section>
            <section :class="!isMobile && 'box-shadow'">
              <ProductCategories :filtered="true"/>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ProductCategories from "@/views/home/ProductCategories.vue";
import CategoryTree from "@/views/home/CategoryTree.vue";

export default {
  components: {CategoryTree, ProductCategories},
  computed: {
    ...mapGetters(['isMenuOpen', 'isMobile', 'allCategories']),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
  },
  data() {
    return {
      category: [],
      banners: [],
      active: 0,
      columns: 4,
    }
  },
  watch: {},
  methods: {
    handleMenu(){
      this.$store.dispatch('handleMenu')
    },
    getBanners() {
      this.$apiService.get(`/banners`).then((res) => {
        this.banners = res.data;
      })
    },
    getCategoryData(category = []) {
      if (typeof category.children !== 'undefined') {
        this.category = category.children
      }else{
        this.category = [];
      }
    },
    startAutoRotation() {
      this.timer = setInterval(this.next, 4000);
    },
    next() {
      if (this.banners.length) {
        if (this.active < this.banners.length - 1) {
          this.active += 1;
        } else {
          this.active = 0;
        }
      }
    },
  },
  mounted() {
    // document.querySelector('body').style.overflow = 'hidden';
    this.getBanners()
    this.startAutoRotation()
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base';
.overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: url('../../assets/img/cursor.png'), auto;
}

.navigation-container {
  //z-index: 2;
  position: relative;
}

.custom-section-wrapper {
  background: white;
}

.navigation {
  max-height: 510px;
}

.tabs-container {
  background: linear-gradient(to bottom, #51199d 30%, #830ca8 80%);
  height: 50px;
  display: flex;
  justify-content: flex-start;

  .tabs {
    display: flex;
    flex-basis: 70%;
    margin-left: 20%;
    align-items: center;
  }

  .tab {
    flex-basis: 15%;
    color: white;
    font-size: 13pt;
    font-weight: 550;
  }
}

.menu {
  width: 200px;
  background-color: #ffffff;
  border-radius: 15px;
  z-index: 300;
  margin-top: -35px;

  .menu-container {
    width: 100%;

    p {
      padding: 10px;
      line-height: 60px;

      &:hover {
        background-color: #b100b2;
      }
    }
  }
}

.banner-categories-container {
  width: 100%;

  .banner {
    width: 100%;

    img {
      object-fit: contain;
    }
  }

  .categories {
    height: 200px;
    background-color: #6b0000;
  }
}

.shadow {
  box-shadow: 0px 6px 26px 0px rgba(163, 163, 163, 1);
}

.menu-banner-r {
  display: flex;
  position: relative;
  font-size: 16px;
  height: 100%;

  .megamenu-container {
    position: absolute;
    width: 1220px;
    margin-left: 280px;
    z-index: 3;
    background-color: #ffffff;
    border-radius: 0 20px 20px 0;
    height: 100%;
    padding: 35px 70px;
  }

  .categories-banner-container-r {
    flex-basis: 85%;
    background-color: #ffffff;

    .banner-r {
      img {
        width: 100%;
        min-width: 800px;
        height: 100%;
        max-height: 380px;
        box-shadow: -250px 40px 20px -21px rgb(222, 218, 222);
      }
    }

    .box-shadow {
      box-shadow: inset 1px 0 1px 0 rgb(217, 217, 217);
    }

    .category {
      margin-left: 20px;
      margin-top: 5px;
      background-color: #6b0000;
      line-height: 60px;
    }
  }
}

.masonry-container4 {
  -moz-column-count: 4;
  -webkit-column-count: 4;
  column-count: 4;
  padding-right: 20px;
}.masonry-container3 {
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
  padding-right: 20px;
}.masonry-container2 {
  -moz-column-count: 2;
  -webkit-column-count: 2;
  column-count: 2;
  padding-right: 20px;
}.masonry-container1 {
  -moz-column-count: 1;
  -webkit-column-count: 1;
  column-count: 1;
  padding-right: 20px;
}

@media all and (max-width: $xl) {
  .menu-banner-r {
    .megamenu-container {
      width: 1020px;
    }
  }
}

@media all and (max-width: $l) {
  .menu-banner-r {
    font-size: 14px;

    .megamenu-container {
      width: 950px;
      margin-left: 250px;
    }
  }
  .masonry-container {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media all and (max-width: $m) {
  .menu-banner-r {
    .megamenu-container {
      width: 820px;
      margin-left: 280px;
      padding: 35px 25px;
    }
  }
}

@media all and (max-width: $sm) {
  .menu-banner-r {
    font-size: 12px;

    .categories-banner-container-r {
      .banner-r {
        img {
          min-width: 200px;
          height: 100%;
          max-height: 240px;
        }
      }
    }

    .megamenu-container {
      width: 640px;
      margin-left: 240px
    }
  }

}

@media all and (max-width: $standard) {
  .navigation {
    max-height: none;
  }
  .menu-banner-r {
    .categories-banner-container-r {
      flex-basis: 100%;
      width: 100%;

      .banner-r img {
        height: 100%;
      }
    }
  }
}
</style>
