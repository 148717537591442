import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/Home.vue";
import store from "@/store/index";
import auth from "./auth";
import user from "./user";
import About from "@/views/home/WhatIsPreonowLila.vue";
import Hotjar from 'vue-hotjar'

Vue.use (Hotjar, {
  id: '3667950',
  snippetVersion: 6
})

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      noSellerEmployees: true,
    },
  },
  ...auth,
  ...user,
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      noSellerEmployees: true,
    },
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("@/views/home/Contacts.vue"),
    meta: {
      noSellerEmployees: true,
    },
  },
  {
    path: "/becomeSeller",
    name: "Become-Seller",
    component: () => import("@/views/home/BecomeSeller.vue"),
    meta: {
      noSellerEmployees: true,
    },
  },
  // {
  //   path: "/generalTerms",
  //   name: "General-Terms",
  //   component: () => import("@/views/home/GeneralTerms.vue"),
  //   meta: {
  //     noSellerEmployees: true,
  //   },
  //},
  {
    path: "/cookies",
    name: "Cookies",
    component: () => import("@/views/home/Cookies.vue"),
    meta: {
      noSellerEmployees: true,
    },
  },
  {
    path: "/privacyPolicy",
    name: "Privacy-Policy",
    component: () => import("@/views/home/PrivacyPolicy.vue"),
    meta: {
      noSellerEmployees: true,
    },
  },
  {
    path: "/cancellationTerms",
    name: "Cancellation-Terms",
    component: () => import("@/views/home/CancellationTerms.vue"),
    meta: {
      noSellerEmployees: true,
    },
  },
  {
    path: "/missionVision",
    name: "Mission-Vision",
    component: () => import("@/views/home/MissionVision.vue"),
    meta: {
      noSellerEmployees: true,
    },
  },
  {
    path: "/products",
    name: "products",
    component: () => import("@/views/Products"),
    meta: {
      noSellerEmployees: true,
    },
  },
  {
    path: "/products/:id",
    name: "product",
    component: () => import("@/views/product/Product"),
    meta: {
      noSellerEmployees: true,
    },
  },
  {
    path: "/products/top/:carousel",
    name: "products",
    component: () => import("@/views/Products"),
    meta: {
      noSellerEmployees: true,
    },
  },
  {
    path: "/sellers",
    name: "sellers",
    component: () => import("@/views/Sellers"),
    meta: {
      noSellerEmployees: true,
    },
  },
  {
    path: "/sellers/:id",
    name: "sellerProfile",
    component: () => import("@/views/Seller"),
    meta: {
      noSellerEmployees: true,
    },
  },
  {
    path: "/not-verified-sellers",
    name: "not-verified-sellers",
    component: () => import("@/views/user/admin/NotVerifiedSellers"),
    meta: {
      noSellerEmployees: true,
    },
  },
  {
    path: "/not-verified-sellers/:id",
    name: "not-verified-sellerProfile",
    component: () => import("@/views/user/admin/NotVerifiedSeller"),
    meta: {
      noSellerEmployees: true,
    },
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    component: () => import("../views/auth/ResetPassword.vue"),
    meta: {
      noSellerEmployees: true,
    },
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("../views/Checkout/Cart.vue"),
    meta: {
      noSellerEmployees: true,
    },
  },
  {
    path: "/cart/checkout",
    name: "checkout",
    component: () => import("../views/Checkout/Checkout.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cart/preview",
    name: "checkout",
    component: () => import("../views/Checkout/Preview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/payment-status",
    name: "paymentResult",
    component: () => import("../views/Checkout/PaymentResult.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     // return savedPosition // This is the default behaviour we want always to return to top
  //     return { x: 0, y: 0 }
  //
  //   }
  //   return { x: 0, y: 0 }
  //
  // },
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (to.hash) {
          return resolve({selector: to.hash});
        } else if (savedPosition) {
          return resolve(savedPosition);
        } else {
          resolve(document.getElementById("app").scrollIntoView({behavior: "smooth"}));
        }
      }, 1200);
    });

  },
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("checkAuth").then(() => {
    // inactive or fired
    if (
      store.getters.currentUser.inactive ||
      store.getters.currentUser.fired ||
      store.getters.currentUser.deleted
    ) {
      store.dispatch("logout").then(() => {
        return next({
          path: "/",
        });
      });
    }

    if (to.matched.some((record) => record.meta.noSellerEmployees)) {
      if (store.getters.isAuthenticated) {
        if (
          store.getters.currentUser.role === "seller" &&
          store.getters.currentUser.employee
        ) {
          return next({
            path: "/employee/payment-request",
          });
        }
      }
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (store.getters.isAuthenticated) {
        // if only specific roles are allowed
        if (to.meta.rolesAllowed) {
          if (
            to.meta.rolesAllowed.indexOf(store.getters.currentUser.role) > -1
          ) {
            if (to.meta.nonEmployees) {
              if (store.getters.currentUser.employee) {
                return next({
                  path: "/",
                });
              }
            }
            if (to.meta.onlyEmployees) {
              if (!store.getters.currentUser.employee) {
                return next({
                  path: "/",
                });
              }
            }

            return next();
          }
          // if no match
          return next({
            path: from.path || "/",
          });
        }
        // no role required
        return next();
      }

      return next({
        path: "/?login=true",
        query: {
          from: to.fullPath,
        },
      });
    } else if (to.matched.some((record) => record.meta.requiresNoAuth)) {
      if (!store.getters.isAuthenticated) {
        return next();
      }
      return next({
        path: "/",
      });
    } else {
      return next();
    }
  });
});

export default router;
