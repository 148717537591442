<template>
  <div class="rate">
    <span
      v-for="n of 5"
      :key="`star-${n}`"
      class="icons"
      :class="{ 'icons--readonly': readonly }"
      @click="readonly ? null : $emit('input', n)"
    >
      <span v-if="value >= n" class="fill">Z</span>
      <span v-else>
        <template v-if="readonly">Z</template>
        <template v-else>a</template>
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: ["value", "readonly"],
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.rate {
  align-items: center;
  display: flex;
  justify-content: center;
  @include noselect();
  .icons {
    color: #fff;
    font-size: 40px;
    padding: 2px;
    &:not(.icons--readonly) {
      @include hover();
    }
    &.icons--readonly {
      color: rgba($color: #fff, $alpha: 0.5);
      .fill {
        color: #f7ff07;
      }
    }
  }
}
</style>