<template>
    <div style="margin: auto 0">
        <section @mouseover="isDropdownOpen = true" @mouseleave="isDropdownOpen = false">
            <div style="position: relative">
                <img
                    :src="`${uploads}/assets/img/header/cart-icon.svg`"
                    alt="cart"
                    style="margin-right: 0; margin-top: 3px"
                    @click="redirect"
                />
                <span class="items-counter">{{ cart.cartQuantity || 0 }}</span>
            </div>
            <span v-if="!isMobile" style="padding-left: 15px"><Price :value="cart.cartTotal || 0" cartTotal="1" /></span>

            <div class="cart-dropdown-wrapper" v-if="isDropdownOpen && cartLoaded && showDropdown">
                <div class="cart-row title">
                    <span v-if="cart.cartQuantity === 0">Нямате продукти в количката</span>
                    <span v-else>Количка за пазаруване</span>
                </div>
                <div v-if="showDropdown">
                    <div v-for="(brand, i) in isAuthenticated ? cart.cartItems : cart.cartItemsFromSession" :key="`brand-dropdown--${i}`">
                        <div class="cart-row cart-item-wrapper" v-for="(product, i) in brand.products" :key="`product-dropdown--${i}`">
                            <div class="image">
                                <img
                                    :src="`${uploads}/users/${product.product.seller._id}/products/200x200-conv-pn-${product.product.mainPhoto}`"
                                    alt="Product cover"
                                />
                            </div>
                            <div class="product-info">
                                <div style="display: flex; justify-content: space-between; height: 20px">
                                    <span class="product-name dark">{{ product.product.name }}</span>
                                    <img
                                        :src="`${uploads}/assets/img/close.svg`"
                                        style="width: 20px; height: auto; margin-right: 0"
                                        alt="Product cover"
                                        @click="deleteItem(product._id)"
                                    />
                                </div>
                                <div class="price-wrapper">
                                    <Price :value="product.currentPrice" noLineSmall="1" />
                                    <span class="product-quantity dark right">x{{ product.quantity }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cart-row">
                        <span class="total dark">ОБЩО:</span>
                        <span class="total-quantity light">{{ cart.cartQuantity }} продукт{{ cart.cartQuantity > 1 ? 'a' : '' }}</span>
                        <Price :value="cart.cartTotal" noLine="1" />
                    </div>
                    <div class="cart-row">
                        <Button class="cart-button" @click="redirect">Виж количката </Button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Button from "@/components/Button.vue";
    import Price from "@/components/Price.vue";
    import cart from "@/views/Checkout/Cart.vue";

    export default {
        props: ['hideText', 'isMobile'],
        components: { Price, Button },
        computed: {
            cart () {
                return cart
            },
            ...mapGetters(['cart', 'cartLoaded', 'isAuthenticated']),
            uploads () {
                return process.env.VUE_APP_UPLOADS_URI;
            },
            showDropdown () {
                if (this.cart.cartQuantity > 0 && this.$route.name !== 'checkout') {
                    return true
                }
                return false
            }
        },
        data () {
            return {
                isDropdownOpen: false
            }
        },
        watch: {},
        methods: {
            redirect () {
                return this.$router.push(`/cart`);
            },
            deleteItem (id) {
                this.$store.dispatch('deleteItem', id).then(res => {
                    this.$snotify.success(res.data.message)
                })
            }
        },
        mounted () {
            document.querySelector('body').style.overflow = 'hidden';
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/scss/nav.scss';

    .cart-dropdown-wrapper {
        position: absolute;
        width: 300px;
        background-color: white;
        border-radius: 25px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        display: block;
        left: -100px;
        top: 100%;

        span {
            margin: auto 0;
        }

        .cart-row {
            border-bottom: 1px solid #dbdbdb;
            display: flex;
            padding: 10px 20px;
            justify-content: space-between;

            .product-info {
                width: calc(100% - 80px);
            }

            .image {
                width: 80px;
                padding-right: 10px;
            }

            img {
                width: 60px;
                height: 60px;
                border-radius: 10px;
            }

            .product-name {
                display: -webkit-box;
                //width: 200px;
                margin: 0;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .price-wrapper {
                justify-content: space-between;
                display: flex;
            }

            .dark {
                color: #7a7a7a;
                justify-content: end;
                width: auto;
            }

            .total {
                width: 80px;
                padding: 0 10px;
            }

            .total-quantity {
                width: 100px;
            }

            .total-amount {
                width: calc(100% - 180px);
            }

            .right {
                position: relative;
                right: 0;
            }

            .cart-button {
                width: 100%;
                height: 30px;
                border-radius: 50px;
                background-color: #b50db6;
                font-size: 14px;
            }
        }

        .cart-row:last-child {
            border: none;
        }

        .title {
            position: relative;
            justify-content: center;
            padding: 20px 0;
            color: #b50db6;
            font-size: 16px;
        }

        .light {
            color: $lightGrey;
        }
    }

    @media all and (max-width: $sm) {
        .cart-dropdown-wrapper {
            top: 120%;
            left: -200px;
        }
    }

    @media all and (max-width: 1100px) {
        .cart-dropdown-wrapper {
            width: 250px;
            font-size: 14px;

            .cart-row .price {
                font-size: 16px;
            }
        }
    }

    @media all and (max-width: 770px) {
        .cart-dropdown-wrapper {
            display: none;
        }
    }
</style>
