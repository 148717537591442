<template>
  <div>
    <section class="container widget">
      <ProductsWidget v-if="!isLoading"
        :showPrices="true"
        :isMobile="isMobile"
        :carouselId="1"
      />
    </section>
    <section class="container widget banner-and-video" v-if="!isMobile">
      <Banner2/>
      <Video/>
    </section>
    <section class="container widget">
      <ProductsWidget v-if="!isLoading"
        :showPrices="true"
        :isMobile="isMobile"
        :carouselId="2"
      />
    </section>
    <section v-if="!isMobile" class="container widget">
      <PreonowBanner/>
    </section>
    <section class="container widget">
      <ProductsWidget v-if="!isLoading"
        :showPrices="true"
        :isMobile="isMobile"
        :carouselId="3"
      />
    </section>
    <section v-if="isMobile" class="container widget banner-and-video">
      <Banner2/>
    </section>
    <section class="container widget">
      <ProductsWidget v-if="!isLoading"
        :showPrices="true"
        :isMobile="isMobile"
        :carouselId="4"
      />
    </section>
    <section v-if="isMobile" class="container widget banner-and-video">
      <Video/>
    </section>
    <section v-if="isMobile" class="container widget">
      <TopSellers2
        :isMobile="isMobile"
      />
    </section>
    <section v-if="isMobile" class="container widget">
      <PreonowBanner/>
    </section>
    <section v-if="!isMobile" class="container widget">
      <TopSellers2
        :isMobile="isMobile"
      />
    </section>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ProductsWidget from "@/components/homepage/ProductsWidget.vue";
import TopSellers2 from "@/views/home/TopSellers2.vue";
import Banner2 from "@/components/Banner2.vue";
import Video from "@/components/Video.vue";
import PreonowBanner from "@/components/PreonowBanner.vue";

export default {
  computed: {
    ...mapGetters(["isAuthenticated", "currentUser", 'isLoading']),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
  },
  name: "Home",
  components: {
    PreonowBanner,
    Video,
    Banner2,
    ProductsWidget,
    TopSellers2,
  },
  data() {
    return {
      categories: [],
      isMobile: false,
    };
  },
  methods: {
    handleResize() {
      if (window.innerWidth <= 900) {
        this.isMobile = true;
      } else if (window.innerWidth > 900 && window.innerWidth <= 1280) {
        this.isMobile = false;
      } else {
        this.isMobile = false;
      }
    },
  },
  mounted() {
    this.$store.dispatch('getCarouselData')

    this.$apiService.getCategories()
      .then(res => {
        this.categories = res.data.children
      })
      .catch(err => {
        console.log(err)
      })
    this.handleResize();
    window.addEventListener('resize', () => {
      this.handleResize();
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700');
.hide-component{
  display: none;
}
.main-wrapper {
  width: 90%;
  margin-left: 5%;
}

.widget {
  margin-top: 70px;
}

.banner-and-video {
  display: flex;
  gap: 80px;
  justify-content: center;
}

@media screen and (max-width: $xl) {
  .home .banner-and-video {
    width: 90%;
    margin-left: 5%;
  }
}

@media screen and (max-width: $l) {
  .banner-and-video {
    gap: 75px;
  }
}

@media screen and (max-width: $sm) {

  .widget {
    margin-top: 50px;
  }
}
</style>
