<template>
    <div class="widget-container" v-if="limitedProducts.length > 0">
        <section class="title">
            <span class="type-name">{{ pageTitle }}</span>
            <span class="view-all" @click="redirect">Виж всички</span>
        </section>

        <section class="products-container" v-if="loaded" @touchstart="handleTouchStart" @touchmove="handleTouchMove">
            <div class="bg">
                <template v-if="!isMobile && totalPages > 1">
                    <span class="arrow arrow-prev" @click="prev()" />
                    <span class="arrow arrow-next" @click="next()" />
                </template>

                <template v-else-if="isMobile && products.length > 1">
                    <span class="arrow arrow-prev" @click="scrollBack()" />
                    <span class="arrow arrow-next" @click="scrollForward()" />
                </template>
            </div>
            <div class="products" ref="productPrices" :style="{ justifyContent: justifyContent }">
                <ProductWidget
                    v-for="(p, i) in limitedProducts"
                    :product="p"
                    :productType="productType"
                    :key="p._id + i"
                    :showPrices="showPrices"
                    :isMobile="isMobile"
                    @click="openProduct(p.slug)"
                />
            </div>
        </section>
    </div>
</template>

<script>
    import ProductWidget from "@/components/homepage/ProductWidget.vue";
    import { mapGetters } from "vuex";

    export default {
        components: { ProductWidget },
        props: ['productType', 'showPrices', 'isMobile', 'carouselId'],
        computed: {
            ...mapGetters(["carouselOptions", "isLoading"]),
            uploads () {
                return process.env.VUE_APP_UPLOADS_URI;
            },
            limitedProducts () {
                const x = this.active * this.limit;

                return this.products.slice(x, x + this.limit);
            },
        },
        data () {
            return {
                limit: Number.MAX_VALUE,
                active: 0,
                totalPages: 0,
                products: [],
                loaded: false,
                xDown: null,
                yDown: null,
                justifyContent: '',
                pageTitle: ''
            };
        },
        methods: {
            redirect () {
                const route = this.carouselOptions.find(carousel => parseInt(carousel.id) === parseInt(this.carouselId)).route;
                return this.$router.push(`/products/top/${route}`);
            },
            getProducts () {
                let carousel = this.carouselOptions.find(carousel => parseInt(carousel.id) === parseInt(this.carouselId));

                if (typeof carousel.carouselItems !== 'undefined') {
                    carousel.carouselItems.map(item => {
                        this.products.push(item.product)
                    });
                    this.pageTitle = carousel.label

                    this.setTotalPages();
                    this.handleResize();
                    this.loaded = true;
                }

            },
            handleTouchStart (event) {
                const firstTouch = event.touches[0];
                this.xDown = firstTouch.clientX;
                this.yDown = firstTouch.clientY;
            },
            handleTouchMove (event) {
                if (!this.xDown || !this.yDown) {

                    return;
                }

                const xUp = event.touches[0].clientX;
                const yUp = event.touches[0].clientY;

                const xDiff = this.xDown - xUp;
                const yDiff = this.yDown - yUp;

                if (Math.abs(xDiff) > Math.abs(yDiff)) {
                    // left swipe
                    if (xDiff > 0) {
                        this.next(true);
                    }
                    // right swipe
                    else {
                        this.prev(true);
                    }
                }

                this.xDown = null;
                this.yDown = null;
            },
            sortDiscounts (p) {
                if (p.discounts) {
                    p.discounts.sort((a, b) => {
                        return b.percent - a.percent;
                    });
                }
            },
            calculateDiscountedPrices (p) {
                for (let d of p.discounts) {
                    const price = p.price - (p.price / 100) * d.percent;
                    this.$set(d, 'price', price);
                }
            },
            handleResize () {
                if (window.innerWidth < 900) {
                    this.limit = 20;
                    // this.isMobile = true;
                } else if (window.innerWidth >= 900 && window.innerWidth <= 1280) {
                    // this.isMobile = false;
                    this.limit = 3;
                } else if (window.innerWidth >= 1280 && window.innerWidth <= 1440) {
                    // this.isMobile = false;
                    this.limit = 4;
                } else if (window.innerWidth >= 1440 && window.innerWidth <= 1600) {
                    // this.isMobile = false;
                    this.limit = 5;
                } else {
                    // this.isMobile = false;
                    this.limit = 5;
                }
                this.setTotalPages();

                if ((window.innerWidth <= 460 && this.limitedProducts.length > 1)
                    || (460 < window.innerWidth && window.innerWidth <= 768 && this.limitedProducts.length > 2)
                    || (768 < window.innerWidth && window.innerWidth <= 900 && this.limitedProducts.length > 3)
                    || (900 < window.innerWidth && window.innerWidth <= 1280 && this.limitedProducts.length > 4)
                    || (window.innerWidth > 1280 && this.limitedProducts.length > 5)) {
                    this.justifyContent = 'flex-start'
                } else {
                    this.justifyContent = 'center'
                }
            },
            setTotalPages () {
                let currentTotalPages = this.totalPages;
                this.totalPages = Math.ceil(this.products.length / this.limit);
                if (currentTotalPages !== this.totalPages && this.active !== 0) {
                    this.active = this.active - 1;
                }
            },
            openProduct (p) {
                this.$router.push(`/products/${p}`);
            },
            isCurrentlyTop (p) {
                if (p.preoPlans) {
                    for (let pp of p.preoPlans) {
                        if (pp.boricaPayment === 'paid') {
                            return (
                                new Date(pp.from) < Date.now() && new Date(pp.to) > Date.now()
                            );
                        }
                    }
                }
            },
            scroll (l) {
                const el = this.$refs['productPrices'];
                el.scrollTo({
                    left: l,
                    behavior: 'smooth',
                });
            },
            scrollForward () {
                const items = this.$refs.productPrices;
                const l = items.scrollLeft + items.firstElementChild.offsetWidth;
                this.scroll(l);
            },
            scrollBack () {
                const l = this.$refs['productPrices'].scrollLeft - 180;
                this.scroll(l);
            },
            prev () {
                if (this.isMobile) {
                    this.active = 0;

                } else {
                    if (this.active > 0) {
                        this.active -= 1;
                    } else {
                        this.active = this.totalPages - 1;
                        // this.active -= 1;
                    }
                }
            },
            next () {
                if (this.isMobile) {
                    this.active = 0;
                } else {
                    if (this.active < this.totalPages - 1) {
                        this.active += 1; // FIX FOR PW-169
                    } else {
                        this.active = 0;
                    }
                }
            },
        },
        mounted () {
            this.getProducts();
            window.addEventListener('resize', () => {
                this.handleResize();
            })
        }
    };
</script>

<style lang="scss" scoped>
    @import '@/scss/base.scss';

    .widget-container {
        .title {
            text-align: center;
            display: grid;
            gap: 10px;
            margin-bottom: 30px;

            .type-name {
                color: $lightGrey;
                font-size: 22px;
                text-transform: uppercase;
            }

            .view-all {
                color: #b100b2;
                font-size: 14px;

                &:hover {
                    cursor: url('../../assets/img/cursor.png'), auto;
                }
            }
        }

        .products-container {
            display: flex;
            justify-content: center;
            position: relative;

            .products {
                display: flex;
                width: 90%;
                text-align: center;
                position: relative;
                justify-content: center;
                gap: 30px;
            }

            .bg {
                height: 100%;
                position: absolute;
                width: 100%;
                top: 0;

                .arrow {
                    width: 40px;
                    height: 40px;
                    background-image: url('../../assets/img/arrow-new.svg');
                    position: absolute;
                    top: 50%;
                    z-index: 2;
                    background-repeat: no-repeat;
                    cursor: url('../../assets/img/cursor.png'), auto;
                    filter: invert(93%) sepia(0%) saturate(1145%) hue-rotate(140deg) brightness(91%) contrast(87%);
                    &:hover {
                        filter: invert(12%) sepia(98%) saturate(5691%) hue-rotate(296deg) brightness(84%) contrast(108%);
                    }

                    &.arrow-prev {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                    }

                    &.arrow-next {
                        position: absolute;
                        transform: translate(0, -50%) rotate(180deg);
                        top: 50%;
                        right: 0;
                    }
                }
            }
        }
    }
    @media all and (max-width: $standard) {
        .widget-container {
            .products-container {
                .products {
                    overflow: scroll;
                    //justify-content: flex-start;
                }
                .bg {
                    width: 105%;
                    .arrow {
                        background-position: center;
                        width: 40px;
                        border-top-right-radius: 25px;
                        border-bottom-right-radius: 25px;
                        height: 80px;
                        background-color: rgba(196, 196, 196, 0.14);
                        &.arrow-prev {
                            -webkit-transform: translateY(-50%);
                            transform: translateY(-50%);
                        }

                        &.arrow-next {
                            transform: translateY(-50%) rotate(180deg);
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        .widget-container .products-container .products {
            gap: 0;
        }
    }
</style>
