<template>
  <div>
    <div class="home__search" :class="{searched:search}">
      <div class="home__search__city" :class="{hidden:isMobile}">
        <DropdownCitySearch
          :placeholder="city || 'Град'"
          v-model="city"
          :list="['Всички градове', ...filteredCities]"
          @input="goSearchingByCity()"
        />
      </div>

      <!-- <span class="vl"></span> -->

      <input
        type="text"
        v-model="search"
        placeholder="Търсене..."
        class="search"
        alt="Търсене"
        @click="handleDropdown(true)"
        @keyup.enter="goSearching()"
        v-click-outside="hideIfOpen"
      />
      <img v-if="search" class="icons" @click="goSearching()" src="@/assets/img/header/search-open.svg" alt="">
      <span v-else class="icons" @click="goSearching()">o</span>
      <!-- <button @click="(selectFilters = true), preventScrollingBackground()">
        <span class="icons">n</span>
        Филтри
      </button> -->
      <transition name="fade">
        <SearchFiltersModal
          v-if="selectFilters"
          :value="filters"
          @close="(selectFilters = false), preventScrollingBackground()"
          @save="updateFilters"
        />
      </transition>
    </div>
    <DropdownSearch
      :class="{hidden:isMobile}"
      :products="products"
      :isOpen="isOpen"
      :handleDropdown="handleDropdown"
      :isLoading="isLoading"
    ></DropdownSearch>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchFiltersModal from "./SearchFiltersModal";
import ClickOutside from 'vue-click-outside';
import DropdownCitySearch from "@/components/DropdownCitySearch.vue";
import DropdownSearch from "@/components/DropdownSearch.vue";
export default {
  components: {
    DropdownSearch,
    DropdownCitySearch,
    SearchFiltersModal,
  },
  props:['isMobile'],
  computed: {
    ...mapGetters(["currentUser"]),
  },
  data() {
    return {
      search: "",
      filteredCities: [],
      city: "",
      products:[],
      selectFilters: false,
      filters: {
        types: [],
        categories: [],
      },
      isOpen:false,
      isLoading:false,
      axiosCall : null,
    };
  },
  directives: {
    ClickOutside,
  },
  methods: {
    filterCities(products) {
      let cities = products
        .filter(p => p.cities.length > 0 && p.cities[0] !== 'Всички градове')
        .map(p => {
          return p.cities
        });
      const cityRows = products
        .filter(p => !p.cityRows || p.cityRows.length > 0)
        .map(p => {
          return p.cityRows.map(cr => cr.city);
        });

      cities.push(...cityRows);

      const citiesUnique = [...new Set(cities.flat(1))];

      this.filteredCities = citiesUnique.filter(c => c)

      this.filteredCities.sort(function (a, b) {
        if (a === "София") return -1;
        if (b === "София") return 1;
        return a.localeCompare(b);
      });

    },
    getProducts() {
      let products = [];
      let url = "/un/products/cities";

      if (this.currentUser) {
        if (this.currentUser.role === 'client') {
          url += `/${this.currentUser.role}`;
        }
      }

      this.$apiService
        .get(url)
        .then((res) => {
          products = res.data;
          this.filterCities(products);
        });

      return products;
    },
    goSearchingByCity() {
      if(this.search.trim().length > 2){
        let url = `/products?search=${this.search}&city=${this.city}`;

        this.$router.push(url);
      }
    },
    updateFilters(f) {
      // The f parameter receives the value from row 134 from SearchFiltersModal.vue
      // trough save - emmiter (on row 23 - @save="updateFilters" from this vue file)
      this.selectFilters = false;
      this.filters = f;
      this.goSearching();
      this.preventScrollingBackground();
    },
    goSearching(redirect = true) {
      if (this.search.trim().length >=3) {
        let url = `/products?search=${this.search}`;

        for (let key in this.$route.query) {
          if (typeof this.$route.query[key] === 'boolean') {
            url += `&${key}=true`;
          } else {

            if (key !== 'search') {
              url += `&${key}=${this.$route.query[key]}`;
            }
            // for (let x of this.$route.query[key]) {
            //   url += `&${key}=${x}`;
            // }
          }
        }

        if (this.$route.fullPath !== url) {
          if(redirect === true){
            this.handleDropdown(false)
            this.$router.push(url).then(()=>{this.handleDropdown(false)});
          }else{
            this.products = [];
            this.isLoading = true;

            this.$apiService
              .get(`/un${url}&dropdown=true`)
              .then((res) => {
                this.products = res.data;
                this.isLoading = false;
              });
          }
        }
      }
    },
    preventScrollingBackground() {
      if (this.selectFilters) {
        document.querySelector("body").style.overflow = "hidden";
      } else {
        document.querySelector("body").style.overflow = "visible";
      }
    },
    handleDropdown(isOpen = false){
      this.isOpen = this.search.trim().length > 2 ? isOpen : false;
    },

    hideIfOpen() {
      if (this.isOpen) {
        this.handleDropdown(false)
      }
    },
  },
  watch:{
    search: function (){
      this.handleDropdown(this.search.trim().length > 2)

      this.goSearching(false)
    }
  },
  mounted() {
    this.getProducts();

    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }
    if (this.$route.query.newest) {
      this.$set(this.filters, "newest", this.$route.query.newest);
    }
    if (this.$route.query.highestRating) {
      this.$set(this.filters, "highestRating", this.$route.query.highestRating);
    }
    if (this.$route.query.mostOrdered) {
      this.$set(this.filters, "mostOrdered", this.$route.query.mostOrdered);
    }
    if (this.$route.query.types) {
      const t =
        typeof this.$route.query.types === "string"
          ? [this.$route.query.types]
          : this.$route.query.types;
      this.$set(this.filters, "types", t);
    }
    if (this.$route.query.categories) {
      const c =
        typeof this.$route.query.categories === "string"
          ? [this.$route.query.categories]
          : this.$route.query.categories;
      this.$set(this.filters, "categories", c);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.home__search {
  align-items: center;
  background-color: #e7edef;
  border-radius: 48px;
  border: 2px solid #350887;
  display: flex;
  height: 35px;
  // margin: 0 calc(25% + 10px) 2rem;
  max-width: 640px;
  padding: 5px;
  width: 100%;
  z-index: 1;
  //width: calc(50% - 20px);
  @include tr();
&.searched,&:hover{
  background: rgba(53, 8, 135, 0.1);
  backdrop-filter: blur(38px);
  input{
    color: #350887;
  }
}
  .vl {
    border-left: 1px solid #bebebe;
    height: 80%;
  }
  input {
    background-color: transparent;
    border: 0;
    height: 30px;
    outline: none;
    padding: 0 10px;
    width: 100%;
    &::placeholder {
      color: rgba($color: #000, $alpha: 0.5);
    }
  }
  > .icons {
    color: #350887;
    font-size: 22px;
    margin-right: 10px;
    @include noselect();
    @include hover();
    height: 25px;
  }
  button {
    align-items: center;
    background-color: #b4c4c9;
    border: 0;
    border-radius: 50px;
    display: inline-flex;
    height: 100%;
    outline: none;
    padding: 0 1.5rem;
    @include hover();
    .icons {
      font-size: $px16;
      margin-right: 0.5rem;
    }
  }
}

@media screen and (max-width: $standard) {
 .home__search{
   height: 30px;
   > .icons{
     font-size: 20px;
   }
 }
  section {
    img {
      margin-right: 10px;
    }
    .hidden{
      display: none;
    }
  }
}
</style>
