<template>
  <div
    class="input-wrapper-city input-wrapper-city--dropdown"
    :class="{
      'input-wrapper-city--invalid': invalid,
      'input-wrapper-city--disabled': disabled,
    }"
    v-click-outside="hideIfOpen"
  >
    <label v-if="label">
      {{ label }}
    </label>
    <div class="input" @click="disabled ? null : (open = !open)">
      <span
        class="city"
        :value="prop && value ? value[prop] : value"
        :type="type || 'text'"
        :disabled="typing ? false : true"
        @input="$emit('input', $event.target.value)"
      >
        {{ this.$route.query.city || placeholder }}</span
      >
    </div>
    <span
      class="icons arrow"
      :class="{ active: open }"
      @click="disabled ? null : (open = !open)"
      >c</span
    >
    <div class="input-wrapper-city__dropdown" v-if="open">
      <p
        v-for="(l, i) in list"
        :key="`list-item-${i}`"
        @click.stop="$emit('input', l), (open = false)"
      >
        <template v-if="!l">{{ placeholder || "Изчисти" }}</template>
        <template v-else>
          {{ prop ? l[prop] : l }}
        </template>
      </p>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
export default {
  props: [
    "value",
    "type",
    "placeholder",
    "label",
    "disabled",
    "invalid",
    "list",
    "typing", // allow typing
    "prop", // if list of objects, displaying the prop as a name
  ],
  directives: {
    ClickOutside,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    hideIfOpen() {
      if (this.open) {
        this.open = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/base.scss";
.home__search {
  position: relative;
  display: flex;
  justify-content: space-between;
  @include noselect();

  .home__search__city {
    left: 1%;
    line-height: 1rem;
    margin-right: 1rem;
    position: relative;
    z-index: 3;
  }

  .input-wrapper-city {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    .input {
      flex-basis: 90%;
    }

    .arrow {
      flex-basis: 10%;
      margin-left: 3px;
    }
  }
}

.input-wrapper-city {
  label {
    color: #6e6e6e;
    display: block;
    font-size: $px12;
    margin-bottom: 5px;
  }

  textarea,
  .city {
    border-radius: 4px;
    border: 0px solid #cacaca;
    color: #000;
    font-size: $px14;
    line-height: $px12;
    font-weight: bold;
    height: 32px;
    outline: none;
    width: 100%;
    @include tr();
    margin-right: 5px;
    font-family: Nunito,Helvetica,Arial,sans-serif;

    &::placeholder {
      color: #000000;
    }
  }

  textarea {
    height: 50px;
    max-height: 200px;
    min-height: 50px;
    resize: vertical;
  }

  .lock {
    align-items: center;
    bottom: 6px;
    color: #ff4e8e;
    display: inline-flex;
    font-size: 20px;
    line-height: 20px;
    justify-content: center;
    position: absolute;
    right: 6px;
    vertical-align: middle;
    @include transform(rotate(45deg));
    @include hover();
  }

  &.input-wrapper-city--disabled {
    input,
    textarea {
      &:disabled {
        background-color: rgba($color: #fff, $alpha: 0.5);
        color: #8e8e8e;
      }
    }
  }

  &.input-wrapper-city--invalid {
    .city,
    textarea {
      border: 1px solid $mainError;
    }
  }

  &.input-wrapper-city--link {
    .city,
    textarea {
      color: $mainAccent;
      font-weight: 700;
      text-decoration: underline;
    }
  }

  &.input-wrapper-city--link--active {
    .city,
    textarea {
      background-color: rgba($color: $mainAccent, $alpha: 0.2) !important;
    }
  }

  &:not(.input-wrapper-city--disabled) {
    .input {
      cursor: pointer;

      input {
        cursor: pointer;
      }
    }
  }
}

.input-wrapper-city--dropdown {
  &:not(.input-wrapper-city--disabled) {
    input {
      cursor: pointer;
    }

    .arrow {
      cursor: pointer;
    }
  }

  .arrow {
    align-items: center;
    color: #212121;
    display: inline-flex;
    font-size: 16px;
    line-height: 16px;
    justify-content: center;
    @include tr();

    &.active {
      @include transform(translateY(5%) scaleY(-1));
    }
  }
}

.input-wrapper-city--percent {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  &::after {
    align-items: center;
    bottom: 16px;
    content: "%";
    display: inline-flex;
    font-size: 16px;
    line-height: 16px;
    justify-content: center;
    position: absolute;
    right: 9px;
    vertical-align: middle;
    @include transform(translateY(50%));
    @include tr();
  }
}

.input-wrapper-city__dropdown {
  background-color: #fff;
  border: 1px solid #cacaca;
  border-radius: 4px;
  max-height: 200px;
  overflow: auto;
  position: absolute;
  width: max-content;
  top: calc(100% + 17px);
  left: -12px;
  z-index: 1;
  @include scrollbar(5px, #cacaca);

  p {
    cursor: pointer;
    padding: 6px 12px;

    &:hover {
      background-color: rgba($color: #cacaca, $alpha: 0.2);
    }
  }
}

@media screen and (max-width: 700px) {
  .input-wrapper-city .city {
    font-size: 0.8rem;
  }

  .input-wrapper-city--dropdown {
    .arrow {
      font-size: 10px;
      line-height: 10px;
      margin: 0;
    }
  }
}
</style>
