import {ApiService} from "@/common/api.service";

const state = {
  isDisabled: false,
  isTabsLoaded: false,
  allCategories: [],
  filteredCategories: [],
  isProductPreview: false,
  productData: [],
  newProductImageData: [],
  categoriesUnhandled: []
}
const getters = {
  isDisabled: state => state.isDisabled,
  isProductPreview: state => state.isProductPreview,
  tabs: state => state.tabs,
  allCategories: state => state.allCategories,
  filteredCategories: state => state.filteredCategories,
  productData: state => state.productData,
  newProductImageData: state => state.newProductImageData,
  categoriesUnhandled: state => state.categoriesUnhandled,
}

const actions = {
  saveTab(context, data){
    context.commit('setData', {state: 'isDisabled', value:true});

    return ApiService.post("/protected-routes/tab", {data})
      .then(() => {
        context.commit('setError', '')
        return true
      }).catch(e =>{
        context.commit('setError', e.response.data.message)
        return false
      }).finally(()=>{
        context.commit('setData', {state: 'isDisabled', value:false});
      })

  },

  getTabs(context) {
    context.commit('setData', {state: 'isDisabled', value:true});
    return ApiService.get("/un/tabs")
      .then((response) => {
        context.commit('setData', {state: 'tabs', value: response.data});
        return response.data
      }).catch(e => {
        context.commit('setError', e.response.data);
      }).finally(() => {
        context.commit('setData', {state: 'isDisabled', value:false});
      })
  },

  deleteTab(context, id) {
    context.commit('setData', {state: 'isDisabled', value:true});
    return ApiService
      .delete(`/protected-routes/delete-tab/${id}`)
      .then((res) => {
        return res.data.count.deletedCount === 1
      }).catch((e) => {
        context.commit('setError', e.response.data.message);
      }).finally(()=>{
        context.commit('setData', {state: 'isDisabled', value:false});
      })
  },
  getCategories(context, reload){
    if(state.allCategories.length === 0 || reload){
      ApiService.get('/un/categories-data')
        .then(res => {
          context.commit('setCategories', res.data)
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  saveCategoriesMiniBanners(context) {
    context.commit('setData', {state: 'isDisabled', value:true});
    ApiService.put('/un/save-mini-banners', state.allCategories)
      .then(res => {
        if(res.data.status === 'success'){
          state.allCategories = state.allCategories.map(category => {
            return {
              ...category,
              isUpdated: false
            }
          })
        }
      })
      .catch(err => {
        console.log(err)
      }).finally(()=>{
        context.dispatch('getCategories')
        context.commit('setData', {state: 'isDisabled', value:false});
    })
  }
}

const mutations = {
    setData(state, data){
    state[data.state] = data.value;
  },
  save(state) {
    state.isLoading = true
  },
  setCategories(state, data){
    state.categoriesUnhandled = data
      data.filter(category => category.parent)
        .forEach(category => {
          const parent = data.find(p => {
            return p.id === category.parent;
          });
          parent.children = parent.children || [];
          parent.children.push(category);
        });

    data = data.filter(category => !category.parent);

    state.allCategories = data
    state.filteredCategories = state.allCategories.filter( category => category.showInMiniBanners)
  },
  updateCategory(state, index){
    state.allCategories[index].isUpdated = true;
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};
