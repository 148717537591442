<template>
  <button @click="$emit('click')" :disabled="disabled">
    <slot />
  </button>
</template>

<script>
export default {
  props: ["disabled"],
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
button {
  background-color: $mainAccent;
  border-radius: 52px;
  border: 0;
  color: #fff;
  cursor: url('../assets/img/cursor.png'), auto;
  font-size: $px16;
  font-weight: 700;
  height: 46px;
  outline: none;
  padding: 0 20px;
  text-align: center;
  width: 100%;
  @include tr();
  @include noselect();
  &:hover {
    background-color: rgba($color: $mainAccent, $alpha: 0.8);
  }
  &:disabled {
    background-color: rgba($color: $mainAccent, $alpha: 0.5);
    cursor: default;
  }
}
.btn-accept-prompt {
  align-items: center;
  background-color: rgba(38, 97, 235, 0.93);
  border: 1px solid#1473E6;
  border-radius: 30px;
  cursor: pointer;
  color: white;
  display: inline-flex;
  font-size: 14px;
  font-weight: 700;
  height: 35px;
  padding: 8px 15px 8px 15px;
  position: relative;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: fit-content;
  white-space: nowrap;

  &:hover {
    background-color: rgba(0, 67, 251, 0.7);
    color: rgb(0, 0, 0);
  }
}
@media all and (max-width: $standard){
    .btn-accept-prompt {
      margin-top: 10px;
  }
}
</style>
