<template>
  <div class="home__search__filters-modal-wrapper" @click.self="close()">
    <transition name="modal" appear>
      <div class="home__search__filters-modal"  ref="home"
        :class="{
          'safari': isMobileSafari
        }"
      >
        <span class="icons close" @click="close">U</span>
       <p>Може да изберете един, или да комбинирате повече филтри</p>
        <span class="transperant fr right"></span>
        <span class="icons arrow-next icons-filters-next-first" @click="next('.wrapper-box-first')">x</span>

        <p class="wide-title">ТИП</p>
        <span class="icons arrow-prev icons-filters-prev-second" @click="prev('.wrapper-box-second')">w</span>
        <span class="transperant sr left"></span>
        <div class="filters wrapper-box-second"
          @mousedown="mouseDownHandler"
          @mouseup="mouseUpHandler"
          @mouseleave="mouseUpHandler"
        >
          <div id="box"
            :class="{
              'isMobileDevice': isMobileDevice
            }"
          >
            <span
              class="filter"
              :class="{ active: filters.types.indexOf('product-service') > -1 }"
              @click="toggle('types', 'product-service')"
              >Стоки/Услуги</span
            >
            <span
              class="filter"
              :class="{ active: filters.types.indexOf('microwallet') > -1 }"
              @click="toggle('types', 'microwallet')"
              >Preo ваучер</span
            >
            <span v-if="currentUser.role !== 'client'"
              class="filter"
              :class="{ active: filters.types.indexOf('b2b') > -1 }"
              @click="toggle('types', 'b2b')"
              >B2B</span
            >
          </div>
        </div>
        <span class="transperant sr right"></span>
        <span class="icons arrow-next icons-filters-next-second" @click="next('.wrapper-box-second')">x</span>

        <p class="wide-title">КАТЕГОРИИ</p>

        <span class="icons arrow-prev icons-filters-prev-third" @click="prev('.wrapper-box-third')">w</span>
        <span class="transperant tr left"></span>
        <div class="filters wrapper-box-third"
          ref="cats-third"
          @mousedown="mouseDownHandler"
          @mouseup="mouseUpHandler"
          @mouseleave="mouseUpHandler"
        >
          <div id="box"
            :class="{
              'isMobileDevice': isMobileDevice
            }"
          >
            <span
              class="filter"
              :class="{ active: filters.categories.indexOf(c) > -1 }"
              v-for="(c, i) in categories"
              :key="`filters-cat-${i}`"
              @click="toggle('categories', c)"
              >{{ c }}</span
            >
          </div>
        </div>
        <span class="transperant tr right"></span>
        <span class="icons arrow-next icons-filters-next-third" @click="next('.wrapper-box-third')">x</span>

        <button @click="save()">ТЪРСИ</button>
        <p>
          <a @click="reset()">ИЗЧИСТИ ФИЛТРИ</a>
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import Categories from '@/views/user/seller/data/categories.json';
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['currentUser']),
    isMobileDevice() {
      return ('ontouchstart' in document.documentElement && /mobi/i.test(navigator.userAgent))
    },
    isMobileSafari() {
      return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent && navigator.userAgent.indexOf('CriOS') == -1 &&
        navigator.userAgent.indexOf('FxiOS') == -1;
    }
  },
  props: ['value'],
  data() {
    return {
      categories: Categories,
      filters: {
        types: [],
        categories: [],
      },
      xDown: null,
      yDown: null,
      translateX: 0,
      pos: {},
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    toggle(t, c) {
      const i = this.filters[t].indexOf(c);
      if (i > -1) {
        this.filters[t].splice(i, 1);
      } else {
        this.filters[t].push(c);
      }
    },
    reset() {
      this.filters = {
        types: [],
        categories: [],
      };
    },
    save() {
      this.$emit('save', this.filters);
    },
    prev(className) {
      let content = null;

      if (this.isMobileDevice) {
        content = document.querySelector(className).children[0];
      } else {
        content = document.querySelector(className);
      }

      content.scrollLeft -= 80;
    },
    next(className) {
      let content = null;

      if (this.isMobileDevice) {
        content = document.querySelector(className).children[0];
      } else {
        content = document.querySelector(className);
      }

      content.scrollLeft += 80;
    },
    mouseDownHandler(e) {
      const className = e.path[2].className;
      const ele = document.getElementsByClassName(className)[0];

      this.pos = {
        // The current scroll
        left: ele.scrollLeft,
        top: ele.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
      };

      window.addEventListener("mousemove", this.mouseMoveHandler);
      window.removeEventListener("mouseup", this.mouseUpHandler);
    },
    mouseMoveHandler(e) {
      const className = e.path[2].className;
      const ele = document.getElementsByClassName(className)[0];
      // How far the mouse has been moved
      const dx = e.clientX - this.pos.x;
      const dy = e.clientY - this.pos.y;

      // Scroll the element
      ele.scrollTop = this.pos.top - dy;
      ele.scrollLeft = this.pos.left - dx;
    },
    mouseUpHandler() {
      window.removeEventListener("mousemove", this.mouseMoveHandler);
      window.removeEventListener("mouseup", this.mouseUpHandler);
    },
  },
  mounted() {
    if (this.value) {
      this.filters = Object.assign({}, this.value);
    }
  },
  destroyed() {
    window.removeEventListener("mousemove", this.mouseMoveHandler);
    window.removeEventListener("mouseup", this.mouseUpHandler);
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.home__search__filters-modal-wrapper {
  align-items: center;
  background-color: #00000080;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
.home__search__filters-modal {
  background-color: #b4c4c9;
  border-radius: 24px;
  max-width: 900px;
  padding: 1rem 8px 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .close {
    font-size: 2rem;
    position: absolute;
    right: 10px;
    top: 10px;
    @include transform(rotate(45deg));
    @include hover();
    @include noselect();
  }
  > p {
    font-size: $px16;
    text-align: center;
  }
  .wide-title {
    margin: 20px 0 10px;
  }
  button {
    align-items: center;
    background-color: #350887;
    border-radius: 52px;
    border: none;
    color: #fff;
    display: flex;
    font-size: $px18;
    font-weight: 600;
    height: 40px;
    justify-content: center;
    margin: 20px auto 7px;
    outline: none;
    width: 250px;
    @include hover();
  }
  a {
    color: #350887;
    font-weight: 700;
    text-decoration: underline;
    @include hover();
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .filter {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #e7edef;
    cursor: pointer;
    font-weight: 700;
    padding: $px12;
    margin: 5px;
    white-space: nowrap;
    @include noselect();
    @include tr();
    &:hover {
      background-color: #e7edef;
    }

    &.active {
      background-color: #350887;
      color: $mainBg;
    }
  }
}

#box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.arrow-prev,
.arrow-next {
  display: none;
}

@media screen and (max-width: $standard) {
  .home__search__filters-modal-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-items: center;
  }

  .home__search__filters-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: space-around;
    flex-wrap: nowrap;
    height: fit-content;

    position:fixed;
    left: 5%;
    right: 5%;

    &:not(.safari) {
      .transperant {
        position: absolute;
        &.left {
          width: 9%;
          height: 16%;
          background-image: linear-gradient(90deg, transparent, rgba(180, 196, 201, 1) 80%);
          transform: rotate(180deg);
        }

        &.right {
          width: 9%;
          height: 14%;
          background-image: linear-gradient(90deg, transparent, rgba(180, 196, 201, 1) 80%);
        }

        &.fr {
          &.left {
            top: 20%;
            left: 9%;
          }

          &.right {
            top: 20%;
            right: 8%;
          }
        }

        &.sr {
          &.left {
            top: 40%;
            left: 9%;
          }

          &.right {
            top: 42%;
            right: 8%;
          }
        }

        &.tr {
          &.left {
            top: 61%;
            left: 8%;
          }

          &.right {
            top: 64%;
            right: 7%;
          }
        }
      }
    }

    .filters {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      max-height: 100px;
      width: 86%;
      .filter {
        text-align: center;
      }
    }

    p {
      text-align: center;
      font-size: 15px;
      margin-right: 10%;
      margin-left: 10%;
      width: 80%;
    }

    button {
      font-size: 15px;
    }

    .icons-filters-prev-first {
      position: absolute;
      top: 21.5%;
      left: -1%;
      font-size: 30px;
      z-index: 1;
    }

    .icons-filters-next-first {
      position: absolute;
      top: 21.5%;
      right: -1%;
      font-size: 30px;
    }

    .icons-filters-prev-second {
      position: absolute;
      top: 44.5%;
      left: -1%;
      font-size: 30px;
      z-index: 1;
    }

    .icons-filters-next-second {
      position: absolute;
      top: 44.5%;
      right: -1%;
      font-size: 30px;
    }

    .icons-filters-prev-third {
      position: absolute;
      top: 68.5%;
      left: -1%;
      font-size: 30px;
      z-index: 1;
    }

    .icons-filters-next-third {
      position: absolute;
      top: 68.5%;
      right: -1%;
      font-size: 30px;
    }
  }

  .arrow-prev,
  .arrow-next {
    position: relative;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: flex;
    filter: drop-shadow(0px 0px 19px #00000080);
    font-size: 35px;
    @include tr();
    @include noselect();
    &:hover {
      color: rgb(245, 245, 245);
    }
  }

  .wrapper-box {
    overflow: auto;
  }

  #box {
    width: auto;
    display: flex;
    flex-wrap: nowrap;

    &.isMobileDevice {
      justify-content: flex-start;
      width: 100%;
      overflow-x: auto;
    }

  }
}

@media screen and (max-width: 780px) {
  .home__search__filters-modal {
    .icons-filters-prev-first {
      position: absolute;
      top: 21%;
      left: -1%;
    }

    .icons-filters-next-first {
      position: absolute;
      top: 21%;
      right: -1%;
    }

    .icons-filters-prev-second {
      position: absolute;
      top: 45%;
      left: -1%;
    }

    .icons-filters-next-second {
      position: absolute;
      top: 45%;
      right: -1%;
    }

    .icons-filters-prev-third {
      position: absolute;
      top: 68%;
      left: -1%;
    }

    .icons-filters-next-third {
      position: absolute;
      top: 68%;
      right: -1%;
    }

    &:not(.safari) {
      .transperant {
        &.sr {
          &.left {
            top: 40%;
            left: 8%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .home__search__filters-modal-wrapper {
    .icons-filters-prev-first {
      position: absolute;
      top: 24.5%;
      left: -1%;
    }

    .icons-filters-next-first {
      position: absolute;
      top: 24.5%;
      right: -1%;
    }

    .icons-filters-prev-second {
      position: absolute;
      top: 47%;
      left: -1%;
    }

    .icons-filters-next-second {
      position: absolute;
      top: 47%;
      right: -1%;
    }

    .icons-filters-prev-third {
      position: absolute;
      top: 69%;
      left: -1%;
    }

    .icons-filters-next-third {
      position: absolute;
      top: 69%;
      right: -1%;
    }

    &:not(.safari) {
      .transperant {
        &.tr {
          &.left {
            top: 62%;
            left: 8%;
          }
        }
      }
    }

    &.safari {
      .transperant {
        .icons-filters-prev-second,
        .icons-filters-next-second {
          top: 46%;
        }

        .icons-filters-prev-third,
        .icons-filters-next-third {
          top: 67%;
        }
      }
    }
  }
}

@media screen and (max-width: $xxs) {
  .home__search__filters-modal-wrapper {
    .icons-filters-prev-first {
      position: absolute;
      top: 24%;
      left: -1%;
    }

    .icons-filters-next-first {
      position: absolute;
      top: 24%;
      right: -1%;
    }

    .icons-filters-prev-second {
      position: absolute;
      top: 45%;
      left: -1%;
    }

    .icons-filters-next-second {
      position: absolute;
      top: 45%;
      right: -1%;
    }

    .icons-filters-prev-third {
      position: absolute;
      top: 66%;
      left: -1%;
    }

    .icons-filters-next-third {
      position: absolute;
      top: 66%;
      right: -1%;
    }
  }

  .home__search__filters-modal {
    .filters {
      .filter {
        text-align: center;
        padding: 10px;
        margin: 2px;
        font-weight: 500;
      }
    }

    p {
      font-size: 15px;
      margin-right: 5%;
      margin-left: 5%;
    }

    .wide-title {
      letter-spacing: 5px;
      margin: 10px 0px;
    }

    button {
      font-size: 15px;
    }
  }


}

@media screen and (max-width: 376px) {
  .home__search__filters-modal-wrapper {
    .icons-filters-prev-first {
      position: absolute;
      top: 24%;
      left: -1%;
    }

    .icons-filters-next-first {
      position: absolute;
      top: 24%;
      right: -1%;
    }

    .icons-filters-prev-second {
      position: absolute;
      top: 45%;
      left: -1%;
    }

    .icons-filters-next-second {
      position: absolute;
      top: 45%;
      right: -1%;
    }

    .icons-filters-prev-third {
      position: absolute;
      top: 66%;
      left: -1%;
    }

    .icons-filters-next-third {
      position: absolute;
      top: 66%;
      right: -1%;
    }
  }

  .wrapper-box {
    overflow: auto;
  }

  #box {
    width: auto;
    display: flex;
  }
}

@media screen and (max-width: 321px) {
  .home__search__filters-modal-wrapper {
    .icons-filters-prev-first {
      position: absolute;
      top: 24%;
      left: -3%;
    }

    .icons-filters-next-first {
      position: absolute;
      top: 24%;
      right: -3%;
    }

    .icons-filters-prev-second {
      position: absolute;
      top: 45%;
      left: -3%;
    }

    .icons-filters-next-second {
      position: absolute;
      top: 45%;
      right: -3%;
    }

    .icons-filters-prev-third {
      position: absolute;
      top: 66%;
      left: -3%;
    }

    .icons-filters-next-third {
      position: absolute;
      top: 66%;
      right: -3%;
    }
  }

  .wrapper-box {
    overflow: auto;
  }

  #box {
    width: auto;
    display: flex;
  }
}


</style>
